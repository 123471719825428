import { Box, Button, Modal, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useCallback, useRef, useState } from "react";
import { summarSectionModalstyle } from "../../ProcessResume/EditForms/Summary";
import { ReactComponent as CancelModalIcon } from "../../assets/SVG/cancel-modal.svg";
import { ReactComponent as RotateLeftIcon } from "../../assets/SVG/rotate-left-profile-icon.svg";
import AvatarEditor from "react-avatar-editor";
import { useSelector } from "react-redux";
import { StateProps } from "../../redux/reducer";
import DummyProfile from "../../assets/newProfile.png"
import { setExtractedData } from "../../redux/actions";
import { useDispatch } from "react-redux";

interface ZoomProfilePictureModalProps {
    base64ImageUrl:string;
    pictureModal:boolean;
    setPictureModal:Dispatch<SetStateAction<boolean>>
}

const ZoomProfilePictureModal:React.FC<ZoomProfilePictureModalProps>  = ({base64ImageUrl,pictureModal,setPictureModal}) => {
    const extractedData = useSelector((state:StateProps) => state.extractedData);
    const editorRef = useRef<AvatarEditor | null>(null);
    const [currentBase,setCurrentBase] = useState<string>("");
    const [zoomValue,setZoomValue] = useState(10);
    const [rotate, setRotate] = useState<number>(0);
    const [isImageReady, setImageReady] = useState<boolean>(false);
    const dispatch = useDispatch();
    const uploadPhotoRef = useRef<HTMLInputElement>(null);


    const onImageReady = useCallback(() => {
        setImageReady(true);
      },[])

      const rotateLeft = () => {
        setRotate((prev) => prev-=90)
      }

      const handleZoomChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        if(e?.target?.value) {
         const newValue = parseInt(e.target.value);
           setZoomValue(newValue);
        }
     };

     const getCurrentEditorRef:any = ():string => {
        if (editorRef.current && isImageReady) {
          const canvas = editorRef.current.getImageScaledToCanvas();
          const ctx = canvas.getContext('2d');
          
          if(ctx) {   
            const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
            const data = imageData.data;
      
            // ctx.filter = `brightness(${brightness}) contrast(${contrast}) sepia(${sepia}) grayscale(${grayscale})`;
            ctx.drawImage(canvas, 0, 0);
            return canvas.toDataURL('image/jpeg');
          }
        }
        return "";
      }

     const handleProfileSave = () => {
        if (editorRef?.current) {
          if(getCurrentEditorRef()) {
            setCurrentBase(getCurrentEditorRef());
            dispatch(setExtractedData({ ...extractedData, profilePicture: getCurrentEditorRef() }));
            setPictureModal(false)
          }
          
        }
         setPictureModal(false)
      }

      const profileChangeHandler = () => {
        uploadPhotoRef?.current?.click();
      }

      const handleProfilePhotoUpload = (e:any):void => {
        const file = e.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onloadend = () => {
            if (reader.result) {
              dispatch(setExtractedData({ ...extractedData, profilePicture: reader.result.toString() }));
            } else {
              console.error('Failed to read file');
            }
          };
          reader.readAsDataURL(file);
        }
        }

   return (
    <Modal
    open={pictureModal}
    className="zoom-profile-picture-modal-container"
    onClose={()=>setPictureModal(false)}
    >
      <Box sx={{...summarSectionModalstyle,width:"27rem"}}>
        <Box sx={{padding:"1.5rem"}}>
        <Box sx={{display:"flex",justifyContent:"center",alignItems:"center"}}>
            <Typography sx={{flex:1,fontSize:"1.5rem",lineHeight:"1.813",fontWeight:"500",color:"#0F172A",}}>Adjust your photo</Typography>
            <Box onClick={()=>setPictureModal(false)} sx={{cursor:"pointer"}}>
                <CancelModalIcon/>
            </Box>
        </Box>

        <Box sx={{padding:"1.75rem 5.688rem"}}>
        <Box sx={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",}}>
        <AvatarEditor
            ref={editorRef}
            image={currentBase ? currentBase
              : extractedData?.profilePicture ? extractedData?.profilePicture : DummyProfile}
            border={0}
            color={[0, 0, 0, 0.72]}
            scale={zoomValue / 10}
            rotate={rotate}
            onImageReady={onImageReady}
            // style={{
            //   filter: `brightness(${brightness}) contrast(${contrast}) sepia(${sepia}) grayscale(${grayscale})`,
            // }}
            />
            <Box>

            <Box sx={{display:"flex",gap:"0.75rem",justifyContent:"center",alignItems:"center",paddingTop:"1rem"}} className="control-section">
              <label>Zoom</label>
              <input type="range" min={10} max={30} step={0.1} value={zoomValue}
              onChange={handleZoomChange}
              />
              <Box sx={{padding:"0.125rem 0.25rem"}}>
              <RotateLeftIcon onClick={rotateLeft}/>
              </Box>
              </Box>
            </Box>
        </Box>
        </Box>

        
        <Box sx={{
            width: "100%", display: "flex", justifyContent: "right",alignItems:"center", gap: "1rem",
        }}>
            <Box sx={{flex:1}} onClick={profileChangeHandler}>
                <Typography sx={{fontSize:"0.875rem",lineHeight:"1.195rem",color:"#5594ff"}}>
                    Change Image
                </Typography>
                <input
                  type="file"
                  id="upload-photo"
                  accept="image/*"
                  onClick={(e) => {
                    const event = e.target as HTMLInputElement;
                    event.value = "";
                  }}
                  onChange={handleProfilePhotoUpload}
                  ref={uploadPhotoRef}
                  hidden
                />
            </Box>
            <Box sx={{display:"flex",gap:"1rem"}}>
            <Button onClick={() => setPictureModal((prev) => !prev)} sx={{ border: "1px solid #cbd5e1", color: "#182230" }}>Cancel</Button>
            <Button variant="contained" onClick={handleProfileSave}>Save</Button>
            </Box>
        </Box>
      
        </Box>
      </Box>
    </Modal>
   )
}

export default ZoomProfilePictureModal;