const authenticatedFetch = (
  url: string,
  options: RequestInit = {},
  setContentType = true
) => {
  const token = localStorage.getItem("accessToken");

  // Add default headers, including Authorization
  const defaultHeaders = {
    Authorization: `Bearer ${token}`, // Add the Authorization header
    ...(setContentType && { "Content-Type": "application/json" }),
  };

  // Merge headers from options with default headers
  options.headers = {
    ...defaultHeaders,
    ...(options.headers || {}),
  };

  return fetch(url, options);
};

export default authenticatedFetch;
