import { Box, Button, Modal, Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import {ReactComponent as DraftIcon} from "../assets/SVG/save-draft-icon.svg";
import {ReactComponent as CancelModalIcon} from "../assets/SVG/cancel-modal.svg";
import { summarSectionModalstyle } from "./EditForms/Summary";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setDraftState, setExtractedData } from "../redux/actions";
import { useSelector } from "react-redux";
import { base64ToFile, IResume } from "../components/utils";
import { getToken } from "../components/auth/storeToken";
import { addNewResume, saveResumeData, withAuthHandling } from "../services/apiService";
import { initialState, StateProps } from "../redux/reducer";

interface ISaveAsDraftEntryModal {
    saveAsDraftModal:boolean;
    setSaveAsDraftModal:Dispatch<SetStateAction<boolean>>;
    modalUrls:string;
}

const SaveAsDraftModal:React.FC<ISaveAsDraftEntryModal> = ({saveAsDraftModal,setSaveAsDraftModal,modalUrls}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const extractedData = useSelector((state:IResume) => state.extractedData);
    const selectedTemplateId = useSelector((state: StateProps) => state.selectedTemplateId);
    const previewResumeScreenshot = useSelector((state: any) => state.previewResumeScreenshot);
    const fetchWithAuthHandling = withAuthHandling(navigate);

    const handleModalClick = () => {
        setSaveAsDraftModal(false);
        navigate(modalUrls);
        dispatch(setDraftState(false));
        dispatch(setExtractedData(initialState.extractedData));
    }

    const handleSaveResume = async () => {
      if(!extractedData.id) {
          addNewResume({...extractedData, templateId: selectedTemplateId, screenshot: previewResumeScreenshot}, fetchWithAuthHandling);
      }
      else {
          saveResumeData({...extractedData, templateId: selectedTemplateId, screenshot: previewResumeScreenshot}, fetchWithAuthHandling);
      }
      dispatch(setDraftState(false));
      navigate(modalUrls);
    }

    return(
        <Modal
        onClose={()=> setSaveAsDraftModal((prev)=> !prev)}
        open={saveAsDraftModal}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={{...summarSectionModalstyle,width:"31.625rem"}}>
        <Box sx={{    
                    display:"flex",
                    flexDirection:"column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    pt:3,
                    pl:3,
                    pr:3,
                    pb:3,
                    }}>
            <Box sx={{width:"100%",display:"flex",alignItems:"center",justifyContent:"left",gap:"1rem",marginBottom:"1.75rem"}}>
                <DraftIcon/>
                <Typography sx={{flex:"1",fontSize:"20px",fontWeight:"500",color:"#344054"}} id="keep-mounted-modal-title" variant="h6" component="h2">
                Are you sure to leave this page?
                </Typography>
                <Box sx={{cursor:"pointer"}} onClick={()=> setSaveAsDraftModal(false)}>
                <CancelModalIcon/>
                </Box>
            </Box>
          <Typography sx={{fontSize:"16px",fontWeight:"400",color:"#475569"}} id="keep-mounted-modal-description">
          You have unsaved changes that will be lost, if you decide to continue. 
            Do you want to save changes ?
          </Typography>
          {/* <Typography sx={{fontSize:"18px",fontWeight:"500",padding:"0.5rem 0"}}>"{sectionEntryModal?.}"</Typography> */}
        </Box>
        <Box sx={{width:"100%",display:"flex",justifyContent:"right",gap:"1rem",
                    pt:0.5,
                    pr:3,
                    pb:3,}}>
            <Button onClick={handleModalClick} sx={{border:"1px solid #cbd5e1",color:"#182230"}}>Discard</Button>
            <Button variant="contained" onClick={handleSaveResume}>Save as Draft</Button>
        </Box>
        </Box>

      </Modal>
    )
};
export default SaveAsDraftModal;