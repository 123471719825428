import {
  Box,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useForm, useFieldArray } from "react-hook-form";

import { AuthInputFieldStyle } from "../../../ProcessResume/EditForms/ProcessResumeUtils";
import { ReactComponent as PersonalSiteIcon } from "../../../assets/SVG/personal-site-blue.svg";
import { ReactComponent as LinkedInIcon } from "../../../assets/SVG/linkedIn-color.svg";
import { ReactComponent as GithubIcon } from "../../../assets/SVG/github-icon.svg";
import { SecondaryButton } from "../../button/Buttons";
import authenticatedFetch from "../../../services/AuthenticatedFetch";
import { tecknoApiBaseUrl } from "../../../utils/config";
import { responseHandler } from "../../../utils/responseHandler";
import { ILoggedinUserData } from "../../utils";
import { internationlizedUrl } from "../../../utils/regex";
import Customlink from "./CustomLink";

interface ISocialLinkValues {
  linkedIn: string;
  github: string;
  personalSite: string;
  customLinks: Array<{
    name: string;
    url: string;
  }>;
}

const SocialLinks = () => {
  const loggedInUserData: ILoggedinUserData = useSelector(
    (state: any) => state.loggedInUserData
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    control,
  } = useForm<ISocialLinkValues>({
    defaultValues: {
      linkedIn: "",
      github: "",
      personalSite: "",
      customLinks: [
        {
          name: "Reddit",
          url: "",
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "customLinks",
  });

  const formValues = watch();

  const [loading, setLoading] = useState(false);

  const onSubmit = async (data: ISocialLinkValues) => {
    try {
      setLoading(true);

      const body = JSON.stringify({ socialLinks: JSON.stringify(data) });

      //API to update the social links of the user
      const response = await authenticatedFetch(
        `${tecknoApiBaseUrl}/api/update-user-socials`,
        {
          method: "PUT",
          body,
        }
      );

      if (response.ok) {
        toast.success("Social links updated successfully");
      } else {
        responseHandler(response);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const { linkedIn, github, personalSite, customLinks } = JSON.parse(
      loggedInUserData?.socialLinks || "{}"
    );
    setValue("linkedIn", linkedIn || "");
    setValue("github", github || "");
    setValue("personalSite", personalSite || "");
    setValue("customLinks", customLinks || [{ name: "Reddit", url: "" }]);
  }, [loggedInUserData]);

  return (
    <Grid container spacing={2} sx={{ mt: 0.5 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          pl: "1rem",
          mt: 2,
          width: "50%",
          maxWidth: "500px",
        }}
      >
        <Grid item>
          <Typography
            variant="body1"
            component="label"
            htmlFor="linkedIn"
            gutterBottom
            sx={{ fontWeight: "bold" }}
          >
            LinkedIn
          </Typography>
          <TextField
            sx={{ ...AuthInputFieldStyle, mt: 0.5 }}
            fullWidth
            id="linkedIn"
            placeholder="www.linkedin.com/username"
            margin="normal"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LinkedInIcon />
                </InputAdornment>
              ),
            }}
            {...register("linkedIn", {
              pattern: {
                value: internationlizedUrl,
                message: "Invalid URL",
              },
            })}
          />
          <Typography variant="body2" sx={{ color: "red" }}>
            {errors.linkedIn?.message}
          </Typography>
        </Grid>

        <Grid item>
          <Typography
            variant="body1"
            component="label"
            htmlFor="gitHub"
            gutterBottom
            sx={{ fontWeight: "bold" }}
          >
            GitHub
          </Typography>
          <TextField
            sx={{ ...AuthInputFieldStyle, mt: 0.5 }}
            fullWidth
            id="gitHub"
            placeholder="www.github.com/username"
            margin="normal"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <GithubIcon />
                </InputAdornment>
              ),
            }}
            {...register("github", {
              pattern: {
                value: internationlizedUrl,
                message: "Invalid URL",
              },
            })}
          />
          <Typography variant="body2" sx={{ color: "red" }}>
            {errors.github?.message}
          </Typography>
        </Grid>

        <Grid item>
          <Typography
            variant="body1"
            component="label"
            htmlFor="personal-site"
            gutterBottom
            sx={{ fontWeight: "bold" }}
          >
            Personal Site
          </Typography>
          <TextField
            sx={{ ...AuthInputFieldStyle, mt: 0.5 }}
            fullWidth
            id="personal-site"
            placeholder="www.mysite.com"
            margin="normal"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonalSiteIcon />
                </InputAdornment>
              ),
            }}
            {...register("personalSite", {
              pattern: {
                value: internationlizedUrl,
                message: "Invalid URL",
              },
            })}
          />
          <Typography variant="body2" sx={{ color: "red" }}>
            {errors.personalSite?.message}
          </Typography>
        </Grid>

        {fields.map((field, index) => (
          <Grid item key={field.id}>
            <Customlink
              name={formValues.customLinks[index].name}
              id={field.id}
              setName={(value: string) =>
                setValue(`customLinks.${index}.name`, value)
              }
              deleteCustomLink={index === 0 ? undefined : () => remove(index)}
              error={formValues.customLinks[index].name === ""}
            />
            <TextField
              fullWidth
              placeholder="www.customlink.com"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonalSiteIcon />
                  </InputAdornment>
                ),
                sx: {
                  "@media(min-height:910px)": {
                    fontSize: "18px",
                  },
                },
              }}
              {...register(`customLinks.${index}.url`, {
                pattern: {
                  value: internationlizedUrl,
                  message: "Invalid URL",
                },
              })}
            />
            <Typography variant="body2" sx={{ color: "red" }}>
              {errors.customLinks?.[index]?.url?.message}
            </Typography>

            {index === fields.length - 1 && (
              <Box className="add-link" sx={{ display: "inline-block" }}>
                <Typography
                  variant="body2"
                  className="link color-primary"
                  sx={{
                    mt: 1,
                    fontSize: "1rem",
                    fontWeight: "600",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    append({ name: `Custom link ${index + 2}`, url: "" })
                  }
                >
                  Add Link
                </Typography>
              </Box>
            )}
          </Grid>
        ))}

        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
          <SecondaryButton
            variant="contained"
            color="primary"
            loading={loading}
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </SecondaryButton>
        </Box>
      </Box>
    </Grid>
  );
};

export default SocialLinks;
