import {
  Avatar,
  Box,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useForm } from "react-hook-form";

import { ReactComponent as DatePickerIcon } from "../../../assets/SVG/date-picker.svg";
import { AuthInputFieldStyle } from "../../../ProcessResume/EditForms/ProcessResumeUtils";
import { countries } from "../../helpers/countries";
import { ILoggedinUserData } from "../../utils";
import { SecondaryButton } from "../../button/Buttons";
import authenticatedFetch from "../../../services/AuthenticatedFetch";
import toast from "react-hot-toast";
import { responseHandler } from "../../../utils/responseHandler";
import { tecknoApiBaseUrl } from "../../../utils/config";
import { generateInitials } from "../../../utils/commonFunctions";

interface IProfileFormValues {
  fullName: string;
  email: string;
  dob: Date;
  country: string;
  phone: string;
  state: string;
  address: string;
  profilePicFile?: File;
  profilePicUrl?: string;
}

const PersonalDetails = () => {
  const loggedInUserData: ILoggedinUserData = useSelector(
    (state: any) => state.loggedInUserData
  );
  const [loading, setLoading] = useState(false);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
    setValue,
    watch,
  } = useForm<IProfileFormValues>({
    defaultValues: {
      fullName: "",
      email: "",
      dob: new Date(),
      country: "",
      phone: "",
      state: "",
      address: "",
      profilePicUrl: "",
    },
  });

  const formValues = watch();

  const handleProfilePicUpload = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setValue("profilePicFile", file);
    }
  };

  const onSubmit = async (data: IProfileFormValues) => {
    try {
      setLoading(true);

      const body = new FormData();
      body.append("FullName", data.fullName);
      body.append("PhoneNumber", data.phone);
      body.append("Country", data.country);
      body.append("DateOfBirth", data.dob.toISOString());
      body.append("Address", data.address);
      body.append("StateCity", data.state);
      body.append("ProfilePictureFile", data.profilePicFile as File);

      //API call to update the user
      const response = await authenticatedFetch(
        `${tecknoApiBaseUrl}/api/update-user`,
        {
          method: "PUT",
          body,
        },
        false
      );

      if (response.ok) {
        toast.success("Profile updated successfully");
      } else {
        responseHandler(response);
      }
    } catch (error) {
      console.error("Error saving data", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setValue("fullName", loggedInUserData.fullName);
    setValue("email", loggedInUserData.email);
    setValue("country", loggedInUserData.country);
    setValue("phone", loggedInUserData.phoneNumber);
    setValue("dob", new Date(loggedInUserData.dateOfBirth));
    setValue("state", loggedInUserData.stateCity);
    setValue("address", loggedInUserData.address);
    setValue("profilePicUrl", loggedInUserData.profilePic);
  }, [loggedInUserData]);

  return (
    <Box sx={{ mt: 2 }}>
      <Grid container spacing={2} sx={{ mt: 0.5, maxWidth: "870px" }}>
        <Grid item container sm={8} columnSpacing={2} rowSpacing={0.8}>
          <Grid item xs={12} sm={6}>
            <Typography
              variant="body1"
              component="label"
              htmlFor="fullName"
              gutterBottom
              sx={{ fontWeight: "bold" }}
              className="required-field"
            >
              Full Name
            </Typography>
            <TextField
              sx={{ ...AuthInputFieldStyle, mt: 0.5 }}
              fullWidth
              id="fullName"
              placeholder="John Doe"
              {...register("fullName", {
                setValueAs: (value: string) => value.trim(),
                required: "Name is required",
              })}
              margin="normal"
            />
            <Typography variant="body2" sx={{ color: "red" }}>
              {errors.fullName?.message}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography
              variant="body1"
              component="label"
              htmlFor="email"
              gutterBottom
              sx={{ fontWeight: "bold" }}
              className="required-field"
            >
              Email
            </Typography>
            <TextField
              fullWidth
              disabled
              id="email"
              placeholder="johndoe@microsoft.com"
              margin="normal"
              sx={{ ...AuthInputFieldStyle, mt: 0.5 }}
              {...register("email", {
                setValueAs: (value: string) => value.trim(),
                required: "Email is required",
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: "Invalid email",
                },
              })}
            />
            <Typography variant="body2" sx={{ color: "red" }}>
              {errors.email?.message}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography
              variant="body1"
              component="label"
              htmlFor="dateOfBirth"
              gutterBottom
              sx={{ fontWeight: "bold" }}
              className="required-field"
            >
              Date of Birth
            </Typography>

            <Box>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  maxDate={new Date()}
                  sx={{
                    width: "100%",
                    mt: 0.5,
                    ".MuiInputAdornment-root": {
                      order: "1",
                      marginLeft: "-10px",
                      marginRight: "20px",
                    },
                    ".MuiInputBase-input": {
                      order: 2,
                    },
                    ".MuiOutlinedInput-notchedOutline": {
                      order: 2,
                    },
                  }}
                  {...register("dob", {
                    required: "Date of birth is required",
                  })}
                  value={formValues.dob}
                  onChange={(value) => setValue("dob", value!)}
                  slots={{
                    openPickerIcon: DatePickerIcon,
                  }}
                />
              </LocalizationProvider>
            </Box>

            <Typography variant="body2" sx={{ color: "red" }}>
              {errors.dob?.message}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography
              variant="body1"
              component="label"
              htmlFor="phone"
              gutterBottom
              sx={{ fontWeight: "bold" }}
              className="required-field"
            >
              Phone
            </Typography>
            <TextField
              sx={{ ...AuthInputFieldStyle, mt: 0.5 }}
              fullWidth
              id="phone"
              placeholder="Ex: +1 (465) 775 7754"
              margin="normal"
              {...register("phone", {
                setValueAs: (value: string) => value.trim(),
                required: "Phone is required",
                pattern: {
                  value: /^\+?\d{10,13}$/,
                  message: "Invalid phone number",
                },
              })}
            />
            <Typography variant="body2" sx={{ color: "red" }}>
              {errors.phone?.message}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography
              variant="body1"
              component="label"
              htmlFor="stateCity"
              gutterBottom
              sx={{ fontWeight: "bold" }}
            >
              State/City
            </Typography>
            <TextField
              sx={{ ...AuthInputFieldStyle, mt: 0.5 }}
              fullWidth
              id="stateCity"
              margin="normal"
              {...register("state", {
                required: "State/City is required",
              })}
            />
            <Typography variant="body2" sx={{ color: "red" }}>
              {errors.state?.message}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography
              variant="body1"
              component="label"
              htmlFor="country"
              gutterBottom
              sx={{ fontWeight: "bold" }}
              className="required-field"
            >
              Country
            </Typography>

            <Box>
              <Select
                sx={{
                  mt: 0.5,
                  width: "100%",
                  ".MuiSelect-select": {
                    display: "flex",
                    alignItems: "center",
                  },
                }}
                value={formValues.country}
                {...register("country", {
                  required: "Location is required",
                })}
              >
                {/* Placeholder item */}
                <MenuItem value="" disabled>
                  Choose your location
                </MenuItem>
                {countries.map((country, index) => (
                  <MenuItem key={index} value={country.code}>
                    <img
                      src={country.flag}
                      width="20"
                      alt=""
                      style={{ marginRight: "10px" }}
                    />
                    <span>{country.label}</span>
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Typography variant="body2" sx={{ color: "red" }}>
              {errors.country?.message}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="body1"
              component="label"
              htmlFor="address"
              gutterBottom
              sx={{ fontWeight: "bold" }}
            >
              Address
            </Typography>
            <TextField
              sx={{
                ...AuthInputFieldStyle,
                mt: 0.5,
                textbox: {
                  padding: 0,
                },
              }}
              fullWidth
              id="address"
              placeholder="Flat no 205, 2nd Floor, Homeland Apartments, 7th Street, 110041 - Nangloi Chowk."
              margin="normal"
              multiline
              rows={2}
              {...register("address")}
            />
          </Grid>
        </Grid>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-end",
            flexGrow: 1,
            position: "relative",
          }}
        >
          <Grid
            item
            xs={12}
            sm={4}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
          >
            {!formValues.profilePicUrl ? (
              <img
                src={formValues.profilePicUrl}
                alt=""
                width="120"
                height="120"
              />
            ) : (
              <Avatar
                sx={{
                  width: 120,
                  height: 120,
                  bgcolor: "#C2D6FF",
                  boxShadow: "0px -8px 16px 0px rgba(55, 93, 251, 0.24) inset",
                  color: "#162664",
                  fontSize: "2.5em",
                }}
              >
                {formValues.fullName
                  ? generateInitials(formValues.fullName)
                  : ""}
              </Avatar>
            )}
            <Typography
              variant="body2"
              color="textSecondary"
              align="center"
              mt={1}
              sx={{ fontWeight: "bold", cursor: "pointer", color: "#5594FF" }}
              onClick={() => fileInputRef.current?.click()}
            >
              Change Photo
            </Typography>
            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              ref={fileInputRef}
              onChange={handleProfilePicUpload}
            />
            <Typography variant="caption" color="textSecondary" align="center">
              <span style={{ whiteSpace: "nowrap" }}>Min Size: 400x400px</span>
              <br />
              <span style={{ whiteSpace: "nowrap" }}>
                File Types: PNG or JPEG
              </span>
            </Typography>
          </Grid>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              ml: 10,
              position: "absolute",
              bottom: -100,
            }}
          >
            <SecondaryButton
              variant="contained"
              color="primary"
              loading={loading}
              onClick={handleSubmit(onSubmit)}
              disabled={!Object.keys(dirtyFields).length}
            >
              Save
            </SecondaryButton>
          </Box>
        </Box>
      </Grid>
    </Box>
  );
};

export default PersonalDetails;
