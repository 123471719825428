import { Box, CircularProgress, IconButton, TextField, Typography } from "@mui/material";
import ResumeScoreProgressBar from "./ResumeScoreProgressBar";
import { useEffect, useRef, useState } from "react";
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import {ReactComponent as PersonalDetailEditIcon } from "../assets/SVG/personal-edit-icon.svg";
import {ReactComponent as SavedIcon } from "../assets/SVG/saved-icon.svg";
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { getUsernameFromToken } from "../services/getUserFromToken";
import PersonalDetails from './EditForms/PersonalDetails';
import { useDispatch, useSelector } from "react-redux";
import { setExtractedData } from "../redux/actions";
import { useLocation } from "react-router-dom";
import { StateProps } from "../redux/reducer";

const NameScoreWrapper = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    let extractedData = useSelector((state:any) => state.extractedData);
    const isDraft = useSelector((state: any) => state.isDraft);
    const isSaveInProgressResume = useSelector((state: StateProps) => state.isSaveInProgressResume);
    
    const username = getUsernameFromToken();
    const [name,setName] = useState<string>(extractedData.title || username || "");
    const [edit,setEdit] = useState<boolean>(false);
    const initialName = useRef<string>(name);

    const nameRef = useRef<HTMLElement>(null);
    const initialRef = useRef<HTMLElement>(null);
    const [textWidth, setTextWidth] = useState(0);

    useEffect(() => {
        if (nameRef?.current || initialRef?.current) {
            const initialWidth = Math.max(nameRef?.current?.offsetWidth ?? 1,initialRef?.current?.offsetWidth ?? 1)
          setTextWidth(initialWidth); // Set the width of the container to text width
        }
      }, [name,edit]);

    const handleCloseEditor = () => {
        setName(initialName.current);
        setEdit(false);
    }

    const handleEditDone = () => {
        initialName.current = name;
        setEdit(false);
        dispatch(setExtractedData({...extractedData, title:name}));
    }

    useEffect(() => {
        setName(extractedData.title || username+"'s Resume" || "");
    }, [extractedData]);

  return (
    <Box className="name-score-wrapper-container">
        <Box className="name-container">
            {!edit ? <Box className="name-wrapper">
             <Typography ref={initialRef} sx={{fontSize:"20px", fontWeight:"600",
                "@media(min-height:1070px)":{
                    fontSize:"22px",
                }
             }}>{name}</Typography>
             <Box sx={{display:"flex", justifyContent:"center", cursor:"pointer"}}>
                <PersonalDetailEditIcon onClick={() => setEdit(true)}/>
             </Box>
            </Box> 
            :<Box className="edit-wrapper">
                  {/* Hidden span element to measure the width of the text */}
                  <Typography ref={nameRef} sx={{
                    position: 'absolute',   
                    top: '-9999px',         
                    left: '-9999px',        
                    visibility: 'hidden', whiteSpace: 'pre',fontSize:"20px", fontWeight:"600",
                "@media(min-height:1070px)":{
                    fontSize:"22px",
                }
             }}>{name}</Typography>

                <TextField onChange={(e)=> setName(e.target.value)} value={name} size="small" sx={{
                    width: `${Math.max(10, Math.min(textWidth + 20, 290))}px`,
                '  @media (max-height: 1450px)': {
                    width: `${Math.max(10, Math.min(textWidth + 20, 700))}px`, // Further reduce width for smaller screens
                    },
                    '@media (max-height: 1090px)': {
                    width: `${Math.max(10, Math.min(textWidth + 20, 530))}px`, // Further reduce width for smaller screens
                    },
                    '@media (max-height: 910px)': {
                        width: `${Math.max(10, Math.min(textWidth + 20, 305))}px`, // Further reduce width for smaller screens
                    },
                    '@media (max-height: 730px)': {
                        width: `${Math.max(10, Math.min(textWidth + 20, 235))}px`, // Adjust width for small screens
                        },

                    '& .MuiInputBase-input': {
                        fontSize: '20px !important',
                        fontWeight:"600 !important",
                        },
                }} />
                <CloseIcon sx={{color:"#EF4444",cursor:"pointer"}} onClick={handleCloseEditor}/>
                <IconButton disabled={!name} sx={{
                    "&:hover":{
                    background:"none",
                },
                cursor: !name ? 'not-allowed' : 'pointer',
                }}><DoneIcon sx={{color:name ? "#19A44C" : "lightgray"}} onClick={handleEditDone} /></IconButton>
            </Box>}
        </Box>
        <Box className="progress-container" sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{
                display: "flex",
                alignItems: "center",
                borderRight: "2px solid #E2E8F0",
                pr: 2
            }}>
                {isSaveInProgressResume ?
                    <>
                        <CircularProgress size={18} />
                        <span style={{ display: "inline-block", marginLeft: "6px" }}>Saving</span>
                    </> :
                    !isDraft && !location.pathname.includes("new") &&
                    <>
                        <SavedIcon />
                        <span style={{ display: "inline-block", marginLeft: "6px" }}>Saved</span>
                    </>
                }
            </Box>
            <ResumeScoreProgressBar/>
        </Box>
    </Box>
  )
}
export default NameScoreWrapper;