import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {ReactComponent as SelectFontIcon} from "../assets/SVG/tabler_text-size.svg";
import {ReactComponent as ChosseTemplatetIcon} from "../assets/SVG/templates-icon.svg";
import {ReactComponent as DoewnloadIcon} from "../assets/SVG/download-icon.svg";
import {ReactComponent as FontPlusIcon} from "../assets/SVG/plus.svg";
import {ReactComponent as FontMinusIcon} from "../assets/SVG/minus.svg";
import {ReactComponent as WordIcon} from "../assets/SVG/doc-icon.svg";
import {ReactComponent as PdfIcon} from "../assets/SVG/pdf-icon.svg";
import Button from '@mui/material/Button'
import ColorPicker from "./ColorPicker";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import { useSelector } from "react-redux";
// import { TemplateHandler } from 'easy-template-x';
// import { saveAs } from 'file-saver';
import { useRef, useState } from "react";
import { useDispatch } from 'react-redux';
import { setExtractedData } from "../redux/actions";
import { getToken } from "../components/auth/storeToken";
import { flattenExtractedData, generateDocument, handleDownloadPdf, IResume, TemplatesById } from "../components/utils";
import { Save } from "@mui/icons-material";
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { StateProps } from "../redux/reducer";

const ProcessResumePreviewHeader = (props: any) => {

    const {currentPage, numPages, handleNextPage, handlePrevPage} = props;

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const extractedData = useSelector((state:any) => state.extractedData);
    const selectedTemplateId = useSelector((state: StateProps) => state?.selectedTemplateId);
    const [error, setError] = useState('');
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLDivElement>(null);

    const handleDownload = () => {
        generateDocument(extractedData);
    }

    const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
        setOpen(false);
        if (index === 0) {
            const TemplateComponent = TemplatesById[selectedTemplateId].jsx;
            handleDownloadPdf(extractedData, TemplateComponent);
        } else if (index === 1) {
            handleDownload();
        }
    };

    

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
        console.log("toggle");
    };

    const handleClose = (event: Event) => {
        if (
          anchorRef.current &&
          anchorRef.current.contains(event.target as HTMLElement)
        ) {
          return;
        }
        setOpen(false);
    };

    return (
        <Box className="content">
            <Box className="left">
                {/* <Box className="icons-container">
                    <Box className="icon-box">
                        <IconButton>
                        <FontPlusIcon/>
                        </IconButton>
                        <SelectFontIcon/>
                        <IconButton>
                        <FontMinusIcon/>
                        </IconButton>

                    </Box>
                    <Box className="icon-box">
                        <div className="div-as-color-picker-icon"><div className="template-color"></div>
                        <Box className="picker-box">
                        <ColorPicker/>
                        </Box>
                        </div>
                    </Box>
                </Box> */}
            </Box>
            <Box className="middle" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2 }}>
                <Typography
                    variant="body1"
                    sx={{ cursor: currentPage === 1 ? 'default' : 'pointer', color: currentPage === 1 ? 'grey' : 'white' }}
                    onClick={currentPage === 1 ? undefined : handlePrevPage}
                >
                    {'<'}
                </Typography>
                <Typography variant="body1" sx={{ color:"#fff", px: 1 }}>{currentPage} / {numPages}</Typography>
                <Typography
                    variant="body1"
                    sx={{ cursor: currentPage === numPages ? 'default' : 'pointer', color: currentPage === numPages ? 'grey' : 'white' }}
                    onClick={currentPage === numPages ? undefined : handleNextPage}
                >
                    {'>'}
                </Typography>
            </Box>
            <Box className="right">
                <Box className="button-controls">
                    <Button variant="outlined" startIcon={<ChosseTemplatetIcon/>}
                     size="medium"
                    //   className="tekno-black-outline-button"
                     onClick={()=> {
                        if(!extractedData.id) {
                            navigate(`/create-resume/new/select-template`)
                        }
                        else {
                            navigate(`/create-resume/${extractedData.id}/select-template`)
                        }
                    }}
                    //  sx={{backgroundColor: "white",
                    //     "&:hover":{
                    //         backgroundColor:"#f1f1f1",
                    //     }
                    //  }}
                     >
                      Templates
                    </Button>
                    <ButtonGroup ref={anchorRef} variant="contained">
                        <Button startIcon={<DoewnloadIcon />} endIcon={<ArrowDropDownIcon
                            aria-controls={open ? 'split-button-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-label="select merge strategy"
                            aria-haspopup="menu"
                            />}
                            onClick={handleToggle} 
                            >Download</Button>
                    </ButtonGroup>
                    <Popper sx={{ zIndex: 1, }}
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                transformOrigin:
                                    placement === 'bottom' ? 'center top' : 'center bottom',
                                }}
                            >
                                <Box sx={{background:"#fff",marginTop:"0.5rem",
                                        borderRadius: "4px", 
                                        overflow: "hidden", 
                                }}>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList id="split-button-menu" autoFocusItem sx={{border:"0.5px solid #e2e4e9"}}>
                                            <MenuItem onClick={(event) => handleMenuItemClick(event, 0)}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.5rem'}}>
                                                    <PdfIcon />
                                                    <Typography sx={{color:"#0A0D14",fontSize:"14px",fontWeight:"500"}}>Export to PDF</Typography>
                                                </Box>
                                            </MenuItem>
                                            <MenuItem onClick={(event) => handleMenuItemClick(event, 1)}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.5rem'}}>
                                                    <WordIcon /> 
                                                    <Typography sx={{color:"#0A0D14",fontSize:"14px",fontWeight:"500"}}>Export to Word</Typography>
                                                </Box>
                                            </MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Box>
                            </Grow>
                        )}
                    </Popper>
                </Box>
            </Box>
        </Box>
    )
}
export default ProcessResumePreviewHeader;