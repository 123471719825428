import { Alert, Badge, Box, Button, Card, CardContent, Grid, IconButton, Snackbar, styled, Typography } from "@mui/material";
import { ApplyToNewJobsIllustration, ATSCheckIllustration, CreateResumeIllustration, Illustration_3 } from "../Icons";
import { useSelector } from "react-redux";
import { AutoAwesome, Close } from "@mui/icons-material";
import HomeScreenTabs from "../HomeScreenTabs/HomeScreenTabs";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Layout from "../Layout";
import { GradientButton } from "../button/GradientButton";
import { getUsernameFromToken } from "../../services/getUserFromToken";
import CustomizedAlert from "../CustomizedAlert";
// import {ReactComponent as CloseIcon} from "../../assets/SVG/chip-cross-icon.svg"
import CancelIcon from "@mui/icons-material/Cancel";

const GreenBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    background: "linear-gradient(90deg, #00D327 0%, #3D81FF 100%)",
    color: '#fff', // White text color
    transform: 'translate(100%, -50%)', // Position as superscript
    paddingLeft: '8px',
    paddingRight: '10px',
  },
}));

const UserDashboard = () => {
  let loggedInUserData = useSelector((state:any) => state?.loggedInUserData);
  
  const location = useLocation();
  const navigate = useNavigate();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  useEffect(() => {
    if (location.state?.isLoginSuccessful) {
        setOpenSnackbar(true);
    }
}, [location]);

  const username = getUsernameFromToken();

  return (
    <Layout sx={{ m: 2 }} title={`Welcome, ${username}`}>
          <CustomizedAlert
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          alertState={openSnackbar}
          title={"Welcome back!"}
          description={"You've successfully logged in."}
          severity="success"
          handleCloseSnackbar={handleCloseSnackbar}
          />
      {/* <Typography variant="h5" gutterBottom sx={{ fontWeight: "bolder", color: "#344054", mb: 3 }}>
         {loggedInUserData?.firstName} {loggedInUserData?.lastName}
      </Typography> */}
      
      <Grid container direction="column" spacing={2.5}>
        <Grid item>
          <Card sx={{
            borderRadius: "12px",
            border: "0.25px solid #EBEBEB",
            background: "linear-gradient(180deg, #DAE8FF 0%, #FAFCFF 100%)",
            boxShadow: "none"
            }}
          >
            <CardContent sx={{ display: "flex", p: 3, width: "100%", justifyContent: "space-between" }}>
              <Box>
                <GreenBadge badgeContent={<><AutoAwesome sx={{ fontSize: 15, mr: 0.6 }}/> AI Powered</>}>
                  <Typography variant="h6" gutterBottom sx={{ fontWeight: '500', color: '#002E7C', m: 0 }}>
                    100% Free Resume Builder
                  </Typography>
                </GreenBadge>
                <Typography gutterBottom sx={{ color: "#002E7C", my: 2 }}>
                  Your success story begins with a resume.  
                  Discover how our AI-powered platform helps you craft a standout resume and cover letter, land your dream job, and earn what you deserve. 
                  With the customizable templates and AI assistance, create a stunning resume in minutes that will impress your future employer.
                </Typography>
                <GradientButton onClick={() => navigate("/my-resumes")}>
                  Get Started
                </GradientButton>
              </Box>
              <Box>
                <Illustration_3 />
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {isVisible && 
          <Grid item>
            <Box
              sx={{
                p: 3,
                py: 2,
                borderRadius: "20px",
                border: "1px solid #E2E8F0",
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                position: "relative"
              }}
            >
              <IconButton
              onClick={() => setIsVisible(false)}
              sx={{
                position: 'absolute',
                top: "-1rem",
                right: "-1rem",
              }}
            >
              <CancelIcon  sx={{color:"#b0b0b0"}} />
            </IconButton>
              <Box>
                <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bolder', color: '#002E7C' }}>
                  Complete your profile!
                </Typography>
                <Typography sx={{ color: "#5A697E" }}>
                  Create a brand-new resume or fine-tune that captures your experience and career goals in a few clicks.
                </Typography>
              </Box>

              <Button
                type="submit"
                variant="contained"
                size="large"
                sx={{ mr: 1 }}
              >
                Complete Now
              </Button>
            </Box>
          </Grid>
        }
        
        <Grid item container spacing={3}>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <Box
              sx={{
                p: 3,
                borderRadius: "20px",
                border: "1px solid #E2E8F0",
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
              }}
            >
              <Box>
                <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bolder', color: '#002E7C' }}>
                  Create a Resume
                </Typography>
                <Typography sx={{ color: "#5A697E" }}>
                  Create a brand-new resume that captures your experience and career goals.
                </Typography>
              
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{ mt: 3 }}
                  onClick={() => navigate("/my-resumes")}
                >
                  Create Resume
                </Button>
              </Box>
              <CreateResumeIllustration style={{ maxWidth: "150px", maxHeight: "160px", width: "100%", height: "100%" }}/>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <Box
              sx={{
                p: 3,
                borderRadius: "20px",
                border: "1px solid #E2E8F0",
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
              }}
            >
              <Box>
                <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bolder', color: '#002E7C' }}>
                  Ensure ATS Compatibility
                </Typography>
                <Typography sx={{ color: "#5A697E" }}>
                  Optimize your resume for Applicant Tracking Systems (ATS) to increase your chances of getting noticed by employers.
                </Typography>
              
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{ mt: 3 }}
                  onClick={() => navigate("/ats-checker")}
                >
                  Check Now
                </Button>
              </Box>
              <ATSCheckIllustration style={{ maxWidth: "150px", maxHeight: "160px", width: "100%", height: "100%" }}/>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <Box
              sx={{
                p: 3,
                borderRadius: "20px",
                border: "1px solid #E2E8F0",
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box>
                <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bolder', color: '#002E7C' }}>
                  Apply to New Jobs
                </Typography>
                <Typography sx={{ color: "#5A697E" }}>
                  Fill in your personal details, education, and experience.  
                </Typography>
              
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{ mt: 3 }}
                  onClick={() => navigate("/job-board")}
                >
                  Explore
                </Button>
              </Box>
              <ApplyToNewJobsIllustration style={{ maxWidth: "150px", maxHeight: "160px", width: "100%", height: "100%" }}/>
            </Box>
          </Grid>
        </Grid>
        <Grid item>
          <HomeScreenTabs />
        </Grid>
      </Grid>
    </Layout>
  );
}

export default UserDashboard;