import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box, Grid, TextField, Divider, InputAdornment, IconButton, Card, CardContent } from '@mui/material';
import { AuthInputFieldStyle } from '../../ProcessResume/EditForms/ProcessResumeUtils';
import { IFetchedJobDetails, IResume, ITailoredResume } from '../utils';
import { Search, Close } from '@mui/icons-material';
import Lottie from 'react-lottie';
import * as ATSCheckScreen from "./../../assets/lottie/ATSCheckScreen.json";
import { ReactComponent as UploadingIcon } from "./../../assets/SVG/uploading-icon.svg";
import { ReactComponent as UploadedResumeIcon } from "../../assets/SVG/uploaded-resume-icon.svg";
import { FetchJobDescriptionIcon } from '../Icons';
import { useNavigate } from 'react-router-dom';
import { setActiveATSCheckResultID, setExtractedData } from '../../redux/actions';
import { useDispatch } from 'react-redux';
import { getToken } from '../auth/storeToken';
import { extractJobDetails, jobDescriptionATSChecker, withAuthHandling } from '../../services/apiService';

interface ProcessingPopupProps {
  open: boolean;
  onClose: () => void;
  popupDetails: {
    title: string;
    subTitle: string;
    progress: number;
    page: number;
    resumeObject: IResume;
  };
  setPopupDetails: React.Dispatch<React.SetStateAction<{
    open: boolean;
    title: string;
    subTitle: string;
    progress: number;
    page: number;
    resumeObject: IResume;
  }>>;
}

interface JobDetailCardProps {
  title: string;
  content: string | string[];
}

const JobDetailCard: React.FC<JobDetailCardProps> = ({ title, content }) => {
  return (
    <Card variant="outlined" sx={{ background: "#F8F9FD", border: "1px solid #E2E8F0", boxShadow: "0px 1px 2px 0px rgba(228, 229, 231, 0.24)", borderRadius: "8px" }}>
      <CardContent sx={{px: 1.4, py: 1, '&:last-child': { paddingBottom: 1 }}}>
        <Typography variant="subtitle2" color={title === "Job Description" || title === "Required Skills" ? "#242424" : "textSecondary" } sx={{ fontWeight: title === "Job Description" || title === "Required Skills" ? 'normal' : 'bold' }}>
          {title}
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: title === "Job Description" || title === "Required Skills" ? 'normal' : 'bold' }}>
          {Array.isArray(content) ? content.join(', ') : content !== null ? content : 'N/A'}
        </Typography>
      </CardContent>
    </Card>
  );
};

const ProcessingPopup: React.FC<ProcessingPopupProps> = ({ open, onClose, popupDetails, setPopupDetails }) => {
  const [formDetails, setFormDetails] = useState({
    jobUrl: '',
    fetchedJobDetails: {} as IFetchedJobDetails,
    jobDescription: '',
    tailoredResumeObject: {} as ITailoredResume,
    loading: false
  });
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fetchWithAuthHandling = withAuthHandling(navigate);
  
  const handleFetch = async () => {
    try {
      setPopupDetails((prevState) => ({
        ...prevState,
        title: "",
        subTitle: "We are fetching the job details we will show preview here once its done.",
        progress: 20,
        loading: true,
      }));
      setFormDetails((prevState) => ({ ...prevState, loading: true }));
      
      const jobData = await extractJobDetails(formDetails.jobUrl, fetchWithAuthHandling);

      setFormDetails((prevState) => ({ 
        ...prevState,
        fetchedJobDetails: jobData.result,
        loading: false
      }));

    } catch (error) {
      console.error('Error extracting job details:', error);
    } finally {
      setFormDetails((prevState) => ({ ...prevState, loading: false }));
      setPopupDetails((prevState) => ({
        ...prevState,
        title: "",
        subTitle: "",
        progress: 100,
        loading: false,
      }));
    }
  };
  
  const handleATSChecker = async () => {
    try {
      setPopupDetails((prevState) => ({...prevState, page: 3}))
      const data = await jobDescriptionATSChecker(
        formDetails.jobDescription !== "" && Object.keys(formDetails.fetchedJobDetails).length === 0
          ? formDetails.jobDescription
          : formDetails.fetchedJobDetails,
        popupDetails.resumeObject,
        fetchWithAuthHandling
      );
      
      setFormDetails((prevState) => ({
        ...prevState,
        fetchedJobDetails: data.jobScraperResult,
        tailoredResumeObject: {
          ...data.tailoredResumeObject,
          designation: data.jobScraperResult?.jobTitle ? data.jobScraperResult?.jobTitle : "",
          companyName: data.jobScraperResult?.companyName ? data.jobScraperResult?.companyName : "",
        }
      }));

      // dispatch(setExtractedData(popupDetails.resumeObject));
      // const savedATSResult = await fetch("https://testapi.tekno.ai/api/add-ats-checker",{
      //   method: 'POST',
      //   headers: {
      //       'accept': '*/*',
      //       'Authorization': 'Bearer ' + getToken(),
      //       'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify({
      //     tailoredResumeObject: {
      //       atsScore: data.tailoredResumeObject.atsScore,
      //       resumeObjectWithChanges: {
      //         professionalSummary: data.tailoredResumeObject.resumeObjectWithChanges.professionalSummary,
      //         designation: data.tailoredResumeObject.resumeObjectWithChanges.designation,
      //         certifications: data.tailoredResumeObject.resumeObjectWithChanges.certifications?.join(","),
      //         toolsTechnicalSkills: data.tailoredResumeObject.resumeObjectWithChanges.toolsTechnicalSkills,
      //         programmingLanguages: data.tailoredResumeObject.resumeObjectWithChanges.programmingLanguages,
      //         interests: data.tailoredResumeObject.resumeObjectWithChanges.interests?.join(","),
      //         workExperiences: data.tailoredResumeObject.resumeObjectWithChanges.workExperiences
      //       },
      //       designation: data.tailoredResumeObject.designation,
      //       companyName: data.tailoredResumeObject.companyName,
      //       keywordsToAdd: data.tailoredResumeObject.keywordsToAdd,
      //     },
      //     resume: JSON.stringify(popupDetails.resumeObject)
      //   })
      // });
      
      // const savedATSResultData = await savedATSResult.json();
      dispatch(setExtractedData(popupDetails.resumeObject));
      
      navigate(`/ats-checker-result`, {
          state: {
            atsResult: {
              fetchedJobDetails: data.jobScraperResult,
              extractedData: popupDetails.resumeObject,
              tailoredResumeObject: {
                ...data.tailoredResumeObject,
                designation: data.jobScraperResult?.jobTitle ? data.jobScraperResult?.jobTitle : "",
                companyName: data.jobScraperResult?.companyName ? data.jobScraperResult?.companyName : "",
              }
            }
          }
      });
    } catch (error) {
      setPopupDetails((prevState) => ({...prevState, page: 2}))
      throw new Error('Network response was not ok');
    } finally {
      setFormDetails((prevState) => ({ ...prevState, loading: false }));
      setPopupDetails((prevState) => ({
        ...prevState,
        title: "",
        subTitle: "",
        progress: 100,
        loading: false,
      }));
    }
  };

  const moveToSecondScreen = () => {
    setPopupDetails((prevState) => ({...prevState, page: 2}))
    setFormDetails({
      jobUrl: '',
      fetchedJobDetails: {} as IFetchedJobDetails,
      jobDescription: '',
      tailoredResumeObject: {} as ITailoredResume,
      loading: false
    });
  };

  const defaultLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: ATSCheckScreen,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <Dialog open={open} onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '20px', // Apply 20px border radius to the dialog
        },
      }}
    >
      <Box>
        <DialogTitle sx={{ fontWeight: "bold" }}>
          ATS Checker
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            height: "500px",
            width: "600px",
          }}
        >
          {popupDetails.page === 1 && 
          <Box
            sx={{
              py: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <Box sx={{mb: 1}}>{popupDetails.progress < 100 ? <UploadingIcon/> : <UploadedResumeIcon />}</Box>
            <Typography sx={{ fontSize: "24px", fontWeight: "bold", display: popupDetails.progress < 100 ? "block" : "none" }} gutterBottom>{popupDetails.progress}%</Typography>
            <Typography sx={{ fontSize: "20px", fontWeight: "bold" }} gutterBottom>{popupDetails.title}</Typography>
            <Typography sx={{ fontSize: "16px" }} color="text.secondary">{popupDetails.subTitle}</Typography>
          </Box>
          }
          {popupDetails.page === 2 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
              }}
            >
              <Grid container spacing={2}>
                {
                  (!formDetails.loading && Object.keys(formDetails.fetchedJobDetails).length === 0) && 
                  <>
                    <Grid item xs={12}>
                      <Typography variant="body1" component="label" htmlFor="email" gutterBottom>Job URL</Typography>
                      <TextField
                        placeholder="Job URL"
                        value={formDetails.jobUrl}
                        onChange={(e) => setFormDetails({ ...formDetails, jobUrl: e.target.value })}
                        fullWidth
                        sx={{
                          ...AuthInputFieldStyle,
                          mt: 0.5,
                          '& .MuiOutlinedInput-root': {
                            border: "1px solid #E2E8F0",
                            background: "#F8FAFC",
                            color: "#667085",
                            boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                            paddingRight: 0
                          },
                        }}
                        margin="normal"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Button onClick={handleFetch} variant="contained" color="primary"
                                sx={{
                                  padding: "0.6em 0px",
                                  borderTopLeftRadius: 0,
                                  borderBottomLeftRadius: 0
                                }}
                              >
                                Fetch
                              </Button>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center' }}>
                        <Divider sx={{ width: '15%' }} />
                        <Typography variant="body2" sx={{ mx: 2, fontWeight: "bold" }}>OR</Typography>
                        <Divider sx={{ width: '15%' }} />
                      </Box>
                    </Grid>
                  </>
                }
                <Grid item xs={12}>
                {
                  formDetails.loading ?
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                        <Box sx={{mb: 1}}><FetchJobDescriptionIcon/></Box>
                        {/* <Typography sx={{ fontSize: "24px", fontWeight: "bold", display: popupDetails.progress < 100 ? "block" : "none" }} gutterBottom>{popupDetails.progress}%</Typography>
                        <Typography sx={{ fontSize: "20px", fontWeight: "bold" }} gutterBottom>{popupDetails.title}</Typography> */}
                        <Typography sx={{ fontSize: "16px" }} color="text.secondary">{popupDetails.subTitle}</Typography>
                  </Box>
                  :
                  Object.keys(formDetails.fetchedJobDetails).length > 0 ?
                  <Grid container spacing={2} sx={{ overflow: 'auto', mt: 2, pt: 0, pr: 1, width: '100%' }}>
                    <Grid item xs={6} sx={{ paddingTop: "0px !important" }}>
                      <JobDetailCard
                        title="Job Title"
                        content={formDetails.fetchedJobDetails.jobTitle}
                      />
                    </Grid>
                    <Grid item xs={6} sx={{ paddingTop: "0px !important" }}>
                      <JobDetailCard
                        title="Company"
                        content={formDetails.fetchedJobDetails.companyName}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <JobDetailCard
                        title="Job Type"
                        content={formDetails.fetchedJobDetails.jobType}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <JobDetailCard
                        title="Location"
                        content={formDetails.fetchedJobDetails.location}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <JobDetailCard
                        title="Salary"
                        content={formDetails.fetchedJobDetails.salary}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <JobDetailCard
                        title="Experience Level"
                        content={formDetails.fetchedJobDetails.experienceLevel}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <JobDetailCard
                        title="Required Skills"
                        content={formDetails.fetchedJobDetails.requiredSkills}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <JobDetailCard
                        title="Job Description"
                        content={formDetails.fetchedJobDetails.jobDescription}
                      />
                    </Grid>
                  </Grid> :
                  <>
                    <Typography variant="body1" component="label" htmlFor="email" gutterBottom>Job Description</Typography>
                    <TextField
                      multiline
                      rows={12}
                      placeholder="Job Description"
                      value={formDetails.jobDescription}
                      onChange={(e) => setFormDetails({ ...formDetails, jobDescription: e.target.value })}
                      fullWidth
                      sx={{
                        ...AuthInputFieldStyle,
                        mt: 0.5,
                        '& .MuiOutlinedInput-root': {
                          border: "1px solid #E2E8F0",
                          background: "#F8FAFC",
                          color: "#667085",
                          boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                        },
                      }}
                      margin="normal"
                    />
                  </>
                }
                </Grid>
              </Grid>
            </Box>
          )}
          {popupDetails.page === 3 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
              }}
            >
              <Lottie options={defaultLottieOptions} height={400} width={400} />
              <Box sx={{ py: 2, textAlign: "center" }}>
                <Typography variant="h5" gutterBottom sx={{ opacity: 0.85, color: "#344054", fontWeight: "bold" }}>
                  Analyzing Your Resume for ATS Score
                </Typography>
                <Typography variant="h6" gutterBottom sx={{ opacity: 0.85, color: "#5A697E" }}>
                  Ensuring your resume is optimized and ready for ATS
                </Typography>
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "space-between", p: 3, visibility: popupDetails.page === 3 ? "hidden" : "visible" }}>
          <Typography color="text.secondary">{popupDetails.page} of 2</Typography>
          <Box>
            <Button type="submit"
              variant="outlined"
              size="large" onClick={onClose}
              sx={{
                border: "1px solid #CBD5E1",
                borderRadius: "8px",
                mr: 1
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="large"
              disabled={popupDetails.progress < 100 || (popupDetails.page === 2 && formDetails.jobDescription === "")}
              onClick={() => popupDetails.page === 2 ? handleATSChecker() : moveToSecondScreen()}
            >
              Continue
            </Button>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ProcessingPopup;