import { Alert, Box, Button, Container, CssBaseline, Grid, InputAdornment, Snackbar, TextField, Typography } from "@mui/material";
import { AuthInputFieldStyle, InputFieldCustomeStyle } from "../../ProcessResume/EditForms/ProcessResumeUtils";
import Header from "../common/Header";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getToken } from "../auth/storeToken";
import { SnackBarState } from "../auth/Login";
import { useNavigate } from "react-router-dom";
import AppBarHeader from "../../ProcessResume/AppBarHeader";
import SideDrawer from "../../ProcessResume/SideDrawer";
import { ProfileIcon } from "../Icons";
import CountryDropdown from "../CountryDropdown/CountryDropdown";
import CustomizedAlert from "../CustomizedAlert";


const EditAccount = () => {
  let token = useSelector((state:any) => state?.loggedInUserData?.token);

  //getting token from local storage
  if(!token ){
    const storedToken = getToken();
    if(storedToken) {
      token = storedToken;
    }
  }
  const [accountData,setAccountData] = useState({
      firstName:"",
      lastName:"",
      phone:"",
      country:"",
  });
  const [accountErrros,setAccountErrros] = useState({
    firstName:"",
    lastName:"",
    phone:"",
    country:"",
  });
  const [snackBar, setSnackBar] = useState<SnackBarState>({open: false, message: "", severity: "success"});
  const navigate = useNavigate()

  const getUserDetails = async () => {
    const response = await fetch("https://testapi.tekno.ai/api/user-profile",{
      method:"GET",
      headers:{
        'Content-type': "application/json",
        'Authorization':`Bearer ${token}`,
      },
    });
    if(response.ok && response.status === 200) {
      console.log(response,"from user profile");
    }
  };

  const formData = {
    "firstName":accountData.firstName,
    "lastName":accountData.lastName,
    "phoneNumber":accountData.phone,
    "country":accountData.country,
  }

  const updateUserDetails = async () => {
    const response = await fetch("https://testapi.tekno.ai/api/update-user",{
      method:"PUT",
      headers:{
        'Content-type': "application/json",
        'Authorization':`Bearer ${token}`,
      },
      body: JSON.stringify(formData)
    });
    if(!response.ok ) {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Couldn't update account details"
      });
    }

    if(response.status===200 && response.ok){
      setSnackBar({
        open: true,
        severity: "success",
        message: 'Account Info updated successfully.' 
      });
    }
  }

  useEffect(()=>{
    //API is not working
   getUserDetails();
  },[]);

  const handleCloseSnackbar = () => {
    setSnackBar({
      ...snackBar,
      open: false
    });
    navigate("/");
  };

  const handleFormChange = (newValue:string,field:string) => {
     if(!newValue || !field) return;
     setAccountData((prev)=>({
      ...prev,
      [field]:newValue,
     }))
  }

  const handleAccountUpdate = () => {
     if(!accountData.firstName) {
      setAccountErrros((prev)=>({
        ...prev,
        firstName:"First Name is Required", 
      }))
     }
     if(!accountData.lastName) {
      setAccountErrros((prev)=>({
        ...prev,
        lastName:"Last Name is Required", 
      }))
     }
     if(!accountData.phone) {
      setAccountErrros((prev)=>({
        ...prev,
        phone:"Phone Number is Required", 
      }))
     }
     if(!accountData.country) {
      setAccountErrros((prev)=>({
        ...prev,
        country:"Phone country is Required", 
      }))
     }
     if(accountData.firstName && accountData.lastName && accountData.phone && accountData.country) {
      updateUserDetails()
     }
  }
 return (
  <Box>
    <Box className="side-drawer-container">
        <SideDrawer />
    </Box>
      <Container>
          <Grid container>
            <Box component="form" noValidate onSubmit={() => {}} sx={{ mt: 3 }}>
            <Typography component="h3" variant="h6" sx={{fontWeight:"600",fontSize:"1.85rem", mb: 2}} >
              Edit account
            </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1" component="label" htmlFor="firstName" gutterBottom sx={{ fontWeight: "bold" }}>First Name *</Typography>
                  <TextField
                    required
                    fullWidth
                    id="name"
                    name="name"
                    value={accountData.firstName}
                    error={!!accountErrros.firstName}
                    helperText={accountErrros.firstName ? accountErrros.firstName : ""}
                    placeholder="Enter your First Name"
                    onChange={(e)=> handleFormChange(e.target.value,"firstName")}
                    sx={AuthInputFieldStyle}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <ProfileIcon />
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1" component="label" htmlFor="lastName" gutterBottom sx={{ fontWeight: "bold" }}>Last Name *</Typography>
                  <TextField
                    required
                    fullWidth
                    name="email"
                    type="email"
                    id="email"
                    value={accountData.lastName}
                    error={!!accountErrros.lastName}
                    helperText={accountErrros.lastName ? accountErrros.lastName : ""}
                    onChange={(e)=> handleFormChange(e.target.value,"lastName")}
                    placeholder="Enter your last name"
                    sx={AuthInputFieldStyle}
                  />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1" component="label" htmlFor="phone" gutterBottom sx={{ fontWeight: "bold" }}>Phone *</Typography>
                    <TextField
                      required
                      fullWidth
                      id="phone"
                      name="phone"
                      value={accountData.phone}
                      error={!!accountErrros.phone}
                      helperText={accountErrros.phone ? accountErrros.phone : ""}
                      placeholder="Enter your phone number"
                      onChange={(e)=> handleFormChange(e.target.value,"phone")}
                      sx={AuthInputFieldStyle}
                    />
                </Grid>
                <Grid item xs={12}>
                  <CountryDropdown
                    selectedCountry={accountData.country}
                    onCountryChange={(e, value) => handleFormChange(value || '', 'country')}
                    error={accountErrros.country}
                  />
                </Grid>
              </Grid>
              <Button
                fullWidth
                variant="contained"
                size="large"
                sx={{ mt: 3, mb: 2, background:"#012fff",fontWeight:"600",borderRadius:"10px",textTransform:"none" , fontSize:"1rem"}}
                onClick={handleAccountUpdate}
              >
                Update
              </Button>
            </Box>
          </Grid>
      </Container>
   <CustomizedAlert
   anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
   alertState={snackBar.open}
   title={snackBar.severity === 'error' ? 'Error' : snackBar.severity==="success" ? "Success" : "Warning" }
   description={snackBar.message}
   severity={snackBar.severity}
   handleCloseSnackbar={handleCloseSnackbar}
   />
  </Box>

 )
}

export default EditAccount;