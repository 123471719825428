import {
  Box,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import { VscEye } from "react-icons/vsc";
import { AuthInputFieldStyle } from "../../../ProcessResume/EditForms/ProcessResumeUtils";
import { SecondaryButton } from "../../button/Buttons";
import { useForm } from "react-hook-form";
import authenticatedFetch from "../../../services/AuthenticatedFetch";
import toast from "react-hot-toast";
import { responseHandler } from "../../../utils/responseHandler";
import { tecknoApiBaseUrl } from "../../../utils/config";

interface UpdatePasswordForm {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const ResetPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
    reset,
    watch,
  } = useForm<UpdatePasswordForm>({
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
  });

  const [loading, setLoading] = useState(false);

  const newPassword = watch("newPassword");

  const [showPassword, setShowPassword] = useState({
    currentPassword: false,
    newPassword: false,
  });

  const onSubmit = async (data: UpdatePasswordForm) => {
    try {
      setLoading(true);

      const body = JSON.stringify({
        currentPassword: data.currentPassword,
        newPassword: data.newPassword,
      });

      //API call to reset the password
      const response = await authenticatedFetch(
        `${tecknoApiBaseUrl}/api/change-password`,
        {
          method: "POST",
          body,
        }
      );

      if (response.status === 401) {
        toast.error("Invalid current password.");
      } else {
        responseHandler(response);
      }

      if (response.ok) {
        reset();
        toast.success("Password updated successfully!");
      }
    } catch (error) {
      console.error("Error updating password:", error);
    } finally {
      setLoading(false);
    }
  };

  const validateConfirmPassword = useCallback(
    (value: string) => {
      return value === newPassword || "Passwords do not match";
    },
    [newPassword]
  );

  return (
    <Box
      sx={{
        mt: 2,
        maxWidth: 500,
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <Grid item xs={12} sm={6}>
        <Typography
          variant="body1"
          component="label"
          htmlFor="currentPassword"
          gutterBottom
          aria-required
          sx={{ fontWeight: "bold" }}
          className="required-field"
        >
          Current Password
        </Typography>
        <TextField
          sx={{ ...AuthInputFieldStyle, mt: 0.5 }}
          fullWidth
          id="currentPassword"
          type={showPassword.currentPassword ? "text" : "password"}
          margin="normal"
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{ cursor: "pointer" }}
                onClick={() =>
                  setShowPassword({
                    ...showPassword,
                    currentPassword: !showPassword.currentPassword,
                  })
                }
              >
                <VscEye />
              </InputAdornment>
            ),
          }}
          {...register("currentPassword", {
            setValueAs: (value) => value.trim(),
            required: "Current password is required",
          })}
        />
        <Typography variant="body2" sx={{ color: "red" }}>
          {errors.currentPassword?.message}
        </Typography>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography
          variant="body1"
          component="label"
          htmlFor="newPassword"
          gutterBottom
          aria-required
          sx={{ fontWeight: "bold" }}
          className="required-field"
        >
          New Password
        </Typography>
        <TextField
          sx={{ ...AuthInputFieldStyle, mt: 0.5 }}
          fullWidth
          id="newPassword"
          type={showPassword.newPassword ? "text" : "password"}
          margin="normal"
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{ cursor: "pointer" }}
                onClick={() =>
                  setShowPassword({
                    ...showPassword,
                    newPassword: !showPassword.newPassword,
                  })
                }
              >
                <VscEye />
              </InputAdornment>
            ),
          }}
          {...register("newPassword", {
            setValueAs: (value) => value.trim(),
            required: "Password is required",
          })}
        />
        <Typography variant="body2" sx={{ color: "red" }}>
          {errors.newPassword?.message}
        </Typography>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography
          variant="body1"
          component="label"
          htmlFor="confirmPassword"
          gutterBottom
          aria-required
          sx={{ fontWeight: "bold" }}
          className="required-field"
        >
          Confirm New Password
        </Typography>
        <TextField
          sx={{ ...AuthInputFieldStyle, mt: 0.5 }}
          fullWidth
          id="confirmPassword"
          type={showPassword.newPassword ? "text" : "password"}
          margin="normal"
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{ cursor: "pointer" }}
                onClick={() =>
                  setShowPassword({
                    ...showPassword,
                    newPassword: !showPassword.newPassword,
                  })
                }
              >
                <VscEye />
              </InputAdornment>
            ),
          }}
          {...register("confirmPassword", {
            setValueAs: (value) => value.trim(),
            required: "Password is required",
            validate: validateConfirmPassword,
          })}
        />
        <Typography variant="body2" sx={{ color: "red" }}>
          {errors.confirmPassword?.message}
        </Typography>
      </Grid>

      <SecondaryButton
        variant="contained"
        color="primary"
        sx={{ mt: 2, alignSelf: "flex-end" }}
        loading={loading}
        onClick={handleSubmit(onSubmit)}
        disabled={!Object.keys(dirtyFields).length}
      >
        Save
      </SecondaryButton>
    </Box>
  );
};

export default ResetPassword;
