import { Card, CardContent, Typography, IconButton, Chip, Box, Stack, Button } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import InfoIcon from '@mui/icons-material/Info';
import { Error, ExpandMore, ExpandLess, Check } from '@mui/icons-material';
import { useState } from 'react';
import { GradientButton } from '../button/GradientButton';
import { AI_Spark_Icon } from '../Icons';

const IssueCard = (props: any) => {
  const { role, company, startDate, endDate, location, status="Fixed", level="High" } = props;

  const [expanded, setExpanded] = useState(false);

  const getLevelDetails = (level: string) => {
    switch (level) {
      case 'High':
        return { color: '#EF4444', icon: <Error style={{color: "#EF4444"}} /> };
      case 'Medium':
        return { color: '#FF8A1C', icon: <Error style={{color: "#FF8A1C"}} /> };
      case 'Low':
        return { color: '#E9CB33', icon: <Error style={{color: "#E9CB33"}} /> };
      default:
        return { color: 'info.main', icon: <Error color="info" /> };
    }
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };


  return (
    <Card variant="outlined" sx={{ borderRadius: 2, mb: 2, borderColor: "#E2E8F0", boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)" }}>
      <CardContent sx={{ '&:last-child': { pb: 1.5 }, py: 1.5 }}>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
          <Stack direction="row" alignItems="center" spacing={1}>
            {getLevelDetails(level).icon}
            <Typography variant="h6">{role}</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            {status === 'Fixed' ? (
              <Chip label="Fixed" icon={<Check />}
                sx={{
                  color: 'green',
                  fontSize: 15,
                  backgroundColor: 'transparent',
                  '& .MuiChip-icon': {
                    color: 'green',
                  },
                }}
              />
            ) : (
              <GradientButton variant="contained" color="secondary" size="small">
                <AI_Spark_Icon/>
                Fix it
              </GradientButton>
            )}
            <IconButton onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
              {expanded ?  <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Stack>
        </Box>
        
        <Typography variant="body2" color="text.secondary">
          {company}
        </Typography>
        
        {(startDate || endDate || location) && (
          <Typography variant="body2" color="text.secondary">
            {startDate ?? ''} - {endDate ?? ''} | {location ?? ''}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default IssueCard;