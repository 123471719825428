import { Autocomplete, Box, Button, Chip, createFilterOptions, Modal, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setExtractedData } from "../../redux/actions";
import ReactQuill from "react-quill";
import { useEffect, useRef, useState, useCallback } from "react";
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import { hobbiesData, languagesData, skillOption } from "../../Data/skillsData";
import { ReactComponent as CourseIcon } from "../../assets/SVG/courses-icon.svg";
import { ReactComponent as ProjectIcon } from "../../assets/SVG/project-icon.svg";
import { ReactComponent as ReferencessIcon } from "../../assets/SVG/preferencess-icon.svg";
import { ReactComponent as LanguagesIcon } from "../../assets/SVG/languages-icon.svg";
import { ReactComponent as AwardsIcon } from "../../assets/SVG/award-icon.svg";
import { ReactComponent as HobbiesIcon } from "../../assets/SVG/hobbies-icon.svg";
import { ReactComponent as VoulnteerIcon } from "../../assets/SVG/volunteer-work-icon.svg";
import { ReactComponent as ExtarCurricularActivitiesIcon } from "../../assets/SVG/ExtraCurricularActivities-icon.svg";
import { ReactComponent as CustomSection } from "../../assets/SVG/custom-icon.svg";
import { ReactComponent as AddSectionIcon } from "../../assets/SVG/add-section-icon.svg";
import { ReactComponent as RemoveSectionIcon } from "../../assets/SVG/new-delete-section.svg";
import { ReactComponent as CancelModalIcon } from "../../assets/SVG/cancel-modal.svg";
import { ReactComponent as AiIcon } from "../../assets/SVG/ai-description-icon.svg";
import Languages from "./SummarySections/Languages";
import Hobbies from "./SummarySections/Hobbies";
import CoursesList from "./SummarySections/Courses/CoursesList";
import ProjectsList from "./SummarySections/Projects/ProjectsList";
import AwardsList from "./SummarySections/Awards/AwardsList";
import ReferencesList from "./SummarySections/Referencess/ReferencesList";
import VolunteerWorksList from "./SummarySections/VolunteerWork/VolunterrWorksList";
import ExtraCAList from "./SummarySections/extraCA/ExtraCAList";
import CustomSectionList from "./SummarySections/CustomSection/CustomSectionList";
import ImproveResumeBtn from "../ImproveResumeBtn";
import GetHelpWithWriting from "../GetHelpWithWriting";
import ProfessionalSummaryModal from "./SummarySections/ProfessionalSummaryModal";
import { quillModules } from "../../App";
import { cleanSummaryHTMLstring } from "../../components/common/CommonFunctions";
import debounce from 'lodash.debounce';

export const summarSectionModalstyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: "15px",
    boxShadow: 0,
};

const Summary = () => {
    const dispatch = useDispatch();
    const extractedData = useSelector((state: any) => state.extractedData);
    const [removeableSections, setRemoveableSections] = useState<{ label: string, value: string, isEditable?: boolean }[]>([]);
    const careerObjectiveInputRef = useRef(null);
    const [otherSectionList, setOtherSectionList] = useState([
        {
            label: "Courses",
            value: "courses",
            icon: <CourseIcon />,
            visible: true,
        },
        {
            label: "Projects",
            value: "projects",
            icon: <ProjectIcon />,
            visible: true,
        },
        {
            label: "References",
            value: "references",
            icon: <ReferencessIcon />,
            visible: true,
        },
        {
            label: "Languages",
            value: "languages",
            icon: <LanguagesIcon />,
            visible: true,
        },
        {
            label: "Awards",
            value: "awards",
            icon: <AwardsIcon />,
            visible: true,
        },
        {
            label: "Hobbies",
            value: "hobbies",
            icon: <HobbiesIcon />,
            visible: true,
        },
        {
            label: "Volunteer Work",
            value: "volunteerWorks",
            icon: <VoulnteerIcon />,
            visible: true,
        },
        {
            label: "Extracurricular Activities",
            value: "extraCA",
            icon: <ExtarCurricularActivitiesIcon />,
            visible: true,
        },
        {
            label: "Custom section",
            value: "customSection",
            icon: <CustomSection />,
            visible: true,
        },
    ]);
    const [summarySectionModal, setSummarySectionModal] = useState({ modalState: false, sectionName: "", sectionDelete: false });
    const [professionalModal, setProfessionalModal] = useState<boolean>(false);
    const [summary, setSummary] = useState<string>("");

    /**
     * Below useEffect is to set the summary value initially
    */
    useEffect(() => {
        if (extractedData?.professionalSummary) {
            setSummary(extractedData?.professionalSummary);
        }
    }, []);

    const debouncedUpdate = useCallback(
        debounce((cleanSummary) => {
            dispatch(setExtractedData({
                ...extractedData,
                professionalSummary: cleanSummary,
            }));
        }, 100),
        [dispatch, extractedData]
    );

    useEffect(() => {
        const cleanSummary = cleanSummaryHTMLstring(summary);
        debouncedUpdate(cleanSummary);
    }, [summary, debouncedUpdate]);

    const handleSummaryChange = (e: any) => {
        setSummary(e);
    }

    //to delete section 
    useEffect(() => {
        if (summarySectionModal?.modalState && summarySectionModal?.sectionDelete) {
            setRemoveableSections((prev) => prev.filter(sec => sec.value !== summarySectionModal?.sectionName))
            let updatedOtherSection = otherSectionList?.map((ots) => {
                if (ots.value === summarySectionModal?.sectionName) {
                    return {
                        ...ots,
                        visible: true
                    }
                }
                return ots;
            })


            /**
             * If It's a custom section then remove it
            */
            if (summarySectionModal?.sectionName?.includes("customSection")) {
                updatedOtherSection = updatedOtherSection?.filter((sec) => sec?.value !== summarySectionModal?.sectionName);
                const updatedCustomSectionObj = extractedData.customSections;
                delete updatedCustomSectionObj?.[summarySectionModal?.sectionName];
                dispatch(setExtractedData({
                    ...extractedData,
                    customSections: {
                        ...updatedCustomSectionObj,
                    }
                }));
            }
            else {
                dispatch(setExtractedData({
                    ...extractedData,
                    [summarySectionModal?.sectionName]: [],
                }));
            }
            setOtherSectionList(updatedOtherSection);
        }
        setSummarySectionModal({ modalState: false, sectionName: "", sectionDelete: false })
    }, [summarySectionModal?.sectionDelete]);

    const handleChange = (newValue: any, key: string) => {
        dispatch(setExtractedData({ ...extractedData, [key]: newValue }));
    };

    const handleAddOtherSectionAction = (sectionName: { label: string; value: string }) => {
        setRemoveableSections([...removeableSections, sectionName]);
        const updatedOtherSection = otherSectionList?.map((ots) => {
            if (ots.value === sectionName?.value) {
                return {
                    ...ots,
                    visible: false
                }
            }
            return ots;
        })

        //Adding custom section persistancy always
        if (sectionName?.value.includes("customSection")) {
            const lastChar = sectionName?.value?.[sectionName?.value?.length - 1];
            if (isNaN(Number(lastChar))) {
                updatedOtherSection.push(
                    {
                        label: "Custom section",
                        value: "customSection1",
                        icon: <CustomSection />,
                        visible: true,
                    },
                )
            }
            else {
                updatedOtherSection.push(
                    {
                        label: `Custom section`,
                        value: `customSection${Number(lastChar) + 1}`,
                        icon: <CustomSection />,
                        visible: true,
                    },
                )
            }
        }
        setOtherSectionList(updatedOtherSection);
    }

    const handleSectionDelete = (section: string) => {
        setSummarySectionModal((prev) => ({ ...prev, modalState: true, sectionName: section }));
    }

    const sectionNameEditClick = (sectioName: string, sectionLabel: string) => {
        const updatedRemoveableSection = removeableSections?.map((section) => {
            if (section?.value === sectioName) {
                return { ...section, isEditable: true };
            }
            return section;
        })
        setRemoveableSections(updatedRemoveableSection)
    }

    const sectionNameDoneClick = (sectionName: string, sectionLabel: string) => {
        const updatedRemoveableSection = removeableSections?.map((section) => {
            if (section?.value === sectionName) {
                return { ...section, label: sectionLabel, isEditable: false };
            }
            return section;
        })
        setRemoveableSections(updatedRemoveableSection)
        dispatch(setExtractedData({
            ...extractedData,
            customSections: {
                ...extractedData.customSections,
                [sectionName]: { ...extractedData.customSections?.[sectionName], label: sectionLabel },
            }
        }));
    }

    return (
        <Box className="summary-container">
            <Box className="professional-summary-section">
                <Box className="heading-box" sx={{ justifyContent: "space-arround", alignItems: "center" }}>
                    <Typography variant="h5" color="color.primary" className="heading">
                        Professional Summary
                    </Typography>
                    <ImproveResumeBtn section={"professionalSummary"} value={extractedData.professionalSummary} />
                    {/* <GetHelpWithWriting section={"careerObjective"} value={extractedData.careerObjective} inputRef={careerObjectiveInputRef}/> */}
                    <Box
                        sx={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginLeft: 2,
                            background: "linear-gradient(to left,#5594FF,#687AF3,#D080ff)",
                            borderRadius: "5.71px",
                            cursor: 'pointer',
                            color: "#FFF",
                            px: 1,
                            py: 0.5,
                        }}
                    // onClick={handleClick}
                    >
                        <AiIcon /> <Typography variant="caption" sx={{ marginLeft: "0.3rem", fontSize: "1rem", fontWeight:"500" }} onClick={() => setProfessionalModal(true)}>Summary with AI</Typography>
                    </Box>
                </Box>

                <Box className="professional-summary">
                    <Box className="quill-container">
                        <ReactQuill
                            ref={careerObjectiveInputRef}
                            placeholder="Add the relevant description."
                            value={summary}
                            onChange={(e) => handleSummaryChange(e)}
                            theme="snow"
                            modules={quillModules}
                        />
                    </Box>
                </Box>
            </Box>

            {removeableSections.map(section => {
                if (section?.value === "languages") {
                    return (
                        <Languages handleSectionDelete={handleSectionDelete} sectionName={section?.value} />
                    )
                }
                else if (section?.value === "hobbies") {
                    return (
                        <Hobbies handleSectionDelete={handleSectionDelete} sectionName={section?.value} />
                    )
                }
                else if (section?.value === "courses") {
                    return (
                        <CoursesList handleSectionDelete={handleSectionDelete} sectionName={section?.value} />
                    )
                }
                else if (section?.value === "projects") {
                    return (
                        <ProjectsList handleSectionDelete={handleSectionDelete} sectionName={section?.value} />
                    )
                }
                else if (section?.value === "awards") {
                    return (
                        <AwardsList handleSectionDelete={handleSectionDelete} sectionName={section?.value} />
                    )
                }
                else if (section?.value === "references") {
                    return (
                        <ReferencesList handleSectionDelete={handleSectionDelete} sectionName={section?.value} />
                    )
                }
                else if (section?.value === "volunteerWorks") {
                    return (
                        <VolunteerWorksList handleSectionDelete={handleSectionDelete} sectionName={section?.value} />
                    )
                }
                else if (section?.value === "extraCA") {
                    return (
                        <ExtraCAList handleSectionDelete={handleSectionDelete} sectionName={section?.value} />
                    )
                }
                /**
                 * Below else block is to render all custom Sections
                */
                else {
                    return (
                        <CustomSectionList handleSectionDelete={handleSectionDelete} sectionName={section?.value} sectionLabel={section?.label} sectionNameEditClick={sectionNameEditClick} isEditable={section?.isEditable || false} sectionNameDoneClick={sectionNameDoneClick} />
                    )
                }

            })}

            <Box className="other-section-container">
                <Box className="heading-box">
                    <Typography variant="h5" color="color.primary" className="heading">
                        Other
                    </Typography>
                </Box>

                <Box className="other-section-content">
                    {otherSectionList?.map((ots) => {
                        return ots.visible ?
                            <Box className="chip-wrapper" onClick={() => handleAddOtherSectionAction({ label: ots.label, value: ots.value })} >
                                <Box className="icon-container">
                                    {ots.icon}
                                </Box>

                                <Box className="text-cont">
                                    <Typography className="other-name">{ots.label}</Typography>

                                </Box>
                                <Box className="action-cont">
                                    <Box sx={{ paddingRight: '0.2rem' }}>
                                        <AddSectionIcon />
                                    </Box>
                                </Box>
                            </Box>
                            : null;
                    }
                    )}
                </Box>

            </Box>

            <Modal
                onClose={() => setSummarySectionModal((prev) => ({ ...prev, modalState: false, sectionDelete: false }))}
                open={summarySectionModal?.modalState}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={summarSectionModalstyle}>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        pt: 3,
                        pl: 3,
                        pr: 3,
                        pb: 3,
                    }}>
                        <Box
                            sx={{ width: "100%", display: "flex", justifyContent: "left", alignItems: "center", gap: "1rem", marginBottom: "1.75rem" }}
                        >
                            <RemoveSectionIcon />
                            <Typography sx={{ flex: "1", fontSize: "24px", fontWeight: "500", color: "#344054" }} id="keep-mounted-modal-title" variant="h6" component="h2">
                                Remove Section
                            </Typography>
                            <Box>
                                <Box sx={{ cursor: "pointer" }} onClick={() => setSummarySectionModal((prev) => ({ ...prev, modalState: false, sectionDelete: false }))}>
                                    <CancelModalIcon />
                                </Box>
                            </Box>
                        </Box>

                        <Typography sx={{ fontSize: "18px", fontWeight: "400", color: "#475569" }} id="keep-mounted-modal-description">
                            Are you sure you want to delete this section ?
                        </Typography>
                        <Typography sx={{ fontSize: "18px", fontWeight: "500", padding: "0.5rem 0", color: "#344054" }}>"{summarySectionModal?.sectionName}"</Typography>
                    </Box>
                    <Box sx={{
                        width: "100%", display: "flex", justifyContent: "right", gap: "1rem",
                        pt: 0.5,
                        pr: 3,
                        pb: 3,
                    }}>
                        <Button onClick={() => setSummarySectionModal((prev) => ({ ...prev, sectionDelete: true }))} sx={{ border: "1px solid #cbd5e1", color: "#182230" }}>Delete</Button>
                        <Button variant="contained" onClick={() => setSummarySectionModal((prev) => ({ ...prev, modalState: false, sectionDelete: false }))}>Cancel</Button>
                    </Box>
                </Box>

            </Modal>

            {<ProfessionalSummaryModal professionalModal={professionalModal} setProfessionalModal={setProfessionalModal} summary={summary} setSummary={setSummary} />}
        </Box>
    )
}

export default Summary;