import React, { useState } from "react";
import { Box, Typography, Divider } from "@mui/material";
import { StyledTab, StyledTabs } from "../../StyledComponents/StyledTabs";
import TabPanel from "../../StyledComponents/TabPanel";
import PersonalDetails from "./PersonalDetails";
import SocialLinks from "./SocialLinks";

const MyProfile = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleActiveTab = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Box sx={{ flex: 1, padding: 4 }}>
      <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
        My Profile
      </Typography>
      <Typography variant="body1" gutterBottom>
        Update your personal details here.
      </Typography>

      <Divider sx={{ my: 2.5 }} />

      <StyledTabs value={activeTab} onChange={handleActiveTab}>
        <StyledTab label="Personal details" />
        <StyledTab label="Social Links" />
      </StyledTabs>

      <TabPanel value={activeTab} index={0}>
        <PersonalDetails />
      </TabPanel>

      <TabPanel value={activeTab} index={1}>
        <SocialLinks />
      </TabPanel>
    </Box>
  );
};

export default MyProfile;
