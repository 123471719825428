import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useDispatch, useSelector } from 'react-redux';
import { StateProps } from '../redux/reducer';
import {ReactComponent as DashboardIcon} from "../assets/SVG/dashboard-side-bar-icon.svg";
import {ReactComponent as ResumesIcon} from "../assets/SVG/resumes-sidebar-icon.svg";
import {ReactComponent as AtsIcon} from "../assets/SVG/atsCheckerSidebar.svg";
import {ReactComponent as ProfileIcon} from "../assets/SVG/myProfileSidebar.svg";
import {ReactComponent as WorkIcon} from "../assets/SVG/job-side-bar-icon.svg";
import { useLocation, useNavigate } from 'react-router-dom';
import { setSideDrawerState, logoutUser } from '../redux/actions';
import {ReactComponent as TeknoHeaderIcon} from "../assets/SVG/TeknoHeaderLogo.svg";
import {ReactComponent as NotificationIcon} from "../assets/SVG/bell-side-bar-icon.svg";
import {ReactComponent as SettingsIcon} from "../assets/SVG/settings-new-color.svg";
import MenuItem from '@mui/material/MenuItem';
import { Avatar, Menu } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { removeToken } from '../components/auth/storeToken';
import  cn  from 'classnames';
import SaveAsDraftModal from './SaveAsDraftModal';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Logout } from '@mui/icons-material';
import { url } from 'inspector';
import { handleLogout, withAuthHandling } from '../services/apiService';
import { isSavingEmpty } from '../components/common/CommonFunctions';

const drawerWidth = 200;

const sidebarmenuList = [
    {
        name:"Dashboard",
        icon:<DashboardIcon/>,
        url:"/dashboard",
    },
    {
        name:"Resumes",
        icon:<ResumesIcon/>,
        url:"/my-resumes",
    },
    {
        name:"ATS Checker",
        icon:<AtsIcon/>,
        url:"/ats-checker",
    },
    {
        name:"My Profile",
        icon:<ProfileIcon/>,
        url:"/",
    },
    {
        name:"Job Board",
        icon:<WorkIcon/>,
        url:"/job-board",
    },
];

const sidebarmenuListBottom = [
  {
    name:"Notifications",
    icon:<NotificationIcon/>,
    url:"/",
  },
  {
    name:"Settings",
    icon:<SettingsIcon/>,
    url:"/settings",
  },
  {
    name:"Logout",
    icon:<Logout sx={{ color: "#94A3B8" }}/>,
    url:"/",
  },
]

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });
  
  const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(8)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(9)} + 1px)`,
    },
  });

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: "0",
    cursor: "pointer",
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      position:'static',
      '& .MuiDrawer-paper': {
        padding: "0px 20px",
      },
      variants: [
        {
          props: ({ open }) => open,
          style: {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
          },
        },
        {
          props: ({ open }) => !open,
          style: {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
          },
        },
      ],
    }),
  );


interface SideDrawerProps {
  saveAsDraftModal?: boolean;
  setSaveAsDraftModal?: Dispatch<SetStateAction<boolean>>;
}

const SideDrawer: React.FC<SideDrawerProps> = ({ saveAsDraftModal = false, setSaveAsDraftModal = () => {} }) => {
const theme = useTheme();
const navigate = useNavigate();
const open = useSelector((state:StateProps)=> state.sideDrawer);

const isDraft = useSelector((state: StateProps) => state.isDraft);
const extractedData = useSelector((state: StateProps) => state.extractedData);

const [modalUrls,setModalUrls] = useState("/");
const location = useLocation();
const urlPath = location.pathname;
const [selectedOption,setSelectedItem] = useState<string>("Dashboard");
const fetchWithAuthHandling = withAuthHandling(navigate);

useEffect(()=>{
  if(urlPath.includes("dashboard")) {
    setSelectedItem("Dashboard");
  }
  else if(urlPath.includes("resume")) {
    setSelectedItem("Resumes");
  }
  else if(urlPath.includes("ats-checker")){
    setSelectedItem("ATS Checker");
    
  }
  else if(urlPath.includes("job-board")){
    setSelectedItem("Job Board");
  }
  else if(urlPath.includes("settings")){
    setSelectedItem("Settings");
  }
  else {
    setSelectedItem("Dashboard");
  }
},[urlPath])


const dispatch = useDispatch();
const handleDrawerState = () => {
  dispatch(setSideDrawerState(!open))
}

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    removeToken()
    dispatch(logoutUser());
    navigate("/login")
  }

  const handleNavigation = (navigationUrl:string) => {
    // && !isSavingEmpty(extractedData)
    if(urlPath.includes("/create-resume") && isDraft){
      setModalUrls(navigationUrl)
      if(setSaveAsDraftModal) setSaveAsDraftModal(true);
    }
    else{
      navigate(navigationUrl);
    }

  }

  return (
    <Drawer variant="permanent" open={open} onMouseOver={() => dispatch(setSideDrawerState(true))} onMouseOut={() => dispatch(setSideDrawerState(false))} className="side-drawer-container">
    <DrawerHeader sx={{ py: 1 }} onClick={() => navigate("/dashboard")} className="drawer-header">
      <IconButton sx={{ p: 0 }}>
        <TeknoHeaderIcon />
      </IconButton>
      <Box sx={{ width:"7rem",display: "flex", flexDirection: "column", ml: 1, opacity: open ? 1 : 0 }}>
        <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
          Tekno.ai
        </Typography>
        <Typography sx={{ fontSize: "14px",fontWeight:400,color:"#667085" }}>
          Free Resume Builder
        </Typography>
      </Box>
      {/* <Box>
      <ChevronLeftIcon />
      </Box> */}
    </DrawerHeader>
    <Divider />
    <List className="drawer-list">
      {sidebarmenuList.map((element, index) => (
        <ListItem className={cn({"selected-item":element.name === selectedOption},"drawer-list-item")} onClick={()=>handleNavigation(element.url)} key={element.name} disablePadding
          sx={{
            display: 'block',
            '&:hover .sidebar-icon': {
              color: '#5594FF',
            },
          }}
        >
          <ListItemButton sx={{ px: 0, my: 1, justifyContent: "center", borderRadius: "8px" }} className="item-button">
            <ListItemIcon
              className={cn({"item-icon-1":index<2,
                "item-icon-2":index>=2
              })}
              sx={{ minWidth: "unset"}}
            >
              {element.icon}
            </ListItemIcon>
            <ListItemText
            className="item-text"
              primary={element.name}
              sx={[
                {
                  m: 0,
                  ml: 1,
                },
                open
                  ? {
                    opacity: 1,
                    color: "#344054",
                  }
                  : {
                    opacity: 0,
                    color: "#344054",
                  },
              ]
            }
            />
          </ListItemButton>
        </ListItem>
      ))}
    </List>

    <Box sx={{ flexGrow: 1 }} />

      <List className="drawer-list" sx={{marginBottom:"1rem"}}>
        {sidebarmenuListBottom?.map((element,index)=>(
          <ListItem
            onClick={() => {
              if(element.name === "Logout"){
                handleLogout(navigate);
              }
              else {
                navigate(element.url);
              }
            }}
          key={element.name}
          disablePadding
          className="drawer-list-item"
          sx={{
            display: 'block',
            '&:hover .sidebar-icon': {
              color: '#5594FF',
            },
          }}
        >
          <ListItemButton sx={{ px: 0, my: 1, justifyContent: "center", borderRadius: "8px" }} className="item-button">
            <ListItemIcon
              sx={{ minWidth: "unset" }}
              className={cn({"item-icon-1":index===0 || index===2,
                "item-icon-2":index===1
              })}
            >
              {element.icon}
            </ListItemIcon>
            <ListItemText
            className="item-text"
              primary={element.name}
              sx={[
                {
                  m: 0,
                  ml: 1
                },
                open
                  ? {
                    opacity: 1,
                    color: "#344054",
                  }
                  : {
                    opacity: 0,
                    color: "#344054",
                  },
              ]}
            />
          </ListItemButton>
        </ListItem>
        ))}
      </List>
  
  <Divider/>

    <List className="avatar-container">
        
          <ListItem disablePadding
          className="avatar-list-item"
        >
          <ListItemButton sx={{ px: 0, my: 1, justifyContent: "center", borderRadius: "8px" }} className="item-button">
            <ListItemIcon
              sx={{ minWidth: "unset" }}
              className="item-icon-1"
            >
              <Avatar sx={{boxShadow:"inset 0px -8px 16px 0px #6F5807" ,width:"2.5rem",height:"2.5rem"}}>SW</Avatar> 
               {/* rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset  "inset 0px -8px 16px 0px #6F5807" */}
            </ListItemIcon>
            <ListItemText
            className="item-text"
              sx={[
                {
                  m: 0,
                  ml: 1
                },
                open
                  ? {
                    opacity: 1,
                    color: "#344054",
                  }
                  : {
                    opacity: 0,
                    color: "#344054",
                  },
              ]}
            >
              <Typography sx={{color:"#354054",fontSize:"16px"}}>Sophia Williams</Typography>
              <Typography sx={{color:"#667085",fontSize:"14px",fontWeight:400}}>sophia@gmail.com</Typography>
            </ListItemText>
          </ListItemButton>
        </ListItem>
        
      </List>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              display: "none"
            },
          },
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => { navigate('/profile'); handleMenuClose(); }}>Profile</MenuItem>
        <MenuItem onClick={() => { navigate('/change-password'); handleMenuClose(); }}>Change Password</MenuItem>
        <MenuItem onClick={() => { logout() }}>Logout</MenuItem>
      </Menu>

      <SaveAsDraftModal saveAsDraftModal={saveAsDraftModal} setSaveAsDraftModal={setSaveAsDraftModal} modalUrls={modalUrls}/>
  </Drawer>
  )
}

export default SideDrawer;
