import { useState } from "react";
import {
  Box,
  Typography,
  Divider,
  Grid,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import { AuthInputFieldStyle } from "../../../ProcessResume/EditForms/ProcessResumeUtils";
import { useForm } from "react-hook-form";
import { SecondaryButton } from "../../button/Buttons";
import authenticatedFetch from "../../../services/AuthenticatedFetch";
import { responseHandler } from "../../../utils/responseHandler";
import { tecknoApiBaseUrl } from "../../../utils/config";
import SubmittedDialog from "./SubmittedDialog";

const ticketTypes = [
  {
    label: "Bug",
    value: "bug",
  },
  {
    label: "Request",
    value: "request",
  },
  {
    label: "Suggestion",
    value: "suggestion",
  },
];

interface ISupportFormValues {
  supportType: string;
  subject: string;
  description: string;
}

const HelpAndSupport = () => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
    setValue,
    watch,
    reset,
  } = useForm<ISupportFormValues>({
    defaultValues: {
      supportType: ticketTypes[0].value,
      subject: "",
      description: "",
    },
  });

  const supportType = watch("supportType");

  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  const onSubmit = async (data: ISupportFormValues) => {
    try {
      setLoading(true);

      const body = JSON.stringify({
        supportType: data.supportType,
        subject: data.subject,
        description: data.description,
      });

      //API call to create a support request
      const response = await authenticatedFetch(
        `${tecknoApiBaseUrl}/api/create-help-request`,
        {
          method: "POST",
          body,
        }
      );

      responseHandler(response);
      reset();

      if (response.ok) {
        setShowSuccessDialog(true);
      }
    } catch (error) {
      console.log("Error creating support request:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ flex: 1, padding: 4, display: "flex", flexDirection: "column" }}>
      <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
        Help & Support
      </Typography>
      <Typography variant="body1" gutterBottom>
        Get in touch with our Support team for any help!{" "}
      </Typography>

      <Divider sx={{ my: 2.5 }} />

      <Box
        sx={{
          mt: 2,
          maxWidth: 500,
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Grid item xs={12} sm={6}>
          <Typography
            variant="body1"
            component="label"
            htmlFor="fullName"
            gutterBottom
            aria-required
            sx={{ fontWeight: "bold", mb: 2 }}
          >
            Support Type
          </Typography>
          <Box>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              sx={{ mt: 0.5, width: "100%" }}
              value={supportType}
              onChange={(event) => setValue("supportType", event.target.value)}
            >
              {ticketTypes.map((ticket) => (
                <MenuItem value={ticket.value}>{ticket.label}</MenuItem>
              ))}
            </Select>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography
            variant="body1"
            component="label"
            htmlFor="subject"
            gutterBottom
            aria-required
            sx={{ fontWeight: "bold" }}
            className="required-field"
          >
            Subject
          </Typography>
          <TextField
            sx={{ ...AuthInputFieldStyle, mt: 0.5 }}
            fullWidth
            id="subject"
            type="text"
            margin="normal"
            placeholder="Enter your subject line"
            {...register("subject", {
              setValueAs: (value) => value.trim(),
              required: "Subject is required",
            })}
          />
          <Typography variant="body2" sx={{ color: "red" }}>
            {errors.subject?.message}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography
            variant="body1"
            component="label"
            htmlFor="description"
            gutterBottom
            aria-required
            sx={{ fontWeight: "bold" }}
            className="required-field"
          >
            Description
          </Typography>
          <TextField
            sx={{ ...AuthInputFieldStyle, mt: 0.5 }}
            multiline
            rows={6}
            fullWidth
            id="description"
            type="text"
            margin="normal"
            placeholder="Enter detailed description of your concern here"
            {...register("description", {
              setValueAs: (value) => value.trim(),
              required: "Description is required",
            })}
          />
          <Typography variant="body2" sx={{ color: "red" }}>
            {errors.description?.message}
          </Typography>
        </Grid>

        <SecondaryButton
          variant="contained"
          color="primary"
          sx={{ mt: 2, alignSelf: "flex-end" }}
          loading={loading}
          onClick={handleSubmit(onSubmit)}
          disabled={!Object.keys(dirtyFields).length}
        >
          Save
        </SecondaryButton>
      </Box>

      <SubmittedDialog
        open={showSuccessDialog}
        onClose={setShowSuccessDialog}
      />
    </Box>
  );
};

export default HelpAndSupport;
