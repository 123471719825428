import React from 'react';
import { Box, Skeleton } from '@mui/material';
import { keyframes } from '@emotion/react';

// Define the keyframe animation for the horizontal pulse effect
const pulse = keyframes`
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
`;

const Shimmer: React.FC<{ count?: number, disableMarginTop?: boolean }> = ({ count = 6, disableMarginTop = false }) => {
  return (
    <>
      {Array.from(new Array(count)).map((_, index) => (
        <Box key={index} sx={{ border: "2px solid #E2E8F0", borderRadius: "8px", overflow: "hidden", height: "340px", mt: disableMarginTop ? 0 : 4, mb: 2 }}>
          <Skeleton
            variant="rectangular"
            width="100%"
            height="100%"
            sx={{
              background: `linear-gradient(90deg, #f7f7f7 25%, #f3f3f3 50%, #f7f7f7 75%)`,
              backgroundSize: '200% 100%',
              animation: `${pulse} 4s infinite`,
            }}
          />
        </Box>
      ))}
    </>
  );
};

export default Shimmer;