import { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { StyledTab, StyledTabs } from "../StyledComponents/StyledTabs";
import TabPanel from "../StyledComponents/TabPanel";
import ResumesList from "../ResumesList";
import { Templates } from "../utils";
import { initialState, StateProps } from "../../redux/reducer";
import { useDispatch, useSelector } from "react-redux";
import { setExtractedData, setSelectedTemplateId } from "../../redux/actions";
import { useNavigate } from "react-router-dom";

const HomeScreenTabs = () => {

  const [value, setValue] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedTemplateId = useSelector((state: StateProps) => state?.selectedTemplateId);


  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
  };

  return (
    <Box>
      <StyledTabs value={value} onChange={handleChange}>
          <StyledTab label="Recent" />
          <StyledTab label="Templates" />
      </StyledTabs>
      <TabPanel value={value} index={0}>
          <ResumesList />
      </TabPanel>
      <TabPanel value={value} index={1}>
          <Box sx={{ mt: 2, display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))', gap: 2 }}>
            {Templates.map((template, index) => (
                <Box
                    key={index}
                    onClick={() => {
                        dispatch(setSelectedTemplateId(template.id))
                        dispatch(setExtractedData(initialState.extractedData));
                        navigate(`/create-resume/new/personal-details`);
                    }}
                    className={`template-preview-box`}
                    sx={{
                        height: 340,
                        cursor: "pointer",
                        border: "2px solid #E2E8F0",
                        borderRadius: "8px",
                        transition: "border 0.1s",
                        '&:hover': {
                            border: "2px solid #5594FF",
                        },
                    }}
                >
                    {
                        template.img && <img src={template.img} className="template-preview-img-in-tabs"/>
                    }
                </Box>
            ))}
          </Box>
      </TabPanel>
    </Box>
  );
};

export default HomeScreenTabs;