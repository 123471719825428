import React, { useEffect, useState } from 'react';
import { Document, Page, Text, View, StyleSheet, Font, Image } from '@react-pdf/renderer';
import { CourseType, defaultProfilePicture, EducationTypes, IReference, IResume, isValueExist, WorkExperienceTypes } from '../components/utils';
import parse, { DOMNode, domToReact, Element } from 'html-react-parser';
import { convertChildNodesToDOMNodes, hasObjTruthyValue, isPhoneNumberExists } from '../components/common/CommonFunctions';

Font.register({
  family: 'Posterama',
  src: `${process.env.REACT_APP_API_URL}/Posterama-1927.ttf`,
});

Font.register({
  family: 'Posterama Bold',
  src: `${process.env.REACT_APP_API_URL}/Posterama-Bold.ttf`,
});


const styles = StyleSheet.create({
  page: {
    // flexDirection: 'column',
    padding: 20,
  },
  mainContainer: {
    flexDirection: 'row',
  },
  leftColumn: {
    flex: 0.5,
    padding: 20,
    border: "2px solid #6D509B",
    // backgroundColor: 'rgb(246, 247, 248)', // Set the background color
  },
  rightColumn: {
    flex: 1, // Occupy half of the page width
    marginLeft: 20, // Add a margin between columns
  },
  name: {
    fontSize: 34,
    fontFamily: "Posterama Bold",
    fontWeight: "bold",
    marginTop: 10,
    marginBottom: 10,
    textTransform: "capitalize",
    color: '#6D509B'
  },
  jobTitle: {
    fontSize: 14,
    color: 'rgb(89, 89, 89)'
  },
  mt20: {
    marginTop: 20,
  },
  mb0: {
    marginBottom: 0,
  },
  mb10: {
    marginBottom: 10,
  },
  sectionHeader: {
    fontSize: 16,
    color: '#6D509B',
    textTransform: 'uppercase',
    fontFamily: "Posterama Bold",
    fontWeight: 'bold',
    marginBottom: 10,
  },
  customSubSection:{
    fontSize:14,
  },
  sectionSubHeader: {
    fontSize: 16,
    fontFamily: "Posterama",
  },
  sectionContent: {
    fontSize: 12,
    lineHeight: 1.5,
  },
  skills: {
    fontSize: 12,
    marginBottom: 10,
  },
  languages: {
    fontSize: 12,
    marginBottom: 10,
  },
  contact: {
    fontSize: 12,
    marginTop: 20,
  },
  atsIssue: {
    position: "relative"
  },
  atsIssueHigh: {
    padding: 5,
    border: "1.5px solid #EF4444",
    backgroundColor: "rgba(255, 182, 182, 0.20)",
  },
  atsIssueMedium: {
    padding: 5,
    border: "1.5px solid #FF8A1C",
    backgroundColor: "rgba(255, 224, 156, 0.20)"
  },
  atsIssueLow: {
    padding: 5,
    border: "1.5px solid #E9CB33",
    backgroundColor: "rgba(233, 203, 51, 0.20)"
  },
  atsIssueFixed: {
    padding: 5,
    border: "1px solid #19A44C"
  },
  fixATSButton: {
    width: 20,
    height: 20,
    position: "absolute",
    right: -10,
    top: -10,
  },
  fixATSButtonImg: {
    width: 20,
    height: 20
  },
 flexCenter:{
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  marginLeft:30
 }
});

const Template_2 = (props: any) => {

  let {extractedData, pageWidth, isATSPreview = false} : {extractedData: any, pageWidth: any, isATSPreview: boolean} = props;

  /* if(isATSPreview)
  extractedData = {
      "name": {
          "firstName": "Gaia",
          "lastName": "Park"
      },
      "profilePicture": null,
      "email": "gaiaparkemailcom",
      "phone": [
          "123 4567890"
      ],
      "location": "Redmond WA",
      "socialLinks": {
          "personal_Website": "NA",
          "linkedIn_URL": "NA",
          "github_URL": "NA",
          "twitter_X_URL": null
      },
      "professionalSummary": "NA",
      "designation": "Principal Software Engineer",
      "educationalDetails": [
          {
              "universityCollege": "University of California Berkeley",
              "degree": "MS Computer Science",
              "location": null,
              "isEdit": null,
              "isNewEntry": null,
              "index": "1561eef5-1034-4d9d-81b2-74b2bda9b6d4",
              "specialization": "NA",
              "gpa": "NA",
              "startDate": "2014",
              "endDate": "2016"
          },
          {
              "universityCollege": "University of California Berkeley",
              "degree": "BS Electrical Engineering",
              "location": null,
              "isEdit": null,
              "isNewEntry": null,
              "index": "48f126d0-1a52-4cdb-a292-972d5e45807e",
              "specialization": "NA",
              "gpa": "NA",
              "startDate": "2010",
              "endDate": "2014"
          }
      ],
      "workExperiences": [
          {
              "startDate": "October 2020",
              "endDate": "current",
              "company": "Microso",
              "jobType": null,
              "role": "Principal Software Engineer",
              "location": null,
              "isEdit": null,
              "isNewEntry": null,
              "index": "f0234f9f-c51a-495e-ab9d-9ec60adb2b7d",
              "keyResponsibilities": "Led the migration of legacy Java applications to Python improving code maintainability by 12 and reducing bug rates by 16\nBuilt custom Eclipse plugin that streamlined software development process reducing build times by 24\nArchitected and deployed solutions on Red Hat OpenShift cutting timetomarket of mobile apps in half\nSpearheaded a new product launch contributing 800K to annual revenue and a 5 rise in market share",
              "keyResponsibilitiesArray": [
                  "Led the migration of legacy Java applications to Python improving code maintainability by 12 and reducing bug rates by 16",
                  "Built custom Eclipse plugin that streamlined software development process reducing build times by 24",
                  "Architected and deployed solutions on Red Hat OpenShift cutting timetomarket of mobile apps in half",
                  "Spearheaded a new product launch contributing 800K to annual revenue and a 5 rise in market share"
              ],
              "projectDetails": "NA",
              "toolsTechnologies": "NA"
          },
          {
              "startDate": "August 2017",
              "endDate": "August 2020",
              "company": "3D Robotics",
              "jobType": null,
              "role": "Software Tester",
              "location": null,
              "isEdit": null,
              "isNewEntry": null,
              "index": "923ba643-b23f-4e19-9a59-1883485b1e29",
              "keyResponsibilities": "Developed Python scripts to automate functional and regression testing increasing test coverage and reducing manual effort by 37\nT ested C applications identifying bottlenecks and optimizing code to improve processing speed by 28\nDesigned custom test automation frameworks using Java to enable faster feedback for developers\nCreated detailed test reports with actionable feedback reducing postrelease defects by 12",
              "keyResponsibilitiesArray": [
                  "Developed Python scripts to automate functional and regression testing increasing test coverage and reducing manual effort by 37",
                  "T ested C applications identifying bottlenecks and optimizing code to improve processing speed by 28",
                  "Designed custom test automation frameworks using Java to enable faster feedback for developers",
                  "Created detailed test reports with actionable feedback reducing postrelease defects by 12"
              ],
              "projectDetails": "NA",
              "toolsTechnologies": "NA"
          },
          {
              "startDate": "June 2014",
              "endDate": "July 2017",
              "company": "Aduro Biotech",
              "jobType": null,
              "role": "Junior Software Developer",
              "location": null,
              "isEdit": null,
              "isNewEntry": null,
              "index": "e6b29b00-b89d-49d7-845b-5a3f0ad38f20",
              "keyResponsibilities": "Used Eclipse IDE to write debug and maintain code improving productivity by 21 and code quality by 7\nDesigned unit tests for the development team for a 15 drop in production defects\nSupported the development of RESTful APIs contributing to a 29 increase in thirdparty integrations\nSupported database operations for both SQL and NoSQL systems improving query performance by 16",
              "keyResponsibilitiesArray": [
                  "Used Eclipse IDE to write debug and maintain code improving productivity by 21 and code quality by 7",
                  "Designed unit tests for the development team for a 15 drop in production defects",
                  "Supported the development of RESTful APIs contributing to a 29 increase in thirdparty integrations",
                  "Supported database operations for both SQL and NoSQL systems improving query performance by 16"
              ],
              "projectDetails": "NA",
              "toolsTechnologies": "NA"
          }
      ],
      "projects": [],
      "courses": null,
      "certifications": "NA",
      "toolsTechnicalSkills": [
          {label: "Java", value: "Java"},
          {label: "Python", value: "Python"},
          {label: "JavaScript", value: "JavaScript"},
          {label: "C", value: "C"},
          {label: "Oracle Cloud Infrastructure", value: "Oracle Cloud Infrastructure"},
          {label: "Oracle Database", value: "Oracle Database"},
          {label: "MongoDB", value: "MongoDB"},
          {label: "Red Hat OpenShift", value: "Red Hat OpenShift"}
      ],
      "programmingLanguages": [
          "Java",
          "Python",
          "JavaScript",
          "C"
      ],
      "interests": "Coding challenges and hackathons",
      "languages": [],
      "hobbies": [],
      "tailoredResumeObject": null
  } */
  
  const renderToolsTechnicalSkills = (skills: any[]) => {
    // Return null for falsy values or empty arrays
    if (!skills || skills.length === 0) {
        return null;
    }

    // Filter out null/undefined values
    const validSkills = skills.filter(item => item !== null && item !== undefined);

    // Return null if no valid skills remain
    if (validSkills.length === 0) {
        return null;
    }

    // Check the type of the first valid element
    const isObjectArray = typeof validSkills[0] === 'object';

    return validSkills.map((item: any, index: number) => {
        if (isObjectArray) {
            return (
                <Text key={index} style={styles.skills}>{item.label}</Text>
            );
        }
        return <Text key={index} style={styles.skills}>{item}</Text>;
    });
  };

  console.log(extractedData,"from PDF template_2");

  return (
  <Document>
    <Page size="A4" style={{...styles.page, width: pageWidth}}>
    <View style={{ height: "10%" }}>
      <Text style={styles.name}>
        {extractedData.fullName}
        {extractedData.firstName || extractedData.lastName ?
          <Image src={`${process.env.REACT_APP_API_URL}/img/Template_2/name-icon.png`} /> : ""
        }
      </Text>
    </View>
    <View style={{...styles.mainContainer, height: "88%"}}>
      <View style={styles.leftColumn}>
          {extractedData?.profilePicture && extractedData?.profilePicture !== "NA" && extractedData.profilePicture !== defaultProfilePicture && <View style={styles.flexCenter}>
            <Image src={extractedData.profilePicture} style={{width: 100, height: 100, borderRadius: 50}}/>
            </View>}
          {(isPhoneNumberExists(extractedData?.phone) || extractedData.email !== "" || extractedData.location !== "" || hasObjTruthyValue(extractedData?.socialLinks)) &&
            <View>
              <Text style={{...styles.sectionHeader,...styles.mt20}}>CONTACT</Text>
              {extractedData.phone && 
                <Text style={styles.contact}>
                  {Array.isArray(extractedData.phone) ? extractedData.phone.join(", ") : extractedData.phone}
                </Text>
              }
              <Text style={styles.contact}>{extractedData.email}</Text>
              <Text style={styles.contact}>{extractedData.location}</Text>
              {Object.keys(extractedData?.socialLinks)?.map((key) => {
              if(extractedData?.socialLinks?.[key]) {
                return <Text style={styles.contact}>{extractedData?.socialLinks?.[key]}</Text>
              }
            })}
            </View>
          }
          {(extractedData.toolsTechnicalSkills) &&
            <View style={isATSPreview ? {...styles.atsIssueMedium, marginTop: 20} : {marginTop: 20} }>
              <Text style={styles.sectionHeader}>SKILLS</Text>
              {isATSPreview &&
                <View style={styles.fixATSButton}>
                  <Image src={`${process.env.REACT_APP_API_URL}/img/ats-ai-fix.png`} style={styles.fixATSButtonImg}/>
                  <View style={{...styles.fixATSButtonImg, position: 'absolute', top: 0, left: 0}}>
                    <Text style={{fontSize: "0.1px", color: "transparent"}}>ATS_ISSUE-SKILLS</Text>
                  </View>
                </View>
              }
              {renderToolsTechnicalSkills(extractedData.toolsTechnicalSkills)}
            </View>
          }

        {(extractedData.nonTechnicalSkills && extractedData.nonTechnicalSkills.length > 0 ) &&
                <View style={isATSPreview ? {...styles.atsIssueMedium, marginTop: 20} : {marginTop: 20} }>
                  <Text style={styles.sectionHeader}>SOFT SKILLS</Text>
                  {isATSPreview &&
                    <View style={styles.fixATSButton}>
                      <Image src={`${process.env.REACT_APP_API_URL}/img/ats-ai-fix.png`} style={styles.fixATSButtonImg}/>
                      <View style={{...styles.fixATSButtonImg, position: 'absolute', top: 0, left: 0}}>
                        <Text style={{fontSize: "0.1px", color: "transparent"}}>ATS_ISSUE-NON_TECHNICAL_SKILLS</Text>
                      </View>
                    </View>
                  }
                  {renderToolsTechnicalSkills(extractedData.nonTechnicalSkills)}
                </View>
              }


          {(extractedData.programmingLanguages?.length > 0 ) &&
            <View style={isATSPreview ? {...styles.atsIssueLow, marginTop: 20} : {marginTop: 20} }>
              <Text style={styles.sectionHeader}>LANGUAGES</Text>
              {isATSPreview &&
                <View style={styles.fixATSButton}>
                  <Image src={`${process.env.REACT_APP_API_URL}/img/ats-ai-fix.png`} style={styles.fixATSButtonImg}/>
                  <View style={{...styles.fixATSButtonImg, position: 'absolute', top: 0, left: 0}}>
                    <Text style={{fontSize: "0.1px", color: "transparent"}}>ATS_ISSUE-LANGUAGES</Text>
                  </View>
                </View>
              }
              {extractedData.programmingLanguages && extractedData.programmingLanguages.map((language: string, index: number) => (
                <Text key={index} style={styles.languages}>• {language}</Text>
              ))}
            </View>
          }

{extractedData?.languages && extractedData?.languages?.length > 0 &&
          <View style={{ marginTop: 20 }}>
          <Text style={styles.sectionHeader}>LANGUAGES</Text>
          {extractedData?.languages?.map((language: string) => (
            <View>
              <Text style={styles.sectionContent}>{language}</Text>
            </View>
          ) )}
          </View>
        }

        {extractedData?.hobbies && extractedData?.hobbies?.length > 0 &&
          <View style={{ marginTop: 20 }}>
          <Text style={styles.sectionHeader}>HOBBIES</Text>
          {extractedData?.hobbies?.map((hob: string) => (
            <View>
              <Text style={styles.sectionContent}>{hob}</Text>
            </View>
          ) )}
          </View>
        }

        </View>
        <View style={styles.rightColumn}>
          <Text style={styles.jobTitle}>{extractedData.designation}</Text>

          {extractedData.careerObjective !== "NA" && extractedData.careerObjective &&
            <>
              <View style={isATSPreview ? {...styles.atsIssueFixed, marginTop: 10} : {}}>
                {/* <Image src={"https://tekno.aiats-fixed-icon.png"} style={{...styles.fixATSButton}}/> */}
                <Text style={styles.sectionHeader}>PROFESSIONAL SUMMARY</Text>
                <Text style={{...styles.sectionContent}}>
                {parse(extractedData.careerObjective
              ,{replace:(domNode)=> {
                if (domNode.type === 'tag') {
                  const childrenAsDomNodes = convertChildNodesToDOMNodes(domNode.children as unknown as ChildNode[]);
                  return <Text style={styles.sectionContent}>{domToReact(childrenAsDomNodes)}</Text>;
                }
                return undefined;
              }})}
                  </Text>
              </View>
            </>
          }

          <View style={{ marginTop: 20 }}>
            {extractedData.workExperiences && extractedData.workExperiences.length > 0 && <Text style={styles.sectionHeader}>WORK EXPERIENCE</Text>}
            {extractedData.workExperiences && extractedData.workExperiences.map((exp: WorkExperienceTypes, index: number) => (
              <View key={index} style={isATSPreview ? index === 0 ? {...styles.atsIssueHigh, marginBottom: 10} : index === 1 ? {...styles.atsIssueFixed, marginBottom: 10} : {} : {}}>
                {isATSPreview &&
                  <View style={styles.fixATSButton}>
                  {index === 0 &&
                    <>
                      <Image src={`${process.env.REACT_APP_API_URL}/img/ats-ai-fix.png`} style={styles.fixATSButtonImg}/>
                      <View style={{...styles.fixATSButtonImg, position: 'absolute', top: 0, left: 0}}>
                        <Text style={{fontSize: "0.1px", color: "transparent"}}>ATS_ISSUE-WORK_EXPERIENCE-{exp.index}</Text>
                      </View>
                    </>
                  }
                  {index === 1 && <Image src={`${process.env.REACT_APP_API_URL}/img/ats-fixed-icon.png`} style={styles.fixATSButtonImg}/>}
                </View>
                }
                <Text style={{...styles.sectionHeader, ...styles.sectionSubHeader}}>{exp.company}, {exp.role}</Text>
                <Text style={styles.sectionContent}>{exp.startDate as any} - {exp.endDate as any}</Text>
                <Text style={{...styles.sectionContent, ...styles.mb0}}>
                {parse(exp.keyResponsibilities
              ,{replace:(domNode)=> {
                // renderHTMLToPDFText(domNode)
                console.log(domNode,"from HTML")
                if (domNode.type === 'tag') {
                  const childrenAsDomNodes = convertChildNodesToDOMNodes(domNode.children as unknown as ChildNode[]);
                  return <Text style={styles.sectionContent}>{domToReact(childrenAsDomNodes)}</Text>;
                }
                return undefined;
          
              }})}
                </Text>
              </View>
            ))}
          </View>
          {extractedData.educationalDetails && extractedData.educationalDetails.length > 0 &&
            <View style={{ marginTop: 20 }}>
              <Text style={styles.sectionHeader}>EDUCATION</Text>
              {extractedData.educationalDetails.map((edu: EducationTypes, index: number) => (
                <View key={index}>
                  <Text style={{...styles.sectionHeader, ...styles.sectionSubHeader}}>{edu.universityCollege}</Text>
                  <Text style={styles.sectionContent}>{edu.degree} in {edu.specialization}</Text>
                  <Text style={{...styles.sectionContent, ...styles.mb0}}>{edu.startDate as any} - {edu.endDate as any}</Text>
                </View>
              ))}
            </View>
          }

{extractedData.projects && extractedData.projects?.length > 0 && 
          <View style={{ marginTop: 20 }}>
            <Text style={styles.sectionHeader}>PROJECTS</Text>
            {extractedData.projects.map((proj: CourseType, index: number) => (
              <View key={index}>
                <Text style={{...styles.sectionHeader, ...styles.sectionSubHeader}}>{proj.organization}</Text>
                <Text style={{...styles.sectionContent, ...styles.mb0}}>{proj.startDate as any} - {proj.endDate as any}</Text>
                <Text style={styles.sectionContent}>{proj?.courseDescription}</Text>
              </View>
            ))}
          </View>
        }

      {extractedData.courses && extractedData.courses?.length > 0 && 
          <View style={{ marginTop: 20 }}>
            <Text style={styles.sectionHeader}>COURSES</Text>
            {extractedData.courses.map((cour: CourseType, index: number) => (
              <View key={index}>
                <Text style={{...styles.sectionHeader, ...styles.sectionSubHeader}}>{cour.organization}</Text>
                <Text style={{...styles.sectionContent, ...styles.mb0}}>{cour.startDate as any} - {cour.endDate as any}</Text>
                <Text style={styles.sectionContent}>{cour?.courseDescription}</Text>
              </View>
            ))}
          </View>
        }

      {extractedData.awards && extractedData.awards?.length > 0 && 
          <View style={{ marginTop: 20 }}>
            <Text style={styles.sectionHeader}>AWARDS</Text>
            {extractedData.awards.map((awd: IReference, index: number) => (
              <View key={index}>
                <Text style={{...styles.sectionHeader, ...styles.sectionSubHeader}}>{awd.organization}</Text>
                <Text style={styles.sectionContent}>{awd?.courseDescription}</Text>
                {awd?.link && <Text style={styles.sectionContent}>{awd?.link}</Text>}
              </View>
            ))}
          </View>
        }

        {extractedData.references && extractedData.references?.length > 0 && 
          <View style={{ marginTop: 20 }}>
            <Text style={styles.sectionHeader}>REFERENCES</Text>
            {extractedData.references.map((ref: IReference, index: number) => (
              <View key={index}>
                <Text style={{...styles.sectionHeader, ...styles.sectionSubHeader}}>{ref.organization}</Text>
                <Text style={styles.sectionContent}>{ref?.courseDescription}</Text>
                {ref?.link && <Text style={styles.sectionContent}>{ref?.link}</Text>}
              </View>
            ))}
          </View>
        } 

        {extractedData.volunteerWorks && extractedData.volunteerWorks?.length > 0 && 
          <View style={{ marginTop: 20 }}>
            <Text style={styles.sectionHeader}>VOLUNTEER WORK</Text>
            {extractedData.volunteerWorks.map((vol: IReference, index: number) => (
              <View key={index}>
                <Text style={{...styles.sectionHeader, ...styles.sectionSubHeader}}>{vol.organization}</Text>
                <Text style={styles.sectionContent}>{vol?.courseDescription}</Text>
                {vol?.link && <Text style={styles.sectionContent}>{vol?.link}</Text>}
              </View>
            ))}
          </View>
        } 

        {extractedData.extraCA && extractedData.extraCA?.length > 0 && 
          <View style={{ marginTop: 20 }}>
            <Text style={styles.sectionHeader}>EXTRACURRICULAR ACTIVITIES</Text>
            {extractedData.extraCA.map((ca: IReference, index: number) => (
              <View key={index}>
                <Text style={{...styles.sectionHeader, ...styles.sectionSubHeader}}>{ca.organization}</Text>
                <Text style={styles.sectionContent}>{ca?.courseDescription}</Text>
                {ca?.link && <Text style={styles.sectionContent}>{ca?.link}</Text>}
              </View>
            ))}
          </View>
        }


        {Object.keys(extractedData.customSections) && Object.keys(extractedData.customSections)?.length > 0 &&
        Object.keys(extractedData.customSections)?.map((section:string) => (
          <View style={{ marginTop: 20 }}>
          <Text style={styles.sectionHeader}>{extractedData.customSections?.[section]?.label}</Text>
          {extractedData.customSections?.[section]?.value?.map((cs: IReference, index: number) => (
            <View key={index}>
              <Text style={{...styles.sectionHeader, ...styles.sectionSubHeader, ...styles.customSubSection}}>{cs.organization}</Text>
              <Text style={styles.sectionContent}>{cs?.courseDescription}</Text>
              {cs?.link && <Text style={styles.sectionContent}>{cs?.link}</Text>}
            </View>
          ))}
        </View>
        ))
        }          

        </View>
    </View>
    </Page>
  </Document>
  );
};

export default Template_2;