import { Box, ListItem, Switch, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StateProps } from "../../../redux/reducer";
import { EducationTypes, WorkExperienceTypes } from "../../../components/utils";
import { setExtractedData, setPriorToggleDetails } from "../../../redux/actions";
import { styled } from '@mui/material/styles';
import WorkExperienceEdit from "./WorkExperienceEdit";
import WorkExperienceCard from "./WorkExperienceCard";
import {ReactComponent as NoExperienceIcon} from "../../../assets/SVG/no-experience-big-icon.svg";

export const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 34, // Updated width
  height: 20, // Updated height
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 17, // Adjusted thumb width for active state
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(11px)', // Adjusted for the new size
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(14px)', // Adjusted for the new size
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#1890ff',
        ...theme.applyStyles('dark', {
          backgroundColor: '#177ddc',
        }),
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 16, // Updated thumb width
    height: 16, // Updated thumb height
    borderRadius: 8, // Adjusted for the new size
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 10, // Updated to match half of new height
    opacity: 1,
    backgroundColor: 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
    ...theme.applyStyles('dark', {
      backgroundColor: 'rgba(255,255,255,.35)',
    }),
  },
}));

interface CoursesListProps {
  handleSectionDelete:(section:string) =>void;
  sectionName:string;
}

const DEFAULT_FORM_DATA:WorkExperienceTypes = {
  jobType: "Full Time",
  company:"",
  startDate:"",
  endDate:"",
  role:"",
  location:"",
  keyResponsibilities:"",
  isEdit:true, 
  isNewEntry:true,  
};


const WorkExperienceList:React.FC = () => {
  const {extractedData,priorToggle} = useSelector((state:StateProps) => state);
  const dispatch = useDispatch();
  const [coursesList,setCoursesList] = useState<WorkExperienceTypes[]>(extractedData?.workExperiences ?? []);
  const [draggingItemIndex, setDraggingItemIndex] = useState<number|null>(null);


  //Adding key to extrated course elements
  useEffect(()=>{
    if(!extractedData?.workExperiences?.[0]) {
      setCoursesList([{...DEFAULT_FORM_DATA,index:0}])
    }
    else {
      const updatedList = extractedData?.workExperiences?.map((course,i) =>{
        return {...course,isEdit:false,index:i}
      });
      setCoursesList(updatedList ?? []);
    }
  },[extractedData?.workExperiences]);

  const handleAddCourse = () => {
    //Adding new course entry as form at the end
    const updateCourseList = coursesList?.map((course)=>({...course,isEdit:false}));
    setCoursesList([...updateCourseList,{...DEFAULT_FORM_DATA,index:coursesList?.length ?? 1}])
  }

  const handleDragStart = (index:number) => {
    setDraggingItemIndex(index);
  }

  const handleDragOver = (e:any,index:number) => {
    e.preventDefault();
  }

const handleDrop = (index:number) => {
  const draggedOverItem = extractedData?.workExperiences?.[index];

  // If the item is dragged over itself, don't change the list
  if (draggingItemIndex === index) {
    return;
  }

  if(draggedOverItem && (draggingItemIndex || draggingItemIndex===0) && extractedData?.workExperiences?.[draggingItemIndex]){
    const updatedItems = [...extractedData?.workExperiences];

    const temp = updatedItems[index];
    updatedItems[index] = updatedItems[draggingItemIndex];
    updatedItems[draggingItemIndex] = temp;

          dispatch(setExtractedData({
            ...extractedData,
            workExperiences:updatedItems,
          }));
      setDraggingItemIndex(index);
      }
    };


const handleDragEnd = () => {
  setDraggingItemIndex(null);
}

const handlePriorExpChange = (e:ChangeEvent<HTMLInputElement>) => {
  if(e.target.checked) {
    dispatch(setExtractedData({...extractedData,workExperiences:priorToggle?.workExpList}));
    dispatch(setPriorToggleDetails({...priorToggle,workExpFlag:true,workExpList:[]}))
  }
  else {
    dispatch(setPriorToggleDetails({...priorToggle,workExpFlag:false,workExpList:extractedData?.workExperiences}));
    dispatch(setExtractedData({...extractedData,workExperiences:[]}));
  }
}


   return (
    <Box className="summary-container" sx={{flex:1,display:"flex",flexDirection:"column"}}>
          <Box className="courses-section" sx={{flex:1,display:"flex",flexDirection:"column"}}>
            <Box className="heading-box">
            <Typography variant="h5" color="color.primary" className="heading">
              Work Experience
            </Typography>
           <Box sx={{display:"flex",justifyContent:"left",alignItems:"center",gap:"0.5rem"}}>
              <Typography sx={{fontSize:"1.125rem",color:"#344054",fontWeight:"500"}}>Have Prior Experience?</Typography>
              {/* <Switch defaultChecked /> */}
              <AntSwitch checked={priorToggle?.workExpFlag} onChange={handlePriorExpChange} inputProps={{ 'aria-label': 'ant design' }} />
           </Box>
    </Box>
     
    {!priorToggle?.workExpFlag ?  
       <Box sx={{width:"100%",flex:1,display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
        <Box>
        <NoExperienceIcon/>
        </Box>
        <Box sx={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
        <Typography sx={{fontSize:"1.5rem",fontWeight:"600",lineHeight:"2em",color:"#344054"}}>No Experience added</Typography>
        <Typography sx={{color:"#bcbcbc",fontSize:"1rem",fontWeight:"400",lineHeight:"19.12px"}}>You can click on next to continue</Typography>
        </Box>

        
       </Box> : coursesList?.map((course,index)=>(<ListItem className="card-wrapper" 
     draggable={course?.isEdit ? false :true}
     onDragStart={()=>handleDragStart(index)}
     onDragOver={(e)=>handleDragOver(e,index)}
     onDrop={()=>handleDrop(index)}
     onDragEnd={handleDragEnd}
     sx={draggingItemIndex === index ? {
      transition:"transform 0.3s ease",
      opacity: "0.7",
      transform: "scale(1.05)",
      cursor: course?.isEdit ? "none" : "grab",
     } : {}}
     >
      {course?.isEdit ? <WorkExperienceEdit courseData={course}
       coursesList={coursesList} 
       setCoursesList={setCoursesList}
       index={index}
       />
       : <WorkExperienceCard courseData={course}
        coursesList={coursesList}
         setCoursesList={setCoursesList}
         />}
      </ListItem>))}

    {!(coursesList?.[coursesList?.length - 1]?.isNewEntry) && <Box className="add-course-button-cont">
     <Typography className="summary-ui-brand" onClick={handleAddCourse}>+ Add More Experience</Typography>
    </Box>}
    
    </Box>
    </Box>
   )
}

export default WorkExperienceList;