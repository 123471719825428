import { Alert, Box, Button, Snackbar } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SnackBarState } from "../components/auth/Login";

import { useSelector, useDispatch } from "react-redux";
import { setPersonalDetailsValidation, setSaveInProgressResume } from "../redux/actions";
import { addNewResume, saveResumeData, withAuthHandling } from "../services/apiService";
import { StateProps } from "../redux/reducer";
import { setDraftState } from "../redux/actions";
import CustomizedAlert from "../components/CustomizedAlert";
import { base64ToFile } from "../components/utils";

interface ProcessResumeFooterProps {
  saveAsDraftModal: boolean;
  setSaveAsDraftModal: Dispatch<SetStateAction<boolean>>;
}

const ProcessResumeFooter : React.FC<ProcessResumeFooterProps> = ({ saveAsDraftModal, setSaveAsDraftModal }) => {
    const [snackBar, setSnackBar] = useState<SnackBarState>({open: false, message: "", severity: "success"});
    const personalDetailsValidation = useSelector((state:StateProps)=>state.PersonalDetailsValidation);
    const selectedTemplateId = useSelector((state: StateProps) => state?.selectedTemplateId);
    const navigate = useNavigate();
    const url = useParams();
    const params = url["*"];
    const dispatch = useDispatch();
    let footerObj:{[key:string]:{prev:string|null,next:string|null,isNextAllowed:boolean}} = {};

    const location = useLocation();
    const extractedData = useSelector((state: StateProps) => 
      state.extractedData
    );
    
    const priorToggle = useSelector((state: StateProps) => 
      state.priorToggle
    );
    
    const previewResumeScreenshot = useSelector((state: StateProps) => 
      state.previewResumeScreenshot
    );
  
    const fetchWithAuthHandling = withAuthHandling(navigate);

    useEffect(() => {
      if(!location.pathname.includes("personal-details")) {
        handleSave();
      }
    }, [location.pathname]);

    if (params) {
         footerObj = {
            "personal-details":{
                prev:null,
                next:"work-experience",
                isNextAllowed:true
            },
            "work-experience" :{
                prev:"personal-details",
                next:"education",
                isNextAllowed:(extractedData?.workExperiences?.[0] || priorToggle?.workExpFlag === false) ? true : false
            },
            "education":{
                prev:"work-experience",
                next:"skills",
                isNextAllowed:(extractedData?.educationalDetails?.[0] || priorToggle?.educationFlag === false) ? true : false
            },
            "skills":{
                prev:"education",
                next:"summary",
                isNextAllowed:true,
            },
            "summary":{
              prev:"skills",
              next:null,
              isNextAllowed:true,
          }

        }
    }

    const handleCloseSnackbar = () => {
      setSnackBar({
        ...snackBar,
        open: false
      });
    };

    const handleSave = async () => {
      let currentPath = location.pathname;
      dispatch(setSaveInProgressResume(true));

      if(!extractedData.id && currentPath.includes("new")) {
        const addedResume = await addNewResume({ ...extractedData, templateId: selectedTemplateId, screenshot: previewResumeScreenshot }, fetchWithAuthHandling);
        if(addedResume && addedResume.id) {
          // Get the current path and replace the placeholder ID with the new ID
          let newPath = currentPath.replace('new', addedResume.id);
          navigate(newPath);
        }
      }
      else {
        saveResumeData({...extractedData, templateId: selectedTemplateId, screenshot: previewResumeScreenshot}, fetchWithAuthHandling);
      }
      dispatch(setSaveInProgressResume(false));
      dispatch(setDraftState(false));
    }

    const runPersonalDetailsValidation = (path:string) => {
      if(personalDetailsValidation?.isNextAllowed) {
        return navigate(path);
      }
      else {
         dispatch(setPersonalDetailsValidation({...personalDetailsValidation,runValidation:true}));
      }

    }

    return (
        
          <Box className="footer">
            <Box className="footer-cancel">
            <Button variant="outlined" size="medium" 
             sx={{border:"none","&:hover":{border:"none"}}}
             onClick={()=> setSaveAsDraftModal(true)}
             >
                  Cancel
            </Button>
          </Box>
          {params && footerObj[params] && <Box className="footer-wrapper">
              { footerObj?.[params]?.prev !== null && <Button variant="outlined" size="medium" 
              onClick={()=> {
                if(footerObj[params]?.prev && typeof footerObj[params]?.prev === "string") {
                    //@ts-ignore
                  return  navigate(footerObj[params]?.prev)
                }
                }
              }
              >
                Previous
              </Button>}
                <Button variant="contained" size="medium" disabled={!footerObj?.[params]?.isNextAllowed}
                  onClick={()=> {
                      if(footerObj[params]?.next && typeof footerObj[params]?.next === "string") {
                         if(footerObj[params]?.next?.includes("work-experience")) {
                          runPersonalDetailsValidation(footerObj[params]?.next ?? "")
                         }
                         else {
                           //@ts-ignore
                           return  navigate(footerObj[params]?.next)
                         }
                      }
                      else {
                        handleSave()
                        if(footerObj[params]?.next === null && location.pathname.includes("summary")) {
                          const url = location.pathname.replace("summary", "personal-details")
                          navigate(url);
                        }
                      }
                      }}
                >
                 {footerObj?.[params]?.next === null ? "Done"  :"Next"}
                </Button>
            </Box>}
            
            <CustomizedAlert
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              alertState={snackBar.open}
              title={snackBar.severity === 'error' ? 'Error' : snackBar.severity==="success" ? "Success" : "Warning"}
              description={snackBar.message}
              severity={snackBar.severity}
              handleCloseSnackbar={handleCloseSnackbar}
   />
          </Box>
        
    )
}
export default ProcessResumeFooter;