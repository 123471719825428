import { Box, Button, CircularProgress, List, ListItem, Modal, Skeleton, Typography } from "@mui/material";
import { summarSectionModalstyle } from "../Summary";
import ReactQuill from "react-quill";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

import {ReactComponent as AiIcon} from "../../../assets/SVG/ai-old.svg";
import {ReactComponent as RegenerateIcon} from "../../../assets/SVG/referesh-icon.svg";
import {ReactComponent as AddAiIcon} from "../../../assets/SVG/add-ai-suggestion.svg";
import {ReactComponent as DoneAiIcon} from "../../../assets/SVG/done-ai-suggestion.svg";
import { useDispatch, useSelector } from "react-redux";
import { IResume } from "../../../components/utils";
import { cleanSummaryHTMLstring } from "../../../components/common/CommonFunctions";
import { quillModules } from "../../../App";
import { parse } from 'node-html-parser';
import { aiShimmerStyle } from "../WorkExperience/WorkExperienceModal";
import {ReactComponent as WhiteAiIcon} from "../../../assets/SVG/ai-description-small-icon.svg";
import {ReactComponent as StartAiIcon} from "../../../assets/SVG/ai-description-icon-new.svg";
import {ReactComponent as CancelModalIcon} from "../../../assets/SVG/cancel-modal.svg";
import { getHelpWithWriting } from "../../../services/apiService";


const Block = ReactQuill.Quill.import('blots/block');

class DraggableListItem extends Block {
  static create(value:any) {
    const node = super.create(value);
    node.setAttribute('draggable', false);

    const dragHandler = document.createElement('span');
    dragHandler.classList.add('drag-handler');
    dragHandler.innerHTML = '<svg width="12" height="12" viewBox="0 0 24 24"><circle cx="5" cy="5" r="2"/><circle cx="12" cy="5" r="2"/><circle cx="19" cy="5" r="2"/><circle cx="5" cy="12" r="2"/><circle cx="12" cy="12" r="2"/><circle cx="19" cy="12" r="2"/><circle cx="5" cy="19" r="2"/><circle cx="12" cy="19" r="2"/><circle cx="19" cy="19" r="2"/></svg>';
    dragHandler.setAttribute('draggable', "true");
    dragHandler.addEventListener('dragstart', DraggableListItem.dragStart);
    dragHandler.addEventListener('dragover', DraggableListItem.dragOver);
    dragHandler.addEventListener('drop', DraggableListItem.drop);

    node.insertBefore(dragHandler, node.firstChild);
    return node;
  }

  static dragStart(event:any) {
    event.dataTransfer.setData('text/plain', event.target.parentNode.dataset.index);
  }

  static dragOver(event:any) {
    event.preventDefault();
  }

  static drop(event:any) {
    event.preventDefault();
    const fromIndex = event.dataTransfer.getData('text/plain');
    const toIndex = event.target.parentNode.dataset.index;
    if (fromIndex !== toIndex) {
      const quill = ReactQuill.Quill.find(event.target);
      const fromBlot = quill.scroll.descendant(Block, fromIndex)[0];
      const toBlot = quill.scroll.descendant(Block, toIndex)[0];
      if (fromBlot && toBlot) {
        const fromNode = fromBlot.domNode;
        const toNode = toBlot.domNode;
        const parentNode = fromNode.parentNode;
        parentNode.insertBefore(fromNode, toNode.nextSibling);
        quill.update();
      }
    }
  }
}

DraggableListItem.blotName = 'draggable-list-item';
DraggableListItem.tagName = 'LI';

ReactQuill.Quill.register(DraggableListItem);

interface ProfessionalSummaryModalProps {
    professionalModal:boolean;
    setProfessionalModal:Dispatch<SetStateAction<boolean>>;
    summary:string;
    setSummary:Dispatch<SetStateAction<string>>;
}


const ProfessionalSummaryModal:React.FC<ProfessionalSummaryModalProps> = ({professionalModal,setProfessionalModal,summary,setSummary}) => {
    const dispatch = useDispatch();
    const extractedData = useSelector((state:IResume) => state.extractedData);
    const [modalSummary,setModalSummary] = useState("");
    const [isCursorActive, setIsCursorActive] = useState(false);
    const [loader,setLoader] = useState<boolean>(false);
    const [aiList,setAiList] = useState<{isAdded:boolean,text:string}[]>([])

      const getSummaryAiData = async () => {
        setAiList([]);
        setLoader(true);
        try {
          const data = await getHelpWithWriting("professionalSummary", extractedData, dispatch);
          const formattedAiList = data?.response.map((res: string) => {
            return { isAdded: false, text: res }
          });
          setAiList(formattedAiList);
        } catch (error) {
          console.error('Error generating content:', error);
        } finally {
          setLoader(false);
        }
      };

    useEffect(()=>{
        if(professionalModal) {
            setAiList([]);
        }
    },[professionalModal])

     /**
     * Below useEffect is to set the modalsummary value initially
    */
     useEffect(()=>{
        setIsCursorActive(false);
        if(summary && professionalModal) {
            const cleansummary = cleanSummaryHTMLstring(summary);
            setModalSummary(cleansummary);
        }
    },[professionalModal]);

    const handleConfirmAndContinue = () => {
        const cleansummary = cleanSummaryHTMLstring(modalSummary);  
        setSummary(cleansummary);
        setProfessionalModal(false);
    }

    const handleChange = (e:any) => {
        /**
         * Checking whether Ai Added modalSummary Changed or not
         * This handle chnage runs every time when there is a change in react quill state
        */
       if(isCursorActive){
        const updatedAiList = aiList.map((listItem)=>{
            if(listItem.isAdded === true && !e.includes(listItem.text)){
                return {...listItem, isAdded: false}
            }
            else if(listItem.isAdded === false && e.includes(listItem.text)){
                return {...listItem, isAdded: true}
            }
            else {
                return listItem;
            }
           })
           setAiList(updatedAiList);
           setModalSummary(e);
       }
    //    else {
    //     const cleansummary = cleanSummaryHTMLstring(e);
    //     setModalSummary(cleansummary);
    //    }
    }

    const handleTextClick = (text:string) => {
        if(!text) return;
        const upDatedList = aiList?.map((listItem)=> {
            if(listItem.text === text){
                return {...listItem, isAdded: true};
            }
            return listItem;
        })
        setAiList(upDatedList);
        const cleansummary = cleanSummaryHTMLstring(modalSummary)
        setModalSummary(cleansummary+`<ul><li>${text}</li></ul>`);
    }

      // Handle focus event
        const handleFocus = () => {
            setIsCursorActive(true);
        };

        // Handle blur event
        const handleBlur = () => {
            setIsCursorActive(false);
        };

        const assignDataIndex = (htmlString: string) => {
            const root = parse(htmlString);
            const ul = root.querySelector('ul');
            if (ul) {
              ul.querySelectorAll('li').forEach((li, index) => {
                li.setAttribute('data-index', index.toString());
              });
            }
            return root.toString();
          };
        
          useEffect(() => {
            setModalSummary(assignDataIndex(modalSummary));
          }, [modalSummary]);

    return (
        <Modal
        open={professionalModal}
        className="professional-summary-modal-container"
        onClose={()=>setProfessionalModal(false)}
        >
            <Box sx={summarSectionModalstyle} className="modal-wrapper">
                <Box className="modal-heading">
                    <Typography className="heading-text" variant="h5" component="h5">
                        Professional Summary
                    </Typography>
                    <Box onClick={()=>setProfessionalModal(false)} sx={{cursor:"pointer"}}>
                      <CancelModalIcon/>
                    </Box>
                </Box>

                <Box
                sx={{    
                    display:"flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    padding:2,
                    }}
                    className="modal-content-wrapper"
                >
                    <Box className="quill-container"> 
                    <Typography className="quill-heading">Summary</Typography>
                    <ReactQuill
                        // ref={careerObjectiveInputRef}
                        placeholder="Add the relevant description."
                        value={modalSummary}
                        onChange={handleChange}
                        theme="snow" 
                        modules={quillModules}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                    />
                    </Box>

                    <Box className="ai-suggestion-container">
                        <Box className="ai-title">
                            <Box className="ai-box">
                            <AiIcon/>
                            <Typography className="ai-description">AI Generated Summary</Typography>
                            </Box>
                            {aiList?.[0] && <Box
                            sx={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginLeft: 2,
                            border: '1px solid #5594ff',
                            borderRadius:"100px",
                            cursor: 'pointer',
                            color: "#5594ff",
                            px: 1,
                            }}
                        onClick={()=> getSummaryAiData()}
                        className="regenrate-wrapper"
                    >
                        <RegenerateIcon/> <Typography className="button" variant="caption">Regenrate</Typography>
                            </Box>}
                        </Box>

                        <Box className="ai-content-container">
                            {
                            loader ? 
                            <Box sx={{flex:1,display:"flex",flexDirection:"column",gap:"1rem",justifyContent:"center",alignItems:"center"}}>
                                   {Array(7).fill(0)?.map((_,index)=>
                                             <Box sx={{display:"flex",flexDirection:"column",width:"90%",height:"48px",gap:"0.5rem"}}>
                                             <Box sx={{display:"flex",gap:"0.25rem",alignItems:"center"}}>
                                              <Skeleton sx={aiShimmerStyle} key={index} variant="circular" width={"10px"} animation="wave" height={"10px"}/>
                                             <Skeleton sx={aiShimmerStyle} key={index} variant="rounded" width={"90%"} animation="wave" height={"16px"}/>
                                             </Box>
                                             <Skeleton
                                           sx={{...aiShimmerStyle,marginLeft:"0.75rem"}}
                                          key={index} variant="rounded" width={"50%"} height={"16px"}
                                          />
                                        </Box>
                                   )}
                            </Box>
                            :
                             aiList?.[0] ? <List className="list">
                            {aiList?.map((sug)=>(
                                <ListItem className="list-item">
                                    {sug.isAdded ? 
                                     <Box className="item-wrapper active-item-wrapper">
                                    <Box className="icon-wrapper">
                                     <DoneAiIcon/>
                                    </Box>
                                    <Typography className="active-text">{sug.text}</Typography>
                                        </Box>
                                        :
                                        <Box className="item-wrapper" onClick={()=>handleTextClick(sug.text)}>
                                        <Box className="icon-wrapper">
                                         <AddAiIcon/>
                                        </Box>
                                        <Typography className={"inactive-text"}>{sug.text}</Typography>
                                    </Box>
                                    }

                                </ListItem>
                            ))}
                            </List>
                            :
                            
                            <Box sx={{width:"100%",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",gap:"0.5rem"}}>
                            <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",marginBottom:"1rem",marginLeft:"1rem"}}>
                            <StartAiIcon/>
                            </Box>
                        <Box
                            sx={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginLeft: 2,
                            // backgroundColor: '#0088ff',
                            background:"linear-gradient(to left,#5594FF,#687AF3,#D080ff)",
                            color:"#fff",
                            borderRadius: "5.71px",
                            cursor: 'pointer',
                            px: 1,
                            py: 0.5,
                            }}
                            >
                            <WhiteAiIcon /> <Typography variant="caption" sx={{marginLeft:"0.3rem",fontSize:"1rem",fontWeight:"500"}} onClick={()=>getSummaryAiData()}>Generate with AI</Typography>
                        </Box>
                        </Box>
                            }
                           
                        </Box>
                    </Box>
                </Box>

                <Box className="modal-footer">
                        <Button variant="outlined" sx={{border:"none","&:hover":{border:"none"}}} onClick={()=>setProfessionalModal(false)}>Cancel</Button>
                        <Button variant="contained" onClick={handleConfirmAndContinue}>Confirm and Continue</Button>
                </Box>
            </Box>  
        </Modal>
    )
}
export default ProfessionalSummaryModal;

// { isAdded: false, text: "Highly motivated professional with extensive experience in delivering successful projects on time and within budget." },
// { isAdded: false, text: "Dedicated team player with proven leadership skills and a track record of driving business growth." },
// { isAdded: false, text: "Detail-oriented individual with a strong background in data analysis and problem-solving." },
// { isAdded: false, text: "Seeking a challenging position in a dynamic organization where I can apply my skills and grow professionally." },
// { isAdded: false, text: "Aiming to leverage my expertise in software development to contribute to innovative projects." },
// { isAdded: false, text: "Aspiring to secure a role in project management where I can utilize my organizational and leadership skills." },
// { isAdded: false, text: "Experienced in full-stack web development, specializing in React and Node.js." },
// { isAdded: false, text: "Proficient in cloud computing with a focus on AWS and DevOps best practices." },
// { isAdded: false, text: "Skilled in data science and machine learning, with hands-on experience in Python and R." },
// { isAdded: false, text: "Collaborated effectively with cross-functional teams to achieve project goals and enhance team productivity." },
// { isAdded: false, text: "Led a team of developers to deliver high-quality software solutions within tight deadlines." },
// { isAdded: false, text: "Proven ability to manage diverse teams and foster a collaborative working environment." },
// { isAdded: false, text: "Successfully reduced operational costs by 15% through process improvements and automation." },
// { isAdded: false, text: "Increased customer satisfaction scores by implementing user-centric design practices." },
// { isAdded: false, text: "Developed and deployed a scalable application that handled over 1 million users." }