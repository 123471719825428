import { EducationTypes, ILoggedinUserData, IResume, WorkExperienceTypes } from '../components/utils';
import { SET_EXTRACTED_DATA, SET_ACCESS_TOKEN, SET_SELECTED_TEMPLATE_ID, SET_LOGGED_IN_USER_DATA, LOGOUT_USER, SET_COLOR_DATA, SET_SIDEDRAWER_STATE, OPEN_POPOVER, CLOSE_POPOVER, SET_FIXED_SECTIONS, UPDATE_POPOVER_VALUE, SET_RESUME_HEIGHT, SET_ACTIVE_ATS_CHECK_RESULT_ID, SET_PERSONALDETAILS_VALIDATION, SET_PRIOR_TOGGLE_DETAILS, SET_PREVIEW_RESUME_SCREENSHOT, SET_DRAFT_STATE, SHOW_SNACKBAR, HIDE_SNACKBAR, SET_SAVE_IN_PROGRESS_RESUME, SET_ISPERSONALDETAILS_LOADING } from './actionTypes';

export const setSideDrawerState = (data: boolean) => ({
  type: SET_SIDEDRAWER_STATE,
  payload: data,
});

export const setExtractedData = (data: IResume) => ({
  type: SET_EXTRACTED_DATA,
  payload: data,
});

export const setColorData = (data: string) => ({
  type: SET_COLOR_DATA,
  payload: data,
});

export const setLoggedInUserData = (data: ILoggedinUserData) => ({
  type: SET_LOGGED_IN_USER_DATA,
  payload: data,
});

export const setAccessToken = (token: string) => ({
  type: SET_ACCESS_TOKEN,
  payload: token,
});

export const setSelectedTemplateId = (templateId: string) => ({
  type: SET_SELECTED_TEMPLATE_ID,
  payload: templateId,
});

export const logoutUser = () => ({
  type: LOGOUT_USER
});

export const openPopover = (anchorEl: any, section: string, sectionValue: any, sectionIndex = -1) => ({
  type: OPEN_POPOVER,
  payload: { anchorEl, section, sectionValue, sectionIndex }
});

export const updatePopoverValue = (sectionValue: any) => ({
  type: UPDATE_POPOVER_VALUE,
  payload: sectionValue,
});

export const closePopover = () => ({
  type: CLOSE_POPOVER
});

export const setFixedSections = (data: any) => ({
  type: SET_FIXED_SECTIONS,
  payload: data,
});

export const setPreviewResumeData = (data:object) => ({
  type:SET_RESUME_HEIGHT,
  payload:data,
});

export const setActiveATSCheckResultID = (id: string) => ({
  type: SET_ACTIVE_ATS_CHECK_RESULT_ID,
  payload: id,
});

export interface IPersonalDetailsValidation {
  isNextAllowed: boolean,
  runValidation: boolean,
}

export const setPersonalDetailsValidation = (PersonalDetailsValidationData:IPersonalDetailsValidation) => ({
  type: SET_PERSONALDETAILS_VALIDATION,
  payload:PersonalDetailsValidationData
});

export interface IPriorToggle {
  workExpFlag:boolean;
  workExpList:WorkExperienceTypes[];
  educationFlag:boolean;
  educationList:EducationTypes[];
}

export const setPriorToggleDetails = (prorToggle:IPriorToggle) => ({
  type: SET_PRIOR_TOGGLE_DETAILS,
  payload:prorToggle,
});

export const setPreviewResumeScreenshot = (screenshot: string) => ({
  type: SET_PREVIEW_RESUME_SCREENSHOT,
  payload: screenshot,
});

export const setDraftState = (isDraft: boolean) => ({
  type: SET_DRAFT_STATE,
  payload: isDraft,
});

export const setSaveInProgressResume = (isSaveInProgressResume: boolean) => ({
  type: SET_SAVE_IN_PROGRESS_RESUME,
  payload: isSaveInProgressResume,
});

export const showSnackbar = (message: string, severity: 'success' | 'info' | 'warning' | 'error') => ({
  type: SHOW_SNACKBAR,
  payload: { message, severity },
});

export const hideSnackbar = () => ({
  type: HIDE_SNACKBAR,
});

export const setIsPersonalDetailsLoading = (isPersonalDetailsLoading:boolean) => ({
  type:SET_ISPERSONALDETAILS_LOADING,
  payload: isPersonalDetailsLoading
})