import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Layout from "../Layout";
import SettingsSidebar from "./Sidebar";
import MyProfile from "./MyProfile";
import Notifications from "./Notifications";
import JobPreferences from "./JobPreferences";
import PasswordAndSecurity from "./PasswordAndSecurity";
import HelpAndSupport from "./HelpAndSupport";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getToken } from "../auth/storeToken";
import { getUserProfile, withAuthHandling } from "../../services/apiService";
import { setLoggedInUserData } from "../../redux/actions";

const Settings = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchWithAuthHandling = withAuthHandling(navigate);

  useEffect(() => {
    location.pathname === "/settings" && navigate("/settings/profile");
  }, []);

  const fetchUserDetails = async () => {
    if (getToken()) {
      const userDetails = await getUserProfile(fetchWithAuthHandling);
      dispatch(setLoggedInUserData(userDetails));
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  return (
    <Layout
      sx={{ p: 2, backgroundColor: "#F8F9FD", flexGrow: 1 }}
      title="Settings"
    >
      <div
        className="settings-page"
        style={{
          display: "flex",
          height: "100%",
          backgroundColor: "#fff",
          flexGrow: 1,
          borderRadius: "8px",
        }}
      >
        <SettingsSidebar />
        <Routes>
          <Route path="/profile" element={<MyProfile />} />
          <Route path="/job-preferences" element={<JobPreferences />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/password" element={<PasswordAndSecurity />} />
          <Route path="/support" element={<HelpAndSupport />} />
        </Routes>
      </div>
    </Layout>
  );
};

export default Settings;
