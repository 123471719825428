import { Box, Button, InputLabel, TextField, Typography } from "@mui/material";
import { SetStateAction, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setExtractedData } from "../../../../redux/actions";
import { IReference } from "../../../../components/utils";
import { Dispatch } from "react";
import { StateProps } from "../../../../redux/reducer";
import { isValidUrl } from "../../../../components/common/CommonFunctions";
import CardEditHeader from "../../../../components/common/CardEditHeader";

  interface CourseProps {
    courseData:IReference;
    coursesList:IReference[];
    setCoursesList:Dispatch<SetStateAction<IReference[]>>;
    index:number;
  }

const ReferenceEdit:React.FC<CourseProps> = ({courseData,coursesList,setCoursesList,index}) => {

    const dispatch = useDispatch();
    const extractedData = useSelector((state:StateProps) => state.extractedData);
    const [addLink,setAddLink] = useState<boolean>(false);
    //formData handles local form state
    const [formData, setFormData] = useState<IReference>(courseData);
    const [errors,setErrors] = useState({
        organization:"",
        url:""  
      });
    const handleChange = (newValue: any, key: string) => {
        if(key === "courseDescription") {
            const wordLimit = 50
            const wordCount = newValue.trim().split(/\s+/).filter(Boolean).length;
            if(wordCount<=wordLimit){
                setFormData({
                    ...formData,
                    [key]: newValue,
                  });
            }
        }
        else {
            setFormData({
                ...formData,
                [key]: newValue,
              });
            //If already showing error for the field to clear that
            setErrors((prev)=> ({
              ...prev,
              [key]:"",
            }))
        }
      };

      const handleCancel = () => {
        if(!!formData?.isNewEntry) {
            setCoursesList((prev) => prev ? prev.slice(0, -1) : prev)
        }
        else {
            setCoursesList((prev)=> prev.map((courEle) => {
                if(courEle?.organization === courseData.organization) {
                    return {...courEle, isEdit: false};
                }
                return courEle;
            }));
        }
      }
  
      const handleSave = () => {
        if(!formData?.organization) {
          setErrors((prev)=>({
            ...prev,
            organization:"Organization is required"
          }))
        }
        if(!isValidUrl(addLink,formData?.link ?? "")) {
            setErrors((prev)=>({
                ...prev,
                url:"Invalid URL"
              }))
          }
        if(formData?.organization) {
            //checking if link is present then It must contain the valid url
          if(!isValidUrl(addLink,formData?.link ?? "")) {
            setErrors((prev)=>({
                ...prev,
                url:"Invalid URL"
              }))
          }
          else {
            //perform new course entry
            if(formData?.isNewEntry) {
                /**
                 * New Entry
                */
               delete formData?.isNewEntry;
               delete formData?.index;
                dispatch(setExtractedData({
                  ...extractedData,
                  references: [
                    ...extractedData.references ?? [],
                     {...formData,isEdit:false}
                  ],
                }));
              }
              else {
                const updatedCourses = coursesList?.map((course)=> {
                    if(course?.index === formData?.index) {
                      delete formData?.index;
                        return {...formData,isEdit:false};
                    }
                    delete course?.index;
                    return course;
                })
                dispatch(setExtractedData({
                  ...extractedData,
                  references:updatedCourses,
                }));
              }
              //both cases remove error
              setErrors({
                organization:"",
                url:"",
              })
          }
        }
      };
           
   return (
    <Box className="section-item-container">
      <Box className="courses-edit-section common-edit-section" sx={{border : (index===0 && courseData?.isNewEntry) ? "none !important" : "inherit" }}>

      {!courseData?.isNewEntry && <CardEditHeader 
        details={{
            heading:courseData?.organization,
        }}
        handleSave={handleSave}
        handleCancel={handleCancel}
        />}

    <Box className="input-box-container">
    <InputLabel htmlFor="company">Title*</InputLabel>
    <TextField
        fullWidth
        id="company"
        name="company"
        placeholder="Ex: Microsoft Corporation"
      value={formData?.organization}
      onChange={(e) => handleChange(e.target.value, 'organization')}
      error={!!errors.organization}
      helperText={errors.organization ? errors.organization : ''}
    />
    </Box>

    <Box className="input-box-container">
    <InputLabel htmlFor="address">
    Description
    </InputLabel>
    <TextField
    multiline
    rows={2}
    fullWidth
    value={formData?.courseDescription}
    id="address"
    placeholder="Add the relevant description."
    onChange={(e) => handleChange(e.target.value,"courseDescription")}
    />
     <Typography
        variant="body2"
        className="word-count-text"
      >
        {`${formData?.courseDescription?.trim().split(/\s+/).filter(Boolean).length}/50 words`}
      </Typography>
   </Box>

 {addLink &&   <Box className="input-box-container">
    <Box sx={{display:"flex",justifyContent:"space-between"}}>
    <InputLabel htmlFor="link">Link*</InputLabel>
    <Typography className="delete-link-section" onClick={()=>setAddLink(false)}>Delete</Typography>
    </Box>
    <TextField
        fullWidth
        id="link"
        name="link"
        placeholder="Ex. www.google.com"
      value={formData?.link}
      onChange={(e) => handleChange(e.target.value, 'link')}
      error={!!errors.url}
      helperText={errors.url ? errors.url : ''}
    />
    </Box>}

    <Box className="section-action">
        <Box className="link-container">
        {!addLink && <Typography className="add-link-text" onClick={()=>setAddLink(true)}>+ Add Link</Typography>}
        </Box>
         {<Box className="button-container">
         {/*  courseData?.isNewEntry && */}
        <Button className="cancel-button" variant="outlined" size="medium" onClick={handleCancel}>Cancel</Button>
        <Button className="add-button" variant="contained" size="medium" onClick={handleSave}>Add</Button>
        </Box>}
    </Box>
    </Box>
    </Box>
   )
}

export default ReferenceEdit;