import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { pdf } from '@react-pdf/renderer';
import { Document, Page, pdfjs } from "react-pdf";
import { useDispatch, useSelector } from 'react-redux';
import { StateProps } from '../redux/reducer';
import { getDocument } from 'pdfjs-dist';
import { setPreviewResumeScreenshot } from '../redux/actions';
import { TemplatesById } from '../components/utils';
import debounce from 'lodash.debounce';
import { cloneElementWithCanvas } from '../utils/commonFunctions';

// Configure pdfjs worker
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export const generatePdfContent = async (extractedData: any, TemplateComponent: any) => {
  if (!extractedData) return null;

  try {
    const blob = await pdf(<TemplateComponent extractedData={extractedData} />).toBlob();
    return blob;
  } catch (error) {
    console.error('Error generating PDF content', error);
    return null;
  }
};

interface TemplatePreviewProps {
  pageWidth: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  setNumPages: (numPages: number) => void;
  isATSPreview: boolean;
}

const TemplatePreview: React.FC<TemplatePreviewProps> = ({ 
  pageWidth, 
  currentPage, 
  setCurrentPage, 
  setNumPages, 
  isATSPreview 
}) => {
  const extractedData = useSelector((state: StateProps) => state?.extractedData);
  const selectedTemplateId = useSelector((state: StateProps) => state?.selectedTemplateId);
  const dispatch = useDispatch();

  // Memoize template component
  const TemplateComponent = useMemo(() => 
    TemplatesById[selectedTemplateId]?.jsx || 
    TemplatesById['18903ed9-0338-438a-903a-ada93fde4a3c'].jsx,
    [selectedTemplateId]
  );

  // States
  const [pdfContent, setPdfContent] = useState<string | null>(null);
  const [arrayBuffer, setArrayBuffer] = useState<ArrayBuffer | null>(null);
  const [overlayElements, setOverlayElements] = useState<any[]>([]);
  const [savingStatus, setSavingStatus] = useState<string>("");

  // Refs
  const pageRef = useRef<HTMLDivElement>(null);
  const previousPageRef = useRef<number>(currentPage);

  // Memoized handlers
  const handleRender = useCallback((layoutData: any) => {
    console.log('Layout data:', layoutData);
  }, []);

  const base64ToArrayBuffer = useCallback((base64: string): ArrayBuffer => {
    const binaryString = window.atob(base64.split(',')[1]);
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }, []);

  // Memoize PDF generation
  const generatePdfContent = useCallback(async () => {
    if (!extractedData || !TemplateComponent) return;

    setSavingStatus("Saving...");
    previousPageRef.current = currentPage;

    try {
      const blob = await pdf(
        <TemplateComponent 
          extractedData={extractedData} 
          pageWidth={pageWidth}
          isATSPreview={isATSPreview}
          onRender={handleRender}
        />
      ).toBlob();

      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error("Failed to generate PDF", error);
      setSavingStatus("Failed");
      return null;
    }
  }, [extractedData, TemplateComponent, pageWidth, isATSPreview, currentPage, handleRender]);

  // Debounced PDF generation
  const debouncedGeneratePdf = useMemo(
    () => debounce(async () => {
      const base64data = await generatePdfContent();
      if (!base64data) return;

      setPdfContent(base64data as string);
      
      if (isATSPreview) {
        setArrayBuffer(base64ToArrayBuffer(base64data as string));
      } else {
        setArrayBuffer(null);
        setOverlayElements([]);
      }

      // Generate screenshot
      const pdfData = base64ToArrayBuffer(base64data as string);
      const pdf = await getDocument({ data: pdfData }).promise;
      const page = await pdf.getPage(1);
      const viewport = page.getViewport({ scale: 1 });
      
      const canvas = document.createElement('canvas');
      canvas.width = viewport.width;
      canvas.height = viewport.height;
      
      await page.render({
        canvasContext: canvas.getContext('2d') as any,
        viewport
      }).promise;

      dispatch(setPreviewResumeScreenshot(canvas.toDataURL()));
      setSavingStatus("Saved");

    }, 500),
    [generatePdfContent, isATSPreview, base64ToArrayBuffer, dispatch]
  );

  // Calculate overlays for ATS preview
  const calculateOverlays = useCallback(async () => {
    if (!isATSPreview || !arrayBuffer || !pageRef.current) return;

    try {
      const pdf = await getDocument({ data: arrayBuffer }).promise;
      const page = await pdf.getPage(currentPage);
      const viewport = page.getViewport({ scale: 1 });

      const { clientWidth: domWidth, clientHeight: domHeight } = pageRef.current;
      const scaleX = domWidth / viewport.width;
      const scaleY = domHeight / viewport.height;

      const { items } = await page.getTextContent();
      const atsIssueElements = items.filter((item: any) => 
        item.str.includes('ATS_ISSUE-')
      );

      const overlays = atsIssueElements.map(({ str, transform, width, height }:any) => ({
        text: str,
        x: transform[4] * scaleX,
        y: domHeight - transform[5] * scaleY - (height * scaleY),
        width: 22,
        height: 22
      }));

      setOverlayElements(overlays);
    } catch (error) {
      console.error('Failed to calculate overlays:', error);
    }
  }, [arrayBuffer, currentPage, isATSPreview]);

  // Effects
  useEffect(() => {
    debouncedGeneratePdf();
    return () => debouncedGeneratePdf.cancel();
  }, [debouncedGeneratePdf]);

  const handlePageRenderSuccess = useCallback(async () => {
    const originalElement = document.querySelector('.pdf-hidden-renderer .react-pdf__Document');
    if (originalElement) {
        const clone = cloneElementWithCanvas(originalElement);
        const pdfVirtualRenderer: any = document.querySelector('#pdf-virtual-renderer');
        while(pdfVirtualRenderer.firstChild){
            pdfVirtualRenderer.removeChild(pdfVirtualRenderer.firstChild);
        }
        pdfVirtualRenderer.appendChild(clone);     
    }
    await calculateOverlays();
  }, [calculateOverlays]);

  const handleOverlayClick = useCallback((item: any) => {
    console.log('Clicked overlay:', item);
  }, []);

  return (
    <div style={{ position: 'relative', width: pageWidth, display: 'flex', flexDirection: 'column' }}>
      <div style={{ flex: 1, overflow: 'hidden', position: 'relative', height: "100%", background: "#FFF" }}>

        <div id="pdf-virtual-renderer"></div>
          <div className='pdf-hidden-renderer' style={{position: "absolute", top: -9999, left: -9999}}>
          <Document
            file={pdfContent}
            onLoadSuccess={({ numPages }) => setNumPages(numPages)}
            onLoadError={(error) => console.error('Error loading PDF:', error)}
            loading={null}
          >
            <div ref={pageRef}>
              {currentPage > 0 && pdfContent &&
                <Page
                  key={`page_${currentPage}`}
                  pageNumber={currentPage}
                  width={pageWidth}
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                  onRenderSuccess={handlePageRenderSuccess}
                  loading={null}
                />
              }
            </div>
          </Document>
        </div>

        {isATSPreview && overlayElements.map((item, index) => (
          <div
            key={index}
            onClick={() => handleOverlayClick(item)}
            style={{
              position: 'absolute',
              left: `${item.x}px`,
              top: `${item.y}px`,
              width: `${item.width}px`, 
              height: `${item.height}px`,
              cursor: 'pointer',
              pointerEvents: 'auto',
              boxSizing: 'border-box'
            }}
            title={item.text}
          />
        ))}
      </div>
    </div>
  );
};

export default React.memo(TemplatePreview);