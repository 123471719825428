import  Box  from '@mui/material/Box';
import  Paper  from '@mui/material/Paper';
import ProcessResumePreviewHeader from './ProcessResumePreviewHeader';
import ProcessResumePreview from './ProcessResumePreview';
import ResumeScoreProgressBar from './ResumeScoreProgressBar';
import { Routes, Route, useNavigate, useLocation, useParams } from 'react-router-dom';
import UploadProfilePhoto from './EditForms/UploadProfilePhoto';
import PersonalDetails from './EditForms/PersonalDetails';
import Skills from './EditForms/Skills';
import { Button, createTheme, useMediaQuery, useTheme } from '@mui/material';
import ProcessResumeFooter from './ProcessResumeFooter';
import ResumeProgressStepper from './ResumeProgressStepper';
import AppBarHeader from './AppBarHeader';
import SideDrawer from './SideDrawer';
import NameScoreWrapper from './NameScoreWrapper';
import Summary from './EditForms/Summary';
import Header from '../components/common/Header';
import EducationList from './EditForms/Education/EducationList';
import WorkExperienceList from './EditForms/WorkExperience/WorkExperienceList';
import TailoredResumeSuggestions from './TailoredResumeSuggestions';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StateProps } from '../redux/reducer';
import { setExtractedData, setIsPersonalDetailsLoading } from '../redux/actions';
import { getToken } from '../components/auth/storeToken';
import Layout from '../components/Layout';
import TemplatesList from '../components/templates/TemplatesList';
import { fetchResumeById, withAuthHandling } from '../services/apiService';

const ProcessResumeFeature:React.FC = () => {
const dispatch = useDispatch();
const { resumeId } = useParams<{ resumeId: string }>();

const location = useLocation();
const navigate = useNavigate();
const isSelectTemplatePage = location.pathname.includes("select-template");

const [numPages, setNumPages] = useState<number>(0);
const [currentPage, setCurrentPage] = useState<number>(1);

const fetchWithAuthHandling = withAuthHandling(navigate);

const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, numPages));
};

const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
};

  useEffect(() => {
    const fetchResumeData = async () => {
      if (resumeId && resumeId !== "new") {
        try {
          const resume = await fetchResumeById(resumeId, fetchWithAuthHandling, dispatch);
          dispatch(setExtractedData(resume));
        } catch (error) {
          console.error('Error fetching resume data', error);
        }
      }
    };

    fetchResumeData();
  }, [resumeId]);

  const [saveAsDraftModal,setSaveAsDraftModal] = useState<boolean>(false);

    return (
      <Layout>
        <Box className="process-resume-page-container">
          <Box className="side-drawer-container">
            <SideDrawer saveAsDraftModal={saveAsDraftModal} setSaveAsDraftModal={setSaveAsDraftModal}/>
          </Box>

          <Box>
            <Box className="main-content">
              <Paper className="left-edit-container" sx={{ flex: 1.6 }}>
                {!isSelectTemplatePage && 
                  <>
                    <Box className="left-progress-bar">
                      <NameScoreWrapper/>
                      <ResumeProgressStepper/>
                    </Box>
                    <TailoredResumeSuggestions/>
                  </>
                }
                <Box className="left-edit-fields-container">
                  <Routes>
                      <Route path="profile-photo" element={<UploadProfilePhoto/>} />
                      <Route path="personal-details" element={<PersonalDetails/>} />
                      <Route path="work-experience" element={<WorkExperienceList/>} />
                      <Route path="education" element={<EducationList/>} />
                      <Route path="skills" element={<Skills/>} />
                      <Route path="summary" element={<Summary/>} />
                      <Route path="select-template" element={<TemplatesList/>} />
                  </Routes>
                </Box>
              
                {!isSelectTemplatePage && <ProcessResumeFooter saveAsDraftModal={saveAsDraftModal} setSaveAsDraftModal={setSaveAsDraftModal}/>}
              </Paper>
              <Box className="right-preview-container"
                sx={{
                  display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex' },
                }}
              >
                  <Box className="right-preview-header-container">
                      <ProcessResumePreviewHeader currentPage={currentPage} numPages={numPages} handleNextPage={handleNextPage} handlePrevPage={handlePrevPage} />
                  </Box>
                  <Box className="right-preview-content-container">
                      <ProcessResumePreview currentPage={currentPage} setCurrentPage={setCurrentPage} setNumPages={setNumPages}/>
                  </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Layout>
    )
}

export default ProcessResumeFeature;