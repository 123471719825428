import {
    AWSLight,
    AbletonLight,
    ActivityPubLight,
    ActixLight,
    Adonis,
    AfterEffects,
    AiScriptLight,
    AlpineJSLight,
    AnacondaLight,
    AndroidStudioLight,
    AngularLight,
    Ansible,
    Apollo,
    AppleLight,
    Appwrite,
    ArchLight,
    Arduino,
    Astro,
    Atom,
    Audition,
    AutoCADLight,
    Azul,
    AzureLight,
    BSDLight,
    Babel,
    BashLight,
    BevyLight,
    BitBucketLight,
    BlenderLight,
    Bootstrap,
    BunLight,
    C,
    CLionLight,
    CMakeLight,
    CPP,
    CS,
    CSS,
    CassandraLight,
    ClojureLight,
    CloudflareLight,
    CodePenLight,
    CoffeeScriptLight,
    CrystalLight,
    CypressLight,
    D3Light,
    DENOLight,
    DartLight,
    DebianLight,
    DevToLight,
    Discord,
    DiscordBots,
    DiscordJSLight,
    Django,
    Docker,
    DotNet,
    DynamoDBLight,
    EclipseLight,
    ElasticsearchLight,
    Electron,
    ElixirLight,
    ElysiaLight,
    Emacs,
    Ember,
    EmotionLight,
    ExpressJSLight,
    FastAPI,
    FediverseLight,
    FigmaLight,
    FirebaseLight,
    FlaskLight,
    FlutterLight,
    Forth,
    Fortran,
    GCPLight,
    GTKLight,
    GameMakerStudio,
    Gatsby,
    GherkinLight,
    Git,
    GitLabLight,
    GithubActionsLight,
    GithubLight,
    GmailLight,
    GoLang,
    GodotLight,
    GradleLight,
    GrafanaLight,
    GraphQLLight,
    Gulp,
    HTML,
    HaskellLight,
    HaxeLight,
    HaxeFlixelLight,
    Heroku,
    HibernateLight,
    HtmxLight,
    IPFSLight,
    IdeaLight,
    Illustrator,
    Instagram,
    JQuery,
    JavaLight,
    JavaScript,
    JenkinsLight,
    Jest,
    JuliaLight,
    Kafka,
    KaliLight,
    KotlinLight,
    KtorLight,
    Kubernetes,
    LaTeXLight,
    LaravelLight,
    LessLight,
    LinkedIn,
    LinuxLight,
    LitLight,
    LuaLight,
    MarkdownLight,
    MastodonLight,
    MaterialUILight,
    MatlabLight,
    MavenLight,
    MintLight,
    MisskeyLight,
    MySQLLight,
    NeoVimLight,
    NestJSLight,
    NetlifyLight,
    NextJSLight,
    Nginx,
    NimLight,
    NixLight,
    NodeJSLight,
    NotionLight,
    NpmLight,
    NuxtJSLight,
    OCaml,
    ObsidianLight,
    OctaveLight,
    OpenCVLight,
    OpenStackLight,
    PHPLight,
    Perl,
    Photoshop,
    PhpStormLight,
    PiniaLight,
    PklLight,
    Plan9Light,
    PlanetScaleLight,
    PnpmLight,
    PostgreSQLLight,
    Postman,
    PowershellLight,
    Premiere,
    Prisma,
    ProcessingLight,
    Prometheus,
    PugLight,
    PyCharmLight,
    PyTorchLight,
    PythonLight,
    QTLight,
    RLight,
    ROSLight,
    RabbitMQLight,
    RaspberryPiLight,
    ReactLight,
    ReactiveXLight,
    RedHatLight,
    RedisLight,
    RegexLight,
    RemixLight,
    ReplitLight,
    RiderLight,
    RobloxStudio,
    Rocket,
    RollupJSLight,
    Ruby,
    Rust,
    SQLite,
    SVGLight,
    Sass,
    ScalaLight,
    SciKitLearnLight,
    Selenium,
    Sentry,
    SequelizeLight,
    SketchupLight,
    SolidJSLight,
    Solidity,
    SpringLight,
    StackOverflowLight,
    StyledComponents,
    SublimeLight,
    SupabaseLight,
    Svelte,
    Swift,
    SymfonyLight,
    TailwindCSSLight,
    TauriLight,
    TensorFlowLight,
    TerraformLight,
    ThreeJSLight,
    Twitter,
    TypeScript,
    UbuntuLight,
    UnityLight,
    UnrealEngine,
    VIMLight,
    VLight,
    VSCodeLight,
    VSCodiumLight,
    VercelLight,
    VisualStudioLight,
    ViteLight,
    VitestLight,
    VueJSLight,
    VuetifyLight,
    WebStormLight,
    WebpackLight,
    WindiCSSLight,
    WindowsLight,
    WorkersLight,
    XD,
    YarnLight,
    YewLight,
    ZigLight,
    p5js
  } from "@fdorantesm/react-skill-icons";
  
import { ReactComponent as VerbalCommunicationIcon } from "../assets/SVG/Skills icons/Verbal communication.svg";
import { ReactComponent as WrittenCommunicationIcon } from "../assets/SVG/Skills icons/Written communication.svg";
import { ReactComponent as ActiveListeningIcon } from "../assets/SVG/Skills icons/Active listening.svg";
import { ReactComponent as PublicSpeakingIcon } from "../assets/SVG/Skills icons/Public Speaking.svg";
import { ReactComponent as NegotiationIcon } from "../assets/SVG/Skills icons/Negotiation.svg";
import { ReactComponent as TeamManagementIcon } from "../assets/SVG/Skills icons/Task management.svg";
import { ReactComponent as DecisionMakingIcon } from "../assets/SVG/Skills icons/Decision-making.svg";
import { ReactComponent as DelegationIcon } from "../assets/SVG/Skills icons/Delegation.svg";
import { ReactComponent as ConflictResolutionIcon } from "../assets/SVG/Skills icons/Conflict resolution.svg";
import { ReactComponent as StrategicThinkingIcon } from "../assets/SVG/Skills icons/Critical Thinking.svg";
import { ReactComponent as EmpathyIcon } from "../assets/SVG/Skills icons/Empathy.svg";
import { ReactComponent as CollaborationIcon } from "../assets/SVG/Skills icons/Collaboration.svg";
// import { ReactComponent as RelationshipBuildingIcon } from "../assets/SVG/Skills icons/Relationship building.svg";
import { ReactComponent as EmotionalIntelligenceIcon } from "../assets/SVG/Skills icons/Emotional Intelligence.svg";
import { ReactComponent as NetworkingIcon } from "../assets/SVG/Skills icons/Networking.svg";
import { ReactComponent as PrioritizationIcon } from "../assets/SVG/Skills icons/Prioritization.svg";
// import { ReactComponent as PlanningAndOrganizationIcon } from "../assets/SVG/Skills icons/Planning and organization.svg";
import { ReactComponent as TaskManagementIcon } from "../assets/SVG/Skills icons/Task management.svg";
import { ReactComponent as MeetingDeadlinesIcon } from "../assets/SVG/Skills icons/Meeting deadlines.svg";
import { ReactComponent as MultitaskingIcon } from "../assets/SVG/Skills icons/Multitasking.svg";
import { ReactComponent as CriticalThinkingIcon } from "../assets/SVG/Skills icons/Critical Thinking.svg";
import { ReactComponent as AnalyticalReasoningIcon } from "../assets/SVG/Skills icons/Analytical reasoning.svg";
import { ReactComponent as CreativityIcon } from "../assets/SVG/Skills icons/Creativity.svg";
import { ReactComponent as AdaptabilityIcon } from "../assets/SVG/Skills icons/Adaptability.svg";
import { ReactComponent as InnovationIcon } from "../assets/SVG/Skills icons/Innovation.svg";
import { ReactComponent as ResponsibilityIcon } from "../assets/SVG/Skills icons/Responsibility.svg";
import { ReactComponent as DependabilityIcon } from "../assets/SVG/Skills icons/Dependability.svg";
import { ReactComponent as IntegrityIcon } from "../assets/SVG/Skills icons/Integrity.svg";
import { ReactComponent as SelfMotivationIcon } from "../assets/SVG/Skills icons/Self-motivation.svg";
// import { ReactComponent as InitiativeIcon } from "../assets/SVG/Skills icons/Initiative.svg";
// import { ReactComponent as CooperationIcon } from "../assets/SVG/Skills icons/Cooperation.svg";
import { ReactComponent as OpenMindednessIcon } from "../assets/SVG/Skills icons/Open-mindedness.svg";
import { ReactComponent as MediationIcon } from "../assets/SVG/Skills icons/Mediation.svg";
import { ReactComponent as FlexibilityIcon } from "../assets/SVG/Skills icons/Flexibility.svg";
import { ReactComponent as FeedbackIcon } from "../assets/SVG/Skills icons/Feedbacl.svg";
import { ReactComponent as PatienceIcon } from "../assets/SVG/Skills icons/Patience.svg";
import { ReactComponent as PersuasionIcon } from "../assets/SVG/Skills icons/Persuasion.svg";
// import { ReactComponent as RelationshipManagementIcon } from "../assets/SVG/Skills icons/Relationship management.svg";
import { ReactComponent as AttentionToDetailIcon } from "../assets/SVG/Skills icons/Attention to detail.svg";
import { ReactComponent as ResilienceIcon } from "../assets/SVG/Skills icons/Resilience.svg";
// import { ReactComponent as StressManagementIcon } from "../assets/SVG/Skills icons/Stress management.svg";
import { ReactComponent as PositivityIcon } from "../assets/SVG/Skills icons/Positivity.svg";
import { ReactComponent as SelfAwarenessIcon } from "../assets/SVG/Skills icons/Self-awareness.svg";


export const skillsData:skillOption[] = [
     { label: "AWS", value: "AWSLight", icon: AWSLight },
    { label: "Ableton", value: "AbletonLight", icon: AbletonLight },
    { label: "ActivityPub", value: "ActivityPubLight", icon: ActivityPubLight },
    { label: "Actix", value: "ActixLight", icon: ActixLight },
    { label: "Adonis", value: "Adonis", icon: Adonis },
    { label: "AfterEffects", value: "AfterEffects", icon: AfterEffects },
    { label: "AiScript", value: "AiScriptLight", icon: AiScriptLight },
    { label: "AlpineJS", value: "AlpineJSLight", icon: AlpineJSLight },
    { label: "Anaconda", value: "AnacondaLight", icon: AnacondaLight },
    { label: "AndroidStudio", value: "AndroidStudioLight", icon: AndroidStudioLight },
    { label: "Angular", value: "AngularLight", icon: AngularLight },
    { label: "Ansible", value: "Ansible", icon: Ansible },
    { label: "Apollo", value: "Apollo", icon: Apollo },
    { label: "Apple", value: "AppleLight", icon: AppleLight },
    { label: "Appwrite", value: "Appwrite", icon: Appwrite },
    { label: "Arch", value: "ArchLight", icon: ArchLight },
    { label: "Arduino", value: "Arduino", icon: Arduino },
    { label: "Astro", value: "Astro", icon: Astro },
    { label: "Atom", value: "Atom", icon: Atom },
    { label: "Audition", value: "Audition", icon: Audition },
    { label: "AutoCAD", value: "AutoCADLight", icon: AutoCADLight },
    { label: "Azul", value: "Azul", icon: Azul },
    { label: "Azure", value: "AzureLight", icon: AzureLight },
    { label: "BSD", value: "BSDLight", icon: BSDLight },
    { label: "Babel", value: "Babel", icon: Babel },
    { label: "Bash", value: "BashLight", icon: BashLight },
    { label: "Bevy", value: "BevyLight", icon: BevyLight },
    { label: "BitBucket", value: "BitBucketLight", icon: BitBucketLight },
    { label: "Blender", value: "BlenderLight", icon: BlenderLight },
    { label: "Bootstrap", value: "Bootstrap", icon: Bootstrap },
    { label: "Bun", value: "BunLight", icon: BunLight },
    { label: "C", value: "C", icon: C },
    { label: "CLion", value: "CLionLight", icon: CLionLight },
    { label: "CMake", value: "CMakeLight", icon: CMakeLight },
    { label: "CPP", value: "CPP", icon: CPP },
    { label: "CS", value: "CS", icon: CS },
    { label: "CSS", value: "CSS", icon: CSS },
    { label: "Cassandra", value: "CassandraLight", icon: CassandraLight },
    { label: "Clojure", value: "ClojureLight", icon: ClojureLight },
    { label: "Cloudflare", value: "CloudflareLight", icon: CloudflareLight },
    { label: "CodePen", value: "CodePenLight", icon: CodePenLight },
    { label: "CoffeeScript", value: "CoffeeScriptLight", icon: CoffeeScriptLight },
    { label: "Crystal", value: "CrystalLight", icon: CrystalLight },
    { label: "Cypress", value: "CypressLight", icon: CypressLight },
    { label: "D3", value: "D3Light", icon: D3Light },
    { label: "DENON", value: "DENOLight", icon: DENOLight },
    { label: "Dart", value: "DartLight", icon: DartLight },
    { label: "Debian", value: "DebianLight", icon: DebianLight },
    { label: "DevTo", value: "DevToLight", icon: DevToLight },
    { label: "Discord", value: "Discord", icon: Discord },
    { label: "DiscordBots", value: "DiscordBots", icon: DiscordBots },
    { label: "DiscordJS", value: "DiscordJSLight", icon: DiscordJSLight },
    { label: "Django", value: "Django", icon: Django },
    { label: "Docker", value: "Docker", icon: Docker },
    { label: "DotNet", value: "DotNet", icon: DotNet },
    { label: "DynamoDB", value: "DynamoDBLight", icon: DynamoDBLight },
    { label: "Eclipse", value: "EclipseLight", icon: EclipseLight },
    { label: "Elasticsearch", value: "ElasticsearchLight", icon: ElasticsearchLight },
    { label: "Electron", value: "Electron", icon: Electron },
    { label: "Elixir", value: "ElixirLight", icon: ElixirLight },
    { label: "Elysia", value: "ElysiaLight", icon: ElysiaLight },
    { label: "Emacs", value: "Emacs", icon: Emacs },
    { label: "Ember", value: "Ember", icon: Ember },
    { label: "Emotion", value: "EmotionLight", icon: EmotionLight },
    { label: "ExpressJS", value: "ExpressJSLight", icon: ExpressJSLight },
    { label: "FastAPI", value: "FastAPI", icon: FastAPI },
    { label: "Fediverse", value: "FediverseLight", icon: FediverseLight },
    { label: "Figma", value: "FigmaLight", icon: FigmaLight },
    { label: "Firebase", value: "FirebaseLight", icon: FirebaseLight },
    { label: "Flask", value: "FlaskLight", icon: FlaskLight },
    { label: "Flutter", value: "FlutterLight", icon: FlutterLight },
    { label: "Forth", value: "Forth", icon: Forth },
    { label: "Fortran", value: "Fortran", icon: Fortran },
    { label: "GCP", value: "GCPLight", icon: GCPLight },
    { label: "GTK", value: "GTKLight", icon: GTKLight },
    { label: "GameMakerStudio", value: "GameMakerStudio", icon: GameMakerStudio },
    { label: "Gatsby", value: "Gatsby", icon: Gatsby },
    { label: "Gherkin", value: "GherkinLight", icon: GherkinLight },
    { label: "Git", value: "Git", icon: Git },
    { label: "GitLab", value: "GitLabLight", icon: GitLabLight },
    { label: "GithubActions", value: "GithubActionsLight", icon: GithubActionsLight },
    { label: "Github", value: "GithubLight", icon: GithubLight },
    { label: "Gmail", value: "GmailLight", icon: GmailLight },
    { label: "GoLang", value: "GoLang", icon: GoLang },
    { label: "Godot", value: "GodotLight", icon: GodotLight },
    { label: "Gradle", value: "GradleLight", icon: GradleLight },
    { label: "Grafana", value: "GrafanaLight", icon: GrafanaLight },
    { label: "GraphQL", value: "GraphQLLight", icon: GraphQLLight },
    { label: "Gulp", value: "Gulp", icon: Gulp },
    { label: "HTML", value: "HTML", icon: HTML },
    { label: "Haskell", value: "HaskellLight", icon: HaskellLight },
    { label: "Haxe", value: "HaxeLight", icon: HaxeLight },
    { label: "HaxeFlixel", value: "HaxeFlixelLight", icon: HaxeFlixelLight },
    { label: "Heroku", value: "Heroku", icon: Heroku },
    { label: "Hibernate", value: "HibernateLight", icon: HibernateLight },
    { label: "Htmx", value: "HtmxLight", icon: HtmxLight },
    { label: "IPFS", value: "IPFSLight", icon: IPFSLight },
    { label: "Idea", value: "IdeaLight", icon: IdeaLight },
    { label: "Illustrator", value: "Illustrator", icon: Illustrator },
    { label: "Instagram", value: "Instagram", icon: Instagram },
    { label: "JQuery", value: "JQuery", icon: JQuery },
    { label: "Java", value: "JavaLight", icon: JavaLight },
    { label: "JavaScript", value: "JavaScript", icon: JavaScript },
    { label: "Jenkins", value: "JenkinsLight", icon: JenkinsLight },
    { label: "Jest", value: "Jest", icon: Jest },
    { label: "Julia", value: "JuliaLight", icon: JuliaLight },
    { label: "Kafka", value: "Kafka", icon: Kafka },
    { label: "Kali", value: "KaliLight", icon: KaliLight },
    { label: "Kotlin", value: "KotlinLight", icon: KotlinLight },
    { label: "Ktor", value: "KtorLight", icon: KtorLight },
    { label: "Kubernetes", value: "Kubernetes", icon: Kubernetes },
    { label: "LaTeX", value: "LaTeXLight", icon: LaTeXLight },
    { label: "Laravel", value: "LaravelLight", icon: LaravelLight },
    { label: "Less", value: "LessLight", icon: LessLight },
    { label: "LinkedIn", value: "LinkedIn", icon: LinkedIn },
    { label: "Linux", value: "LinuxLight", icon: LinuxLight },
    { label: "Lit", value: "LitLight", icon: LitLight },
    { label: "Lua", value: "LuaLight", icon: LuaLight },
    { label: "Markdown", value: "MarkdownLight", icon: MarkdownLight },
    { label: "Mastodon", value: "MastodonLight", icon: MastodonLight },
    { label: "MaterialUI", value: "MaterialUILight", icon: MaterialUILight },
    { label: "Matlab", value: "MatlabLight", icon: MatlabLight },
    { label: "Maven", value: "MavenLight", icon: MavenLight },
    { label: "Mint", value: "MintLight", icon: MintLight },
    { label: "Misskey", value: "MisskeyLight", icon: MisskeyLight },
    { label: "MySQL", value: "MySQLLight", icon: MySQLLight },
    { label: "NeoVim", value: "NeoVimLight", icon: NeoVimLight },
    { label: "NestJS", value: "NestJSLight", icon: NestJSLight },
    { label: "Netlify", value: "NetlifyLight", icon: NetlifyLight },
    { label: "NextJS", value: "NextJSLight", icon: NextJSLight },
    { label: "Nginx", value: "Nginx", icon: Nginx },
    { label: "Nim", value: "NimLight", icon: NimLight },
    { label: "Nix", value: "NixLight", icon: NixLight },
    { label: "NodeJS", value: "NodeJSLight", icon: NodeJSLight },
    { label: "Notion", value: "NotionLight", icon: NotionLight },
    { label: "Npm", value: "NpmLight", icon: NpmLight },
    { label: "NuxtJS", value: "NuxtJSLight", icon: NuxtJSLight },
    { label: "OCaml", value: "OCaml", icon: OCaml },
    { label: "Obsidian", value: "ObsidianLight", icon: ObsidianLight },
    { label: "Octave", value: "OctaveLight", icon: OctaveLight },
    { label: "OpenCV", value: "OpenCVLight", icon: OpenCVLight },
    { label: "OpenStack", value: "OpenStackLight", icon: OpenStackLight },
    { label: "PHP", value: "PHPLight", icon: PHPLight },
    { label: "Perl", value: "Perl", icon: Perl },
    { label: "Photoshop", value: "Photoshop", icon: Photoshop },
    { label: "PhpStorm", value: "PhpStormLight", icon: PhpStormLight },
    { label: "Pinia", value: "PiniaLight", icon: PiniaLight },
    { label: "Pkl", value: "PklLight", icon: PklLight },
    { label: "Plan9", value: "Plan9Light", icon: Plan9Light },
    { label: "PlanetScale", value: "PlanetScaleLight", icon: PlanetScaleLight },
    { label: "Pnpm", value: "PnpmLight", icon: PnpmLight },
    { label: "PostgreSQL", value: "PostgreSQLLight", icon: PostgreSQLLight },
    { label: "Postman", value: "Postman", icon: Postman },
    { label: "Powershell", value: "PowershellLight", icon: PowershellLight },
    { label: "Premiere", value: "Premiere", icon: Premiere },
    { label: "Prisma", value: "Prisma", icon: Prisma },
    { label: "Processing", value: "ProcessingLight", icon: ProcessingLight },
    { label: "Prometheus", value: "Prometheus", icon: Prometheus },
    { label: "Pug", value: "PugLight", icon: PugLight },
    { label: "PyCharm", value: "PyCharmLight", icon: PyCharmLight },
    { label: "PyTorch", value: "PyTorchLight", icon: PyTorchLight },
    { label: "Python", value: "PythonLight", icon: PythonLight },
    { label: "QT", value: "QTLight", icon: QTLight },
    { label: "R", value: "RLight", icon: RLight },
    { label: "ROS", value: "ROSLight", icon: ROSLight },
    { label: "RabbitMQ", value: "RabbitMQLight", icon: RabbitMQLight },
    { label: "RaspberryPi", value: "RaspberryPiLight", icon: RaspberryPiLight },
    { label: "React", value: "ReactLight", icon: ReactLight },
    { label: "ReactiveX", value: "ReactiveXLight", icon: ReactiveXLight },
    { label: "RedHat", value: "RedHatLight", icon: RedHatLight },
    { label: "Redis", value: "RedisLight", icon: RedisLight },
    { label: "Regex", value: "RegexLight", icon: RegexLight },
    { label: "Remix", value: "RemixLight", icon: RemixLight },
    { label: "Replit", value: "ReplitLight", icon: ReplitLight },
    { label: "Rider", value: "RiderLight", icon: RiderLight },
    { label: "RobloxStudio", value: "RobloxStudio", icon: RobloxStudio },
    { label: "Rocket", value: "Rocket", icon: Rocket },
    { label: "RollupJS", value: "RollupJSLight", icon: RollupJSLight },
    { label: "Ruby", value: "Ruby", icon: Ruby },
    { label: "Rust", value: "Rust", icon: Rust },
    { label: "SQLite", value: "SQLite", icon: SQLite },
    { label: "SVG", value: "SVGLight", icon: SVGLight },
    { label: "Sass", value: "Sass", icon: Sass },
    { label: "Scala", value: "ScalaLight", icon: ScalaLight },
    { label: "SciKitLearn", value: "SciKitLearnLight", icon: SciKitLearnLight },
    { label: "Selenium", value: "Selenium", icon: Selenium },
    { label: "Sentry", value: "Sentry", icon: Sentry },
    { label: "Sequelize", value: "SequelizeLight", icon: SequelizeLight },
    { label: "Sketchup", value: "SketchupLight", icon: SketchupLight },
    { label: "SolidJS", value: "SolidJSLight", icon: SolidJSLight },
    { label: "Solidity", value: "Solidity", icon: Solidity },
    { label: "Spring", value: "SpringLight", icon: SpringLight },
    { label: "StackOverflow", value: "StackOverflowLight", icon: StackOverflowLight },
    { label: "StyledComponents", value: "StyledComponents", icon: StyledComponents },
    { label: "Sublime", value: "SublimeLight", icon: SublimeLight },
    { label: "Supabase", value: "SupabaseLight", icon: SupabaseLight },
    { label: "Svelte", value: "Svelte", icon: Svelte },
    { label: "Swift", value: "Swift", icon: Swift },
    { label: "Symfony", value: "SymfonyLight", icon: SymfonyLight },
    { label: "TailwindCSS", value: "TailwindCSSLight", icon: TailwindCSSLight },
    { label: "Tauri", value: "TauriLight", icon: TauriLight },
    { label: "TensorFlow", value: "TensorFlowLight", icon: TensorFlowLight },
    { label: "Terraform", value: "TerraformLight", icon: TerraformLight },
    { label: "ThreeJS", value: "ThreeJSLight", icon: ThreeJSLight },
    { label: "Twitter", value: "Twitter", icon: Twitter },
    { label: "TypeScript", value: "TypeScript", icon: TypeScript },
    { label: "Ubuntu", value: "UbuntuLight", icon: UbuntuLight },
    { label: "Unity", value: "UnityLight", icon: UnityLight },
    { label: "UnrealEngine", value: "UnrealEngine", icon: UnrealEngine },
    { label: "VIM", value: "VIMLight", icon: VIMLight },
    { label: "V", value: "VLight", icon: VLight },
    { label: "VSCode", value: "VSCodeLight", icon: VSCodeLight },
    { label: "VSCodium", value: "VSCodiumLight", icon: VSCodiumLight },
    { label: "Vercel", value: "VercelLight", icon: VercelLight },
    { label: "VisualStudio", value: "VisualStudioLight", icon: VisualStudioLight },
    { label: "Vite", value: "ViteLight", icon: ViteLight },
    { label: "Vitest", value: "VitestLight", icon: VitestLight },
    { label: "VueJS", value: "VueJSLight", icon: VueJSLight },
    { label: "Vuetify", value: "VuetifyLight", icon: VuetifyLight },
    { label: "WebStorm", value: "WebStormLight", icon: WebStormLight },
    { label: "Webpack", value: "WebpackLight", icon: WebpackLight },
    { label: "WindiCSS", value: "WindiCSSLight", icon: WindiCSSLight },
    { label: "Windows", value: "WindowsLight", icon: WindowsLight },
    { label: "Workers", value: "WorkersLight", icon: WorkersLight },
    { label: "XD", value: "XD", icon: XD },
    { label: "Yarn", value: "YarnLight", icon: YarnLight },
    { label: "Yew", value: "YewLight", icon: YewLight },
    { label: "Zig", value: "ZigLight", icon: ZigLight },
    { label: "p5js", value: "p5js", icon: p5js } 
  ];

export interface NonTechnicalSkill {
  label: string;
    icon?: JSX.Element;
    value: string;
}

const nonTechIconStyle ={ marginRight: '8px', width:"26px", height:"26px" }

export const nonTechSkillsData: NonTechnicalSkill[] = [
  { label: "Verbal communication", value: "Verbal communication", icon: <VerbalCommunicationIcon style={nonTechIconStyle} /> },
  { label: "Written communication", value: "Written communication", icon: <WrittenCommunicationIcon style={nonTechIconStyle} /> },
  { label: "Active listening", value: "Active listening", icon: <ActiveListeningIcon style={nonTechIconStyle} /> },
  { label: "Public speaking", value: "Public speaking", icon: <PublicSpeakingIcon style={nonTechIconStyle} /> },
  { label: "Negotiation", value: "Negotiation", icon: <NegotiationIcon style={nonTechIconStyle} /> },
  { label: "Team management", value: "Team management", icon: <TeamManagementIcon style={nonTechIconStyle} /> },
  { label: "Decision-making", value: "Decision-making", icon: <DecisionMakingIcon style={nonTechIconStyle} /> },
  { label: "Delegation", value: "Delegation", icon: <DelegationIcon style={nonTechIconStyle} /> },
  { label: "Conflict resolution", value: "Conflict resolution", icon: <ConflictResolutionIcon style={nonTechIconStyle} /> },
  { label: "Strategic thinking", value: "Strategic thinking", icon: <StrategicThinkingIcon style={nonTechIconStyle} /> },
  { label: "Empathy", value: "Empathy", icon: <EmpathyIcon style={nonTechIconStyle} /> },
  { label: "Collaboration", value: "Collaboration", icon: <CollaborationIcon style={nonTechIconStyle} /> },
  // { label: "Relationship building", value: "Relationship building", icon: <RelationshipBuildingIcon style={nonTechIconStyle} /> },
  { label: "Emotional intelligence", value: "Emotional intelligence", icon: <EmotionalIntelligenceIcon style={nonTechIconStyle} /> },
  { label: "Networking", value: "Networking", icon: <NetworkingIcon style={nonTechIconStyle} /> },
  { label: "Prioritization", value: "Prioritization", icon: <PrioritizationIcon style={nonTechIconStyle} /> },
  // { label: "Planning and organization", value: "Planning and organization", icon: <PlanningAndOrganizationIcon style={nonTechIconStyle} /> },
  { label: "Task management", value: "Task management", icon: <TaskManagementIcon style={nonTechIconStyle} /> },
  { label: "Meeting deadlines", value: "Meeting deadlines", icon: <MeetingDeadlinesIcon style={nonTechIconStyle} /> },
  { label: "Multitasking", value: "Multitasking", icon: <MultitaskingIcon style={nonTechIconStyle} /> },
  { label: "Critical thinking", value: "Critical thinking", icon: <CriticalThinkingIcon style={nonTechIconStyle} /> },
  { label: "Analytical reasoning", value: "Analytical reasoning", icon: <AnalyticalReasoningIcon style={nonTechIconStyle} /> },
  { label: "Creativity", value: "Creativity", icon: <CreativityIcon style={nonTechIconStyle} /> },
  { label: "Adaptability", value: "Adaptability", icon: <AdaptabilityIcon style={nonTechIconStyle} /> },
  { label: "Innovation", value: "Innovation", icon: <InnovationIcon style={nonTechIconStyle} /> },
  { label: "Responsibility", value: "Responsibility", icon: <ResponsibilityIcon style={nonTechIconStyle} /> },
  { label: "Dependability", value: "Dependability", icon: <DependabilityIcon style={nonTechIconStyle} /> },
  { label: "Integrity", value: "Integrity", icon: <IntegrityIcon style={nonTechIconStyle} /> },
  { label: "Self-motivation", value: "Self-motivation", icon: <SelfMotivationIcon style={nonTechIconStyle} /> },
  // { label: "Initiative", value: "Initiative", icon: <InitiativeIcon style={nonTechIconStyle} /> },
  // { label: "Cooperation", value: "Cooperation", icon: <CooperationIcon style={nonTechIconStyle} /> },
  { label: "Open-mindedness", value: "Open-mindedness", icon: <OpenMindednessIcon style={nonTechIconStyle} /> },
  { label: "Mediation", value: "Mediation", icon: <MediationIcon style={nonTechIconStyle} /> },
  { label: "Flexibility", value: "Flexibility", icon: <FlexibilityIcon style={nonTechIconStyle} /> },
  { label: "Giving and receiving feedback", value: "Giving and receiving feedback", icon: <FeedbackIcon style={nonTechIconStyle} /> },
  { label: "Patience", value: "Patience", icon: <PatienceIcon style={nonTechIconStyle} /> },
  { label: "Persuasion", value: "Persuasion", icon: <PersuasionIcon style={nonTechIconStyle} /> },
  // { label: "Relationship management", value: "Relationship management", icon: <RelationshipManagementIcon style={nonTechIconStyle} /> },
  { label: "Attention to detail", value: "Attention to detail", icon: <AttentionToDetailIcon style={nonTechIconStyle} /> },
  { label: "Resilience", value: "Resilience", icon: <ResilienceIcon style={nonTechIconStyle} /> },
  // { label: "Stress management", value: "Stress management", icon: <StressManagementIcon style={nonTechIconStyle} /> },
  { label: "Positivity", value: "Positivity", icon: <PositivityIcon style={nonTechIconStyle} /> },
  { label: "Self-awareness", value: "Self-awareness", icon: <SelfAwarenessIcon style={nonTechIconStyle} /> },
];

  


export interface skillOption {
    label: string;
    value: string;
    icon?: (props: React.SVGProps<SVGSVGElement>) => React.JSX.Element | JSX.Element;
}

export const hobbiesData:skillOption[] = 
[
    { "label": "Reading", "value": "Reading" },
    { "label": "Writing", "value": "Writing" },
    { "label": "Traveling", "value": "Traveling" },
    { "label": "Cooking", "value": "Cooking" },
    { "label": "Gardening", "value": "Gardening" },
    { "label": "Photography", "value": "Photography" },
    { "label": "Painting", "value": "Painting" },
    { "label": "Drawing", "value": "Drawing" },
    { "label": "Music", "value": "Music" },
    { "label": "Dancing", "value": "Dancing" },
    { "label": "Hiking", "value": "Hiking" },
    { "label": "Fishing", "value": "Fishing" },
    { "label": "Camping", "value": "Camping" },
    { "label": "Cycling", "value": "Cycling" },
    { "label": "Running", "value": "Running" },
    { "label": "Swimming", "value": "Swimming" },
    { "label": "Yoga", "value": "Yoga" },
    { "label": "Meditation", "value": "Meditation" },
    { "label": "Gaming", "value": "Gaming" },
    { "label": "Board Games", "value": "Board Games" },
    { "label": "Puzzles", "value": "Puzzles" },
    { "label": "Knitting", "value": "Knitting" },
    { "label": "Crocheting", "value": "Crocheting" },
    { "label": "Sewing", "value": "Sewing" },
    { "label": "Crafting", "value": "Crafting" },
    { "label": "Woodworking", "value": "Woodworking" },
    { "label": "DIY Projects", "value": "DIY Projects" },
    { "label": "Model Building", "value": "Model Building" },
    { "label": "Bird Watching", "value": "Bird Watching" },
    { "label": "Stargazing", "value": "Stargazing" },
    { "label": "Volunteering", "value": "Volunteering" },
    { "label": "Blogging", "value": "Blogging" },
    { "label": "Vlogging", "value": "Vlogging" },
    { "label": "Podcasting", "value": "Podcasting" },
    { "label": "Collecting", "value": "Collecting" },
    { "label": "Antiquing", "value": "Antiquing" },
    { "label": "Genealogy", "value": "Genealogy" },
    { "label": "Astronomy", "value": "Astronomy" },
    { "label": "Science Experiments", "value": "Science Experiments" },
    { "label": "Homebrewing", "value": "Homebrewing" },
    { "label": "Wine Tasting", "value": "Wine Tasting" },
    { "label": "Cooking Classes", "value": "Cooking Classes" },
    { "label": "Language Learning", "value": "Language Learning" },
    { "label": "Martial Arts", "value": "Martial Arts" },
    { "label": "Rock Climbing", "value": "Rock Climbing" },
    { "label": "Skydiving", "value": "Skydiving" },
    { "label": "Scuba Diving", "value": "Scuba Diving" },
    { "label": "Surfing", "value": "Surfing" },
    { "label": "Skiing", "value": "Skiing" },
    { "label": "Snowboarding", "value": "Snowboarding" },
    { "label": "Tennis", "value": "Tennis" },
    { "label": "Golf", "value": "Golf" },
    { "label": "Basketball", "value": "Basketball" },
    { "label": "Soccer", "value": "Soccer" },
    { "label": "Baseball", "value": "Baseball" },
    { "label": "Football", "value": "Football" },
    { "label": "Badminton", "value": "Badminton" },
    { "label": "Table Tennis", "value": "Table Tennis" },
    { "label": "Fencing", "value": "Fencing" },
    { "label": "Archery", "value": "Archery" },
    { "label": "Horseback Riding", "value": "Horseback Riding" }
]

export const languagesData = [
  { label: "English", value: "English", isSelected: false },
  { label: "Spanish", value: "Spanish", isSelected: false },
  { label: "Mandarin Chinese", value: "Mandarin Chinese", isSelected: false },
  { label: "Hindi", value: "Hindi", isSelected: false },
  { label: "French", value: "French", isSelected: false },
  { label: "Arabic", value: "Arabic", isSelected: false },
  { label: "Bengali", value: "Bengali", isSelected: false },
  { label: "Russian", value: "Russian", isSelected: false },
  { label: "Portuguese", value: "Portuguese", isSelected: false },
  { label: "Indonesian", value: "Indonesian", isSelected: false },
  { label: "German", value: "German", isSelected: false },
  { label: "Japanese", value: "Japanese", isSelected: false },
  { label: "Korean", value: "Korean", isSelected: false },
  { label: "Italian", value: "Italian", isSelected: false },
  { label: "Turkish", value: "Turkish", isSelected: false },
  { label: "Polish", value: "Polish", isSelected: false },
  { label: "Dutch", value: "Dutch", isSelected: false },
  { label: "Greek", value: "Greek", isSelected: false },
  { label: "Swedish", value: "Swedish", isSelected: false },
  { label: "Norwegian", value: "Norwegian", isSelected: false },
  { label: "Danish", value: "Danish", isSelected: false },
  { label: "Finnish", value: "Finnish", isSelected: false },
  { label: "Hungarian", value: "Hungarian", isSelected: false },
  { label: "Czech", value: "Czech", isSelected: false },
  { label: "Slovak", value: "Slovak", isSelected: false },
  { label: "Romanian", value: "Romanian", isSelected: false },
  { label: "Bulgarian", value: "Bulgarian", isSelected: false },
  { label: "Croatian", value: "Croatian", isSelected: false },
  { label: "Serbian", value: "Serbian", isSelected: false },
  { label: "Ukrainian", value: "Ukrainian", isSelected: false },
  { label: "Hebrew", value: "Hebrew", isSelected: false },
  { label: "Vietnamese", value: "Vietnamese", isSelected: false },
  { label: "Thai", value: "Thai", isSelected: false },
  { label: "Malay", value: "Malay", isSelected: false },
  { label: "Filipino", value: "Filipino", isSelected: false },
  { label: "Swahili", value: "Swahili", isSelected: false },
  { label: "Zulu", value: "Zulu", isSelected: false },
  { label: "Xhosa", value: "Xhosa", isSelected: false },
  { label: "Afrikaans", value: "Afrikaans", isSelected: false },
  { label: "Persian", value: "Persian", isSelected: false },
  { label: "Pashto", value: "Pashto", isSelected: false },
  { label: "Urdu", value: "Urdu", isSelected: false },
  { label: "Punjabi", value: "Punjabi", isSelected: false },
  { label: "Tamil", value: "Tamil", isSelected: false },
  { label: "Telugu", value: "Telugu", isSelected: false },
  { label: "Marathi", value: "Marathi", isSelected: false },
  { label: "Gujarati", value: "Gujarati", isSelected: false },
  { label: "Kannada", value: "Kannada", isSelected: false },
  { label: "Malayalam", value: "Malayalam", isSelected: false },
  { label: "Sinhalese", value: "Sinhalese", isSelected: false },
  { label: "Burmese", value: "Burmese", isSelected: false },
  { label: "Khmer", value: "Khmer", isSelected: false },
  { label: "Lao", value: "Lao", isSelected: false },
  { label: "Mongolian", value: "Mongolian", isSelected: false },
  { label: "Nepali", value: "Nepali", isSelected: false },
  { label: "Tibetan", value: "Tibetan", isSelected: false },
  { label: "Haitian Creole", value: "Haitian Creole", isSelected: false }
];







