import toast from "react-hot-toast";
import { sleep } from "./commonFunctions";

type ErrorResponse = {
  message?: string;
  [key: string]: unknown; // For additional unknown fields in the error response
};

export const responseHandler = async (response: Response) => {
  try {
    if (!response.ok) {
      if (response.status === 401) {
        toast.error("Session expired. Please login again.");
        await sleep(2000);
        window.location.replace(`${window.location.origin}/login`);
        return;
      }

      // Attempt to parse the error response as JSON
      const errorData: ErrorResponse = await response.json();

      if (typeof errorData === "string") {
        toast.error(errorData);
      } else if (errorData.message) {
        toast.error(errorData.message);
      } else {
        toast.error("An unexpected error occurred.");
      }

      // Optional: Throw an error to stop further execution
      throw new Error(response.statusText);
    }
  } catch (error) {
    if (error instanceof Error) {
      toast.error(error.message);
    } else {
      toast.error("An unexpected error occurred.");
    }
  }
};
