import { Box, Button, Modal, Typography } from "@mui/material";
import { ISectionEntryModalData } from "./Courses/CourseCard";
import { Dispatch, SetStateAction } from "react";
import { summarSectionModalstyle } from "../Summary";
import {ReactComponent as RemoveEntryIcon} from "../../../assets/SVG/new-delete-section.svg";
import {ReactComponent as CancelModalIcon} from "../../../assets/SVG/cancel-modal.svg";

interface ISectionEntryModal {
    sectionEntryModal:ISectionEntryModalData;
    setSectionEntryModal:Dispatch<SetStateAction<ISectionEntryModalData>>
}

const SectionEntryModal:React.FC<ISectionEntryModal> = ({sectionEntryModal,setSectionEntryModal}) => {
    return(
        <Modal
        // keepMounted
        onClose={()=> setSectionEntryModal((prev)=> ({...prev,modalState:false,entryDelete:false}))}
        open={sectionEntryModal?.modalState}
        // onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={summarSectionModalstyle}>
        <Box sx={{    
                    display:"flex",
                    flexDirection:"column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    pt:3,
                    pl:3,
                    pr:3,
                    pb:3,
                    }}>
            <Box sx={{width:"100%",display:"flex",alignItems:"center",justifyContent:"left",gap:"1rem",marginBottom:"1.75rem"}}>
                <RemoveEntryIcon/>
                <Typography sx={{flex:"1",fontSize:"24px",fontWeight:"500",color:"#344054"}} id="keep-mounted-modal-title" variant="h6" component="h2">
                    Delete Entry
                </Typography>
                <Box sx={{cursor:"pointer"}} onClick={()=> setSectionEntryModal((prev)=> ({...prev,modalState:false,entryDelete:false}))}>
                <CancelModalIcon/>
                </Box>
            </Box>
          <Typography sx={{fontSize:"18px",fontWeight:"400",color:"#475569"}} id="keep-mounted-modal-description">
          Are you sure you want to delete this entry ?
          </Typography>
          {/* <Typography sx={{fontSize:"18px",fontWeight:"500",padding:"0.5rem 0"}}>"{sectionEntryModal?.}"</Typography> */}
        </Box>
        <Box sx={{width:"100%",display:"flex",justifyContent:"right",gap:"1rem",
                    pt:0.5,
                    pr:3,
                    pb:3,}}>
            <Button onClick={()=> setSectionEntryModal((prev)=> ({...prev,entryDelete:true}))} variant="contained">Delete</Button>
            <Button onClick={()=> setSectionEntryModal((prev)=> ({...prev,modalState:false,entryDelete:false}))} sx={{border:"1px solid #cbd5e1",color:"#182230"}}>Cancel</Button>
        </Box>
        </Box>

      </Modal>
    )
};
export default SectionEntryModal;