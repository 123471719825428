import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {ReactComponent as CardIcon}  from "../../assets/SVG/cardIcon.svg"
import { IconButton } from '@mui/material';
import { cleanSummaryHTMLstring } from '../../components/common/CommonFunctions';
import {ReactComponent as EducationCardIcon} from "../../assets/SVG/general-education-card-icon.svg";
import {ReactComponent as ExperienceCardIcon} from "../../assets/SVG/general-experience-card-icon.svg";
import {ReactComponent as EditIcon } from "../../assets/SVG/link-edit-icon.svg";

const bulletPoint = '\u2022'; 

interface CardDetails {
    details : {
    heading:string,
    subHeading?:string,
    startDate?:string,
    endDate?:string,
    city?:string,
    aboutText?:string,
    company?:string,
    index?:number,
    isEducationSection?:boolean,
    isWorkExperienceSection?:boolean,
    duration?:string,
    jobType?:string,
    },
    handleEditClick?:(index?:number) => void;
    handleDeleteClick?:(index?:number) => void;
}

const EducationCard:React.FC<CardDetails> = ({details, handleEditClick, handleDeleteClick}) => {
  const {
    heading,
    subHeading,
    startDate,
    endDate,
    city,
    aboutText,
    company,
    index,
    isEducationSection,
    isWorkExperienceSection,
    duration,
    jobType
  } = details;
  return (
    <Card className="education-card-container" sx={{ width: "100%",boxShadow:"none",border:"1px solid #e2e8f0",borderRadius:"8px" }}>
      <CardContent className='card-content'         
      sx={{
          '&:last-child': {
            paddingBottom: 0, // Remove padding from last child
          },
        }}
>       
        <Box className="top-section" sx={aboutText ? {borderBottom:"1px dashed #e2e8f0",marginBottom:"0.5rem"} : {}}>
          <Box className="top-section-left">
              <CardIcon/>
          </Box>
          {isEducationSection ? <Box sx={{paddingLeft:"0.7rem",display:"flex",alignItems:"flex-start"}}>
            <EducationCardIcon/>
          </Box>
           :
          isWorkExperienceSection ? 
          <Box sx={{paddingLeft:"0.7rem",display:"flex",alignItems:"flex-start"}}>
            <ExperienceCardIcon/>
          </Box>
          :
          null
          }
          <Box className="top-section-right">
            <Box className="upper">
              <Box className="top-heading">
          <Typography variant="h5" component="div" className="heading">
            {heading}
          </Typography>
          {company && <Typography className="middle-heading">
            {company} {jobType && <span><span style={{padding:"0 0.5rem"}}>{bulletPoint}</span><span>{jobType}</span></span>  }
          </Typography>}
              </Box>

              <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",gap:"0.25rem",cursor:"pointer"}} onClick={()=>handleEditClick && handleEditClick(index ?? 1)}>
              <Box>
              <EditIcon style={{width:"11px",height:"11px"}}/>
              </Box>
                <Typography className="edit-button" >Edit</Typography>
              </Box>

            </Box>
            <Box className="lower">
            {(startDate  || endDate) ? <Typography variant="h6" className="sub-heading" gutterBottom>
            {startDate} to {endDate} <span style={{padding:"0 0.5rem"}}>{bulletPoint}</span><span>{duration}</span>
            </Typography> : <Typography variant="h6" className="sub-heading">{""}</Typography>}
            <Typography className="delete-button"  onClick={()=>handleDeleteClick && handleDeleteClick(index ?? 1)}>
              Delete
            </Typography>
            </Box>

          </Box>
        </Box>

        {aboutText && <Box className="middle-section">
            <Box className="about-section">
                <Typography variant="body1" component="div" className="about-text ui-text-3-color" dangerouslySetInnerHTML={{ __html: cleanSummaryHTMLstring(aboutText) }}>
                </Typography>
            </Box>
        </Box>}

      </CardContent>
      {/* <CardActions className="bottom-section">
        <Box className="action-container">
            <Box className="edit-button" onClick={handleEditClick}><EditOutlinedIcon className="edit-icon"/></Box>
            <Box className="delete-button" onClick={handleDeleteClick}><DeleteOutlineOutlinedIcon className="delete-icon"/></Box>
        </Box>
      </CardActions> */}
    </Card>
  );
}

export default EducationCard;
