import { Box, Button, Dialog, DialogContent, Typography } from '@mui/material';
import Lottie from 'react-lottie';

const Loading = ({ title = "", subTitle = "", lottieContent, onClose = () => {} }: {title?: string, subTitle?: string, lottieContent?: any, onClose?: any}) => {

  const defaultLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: lottieContent,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <Dialog open={true} onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '20px', // Apply 20px border radius to the dialog
        },
      }}
    >
      <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            height: "500px",
            width: "600px",
          }}
        >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        // position="fixed"
        // top={0}
        // left={0}
        // width="100%"
        // height="100%"
        zIndex={9999}
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.95)', // Light background with some opacity
          backdropFilter: 'blur(4px)', // Apply a blur effect to the background
          color: 'primary.main', // Use the primary color from the theme for the text
          p: 3, // Add some padding around the content
          borderRadius: '10px', // Optional: rounded corners for a softer look
        }}
      >
        <Lottie options={defaultLottieOptions} height={400} width={400} />
        <Box sx={{ py: 2, textAlign: "center" }}>
          <Typography variant="h5" gutterBottom sx={{ opacity: 0.85, color: "#344054", fontWeight: "bold" }}>
            {title}
          </Typography>
          <Typography variant="h6" gutterBottom sx={{ opacity: 0.85, color: "#5A697E" }}>
            {subTitle}
          </Typography>
        </Box>
        <Button variant="outlined" sx={{ color: "#344054", borderColor: "#CBD5E1" }} onClick={onClose}>Cancel</Button>
      </Box>
      </DialogContent>
    </Dialog>
  );
};

export default Loading;