import { Box, Grid, Typography, Link, Divider } from '@mui/material';
import { Open_Link_Icon } from '../../components/Icons';

const GreenCheckMark = (props: any) => (
  <svg
    width={36}
    height={40}
    viewBox="0 0 36 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={36} height={40} rx={18} fill="#ECF8EE" />
    <path
      d="M18 30C23.5228 30 28 25.5228 28 20C28 14.4772 23.5228 10 18 10C12.4772 10 8 14.4772 8 20C8 25.5228 12.4772 30 18 30ZM22.2071 18.2071L17.7071 22.7071C17.3166 23.0977 16.6835 23.0977 16.2929 22.7071L14.2929 20.7071C13.9024 20.3166 13.9024 19.6834 14.2929 19.2929C14.6834 18.9024 15.3166 18.9024 15.7071 19.2929L17 20.5858L20.7929 16.7929C21.1834 16.4024 21.8166 16.4024 22.2071 16.7929C22.5976 17.1834 22.5976 17.8166 22.2071 18.2071Z"
      fill="#19A44C"
    />
  </svg>
);

const BlueCheckMark = (props: any) => (
  <svg
    width={36}
    height={40}
    viewBox="0 0 36 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={36} height={40} rx={18} fill="#F1F5F9" />
    <path
      d="M18 30C23.5228 30 28 25.5228 28 20C28 14.4772 23.5228 10 18 10C12.4772 10 8 14.4772 8 20C8 25.5228 12.4772 30 18 30ZM22.2071 18.2071L17.7071 22.7071C17.3166 23.0977 16.6835 23.0977 16.2929 22.7071L14.2929 20.7071C13.9024 20.3166 13.9024 19.6834 14.2929 19.2929C14.6834 18.9024 15.3166 18.9024 15.7071 19.2929L17 20.5858L20.7929 16.7929C21.1834 16.4024 21.8166 16.4024 22.2071 16.7929C22.5976 17.1834 22.5976 17.8166 22.2071 18.2071Z"
      fill="#5594FF"
    />
  </svg>
);

const RedCheckMark = (props: any) => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={40} height={40} rx={20} fill="#F1F5F9" />
    <path
      d="M20 10C25.5228 10 30 14.4772 30 20C30 25.5228 25.5228 30 20 30C14.4772 30 10 25.5228 10 20C10 14.4772 14.4772 10 20 10ZM23.5303 16.4697L23.4462 16.3971C23.1852 16.2034 22.827 16.201 22.5636 16.3899L22.4697 16.4697L20 18.939L17.5303 16.4697L17.4462 16.3971C17.1852 16.2034 16.827 16.201 16.5636 16.3899L16.4697 16.4697L16.3971 16.5538C16.2034 16.8148 16.201 17.173 16.3899 17.4364L16.4697 17.5303L18.939 20L16.4697 22.4697L16.3971 22.5538C16.2034 22.8148 16.201 23.173 16.3899 23.4364L16.4697 23.5303L16.5538 23.6029C16.8148 23.7966 17.173 23.799 17.4364 23.6101L17.5303 23.5303L20 21.061L22.4697 23.5303L22.5538 23.6029C22.8148 23.7966 23.173 23.799 23.4364 23.6101L23.5303 23.5303L23.6029 23.4462C23.7966 23.1852 23.799 22.827 23.6101 22.5636L23.5303 22.4697L21.061 20L23.5303 17.5303L23.6029 17.4462C23.7966 17.1852 23.799 16.827 23.6101 16.5636L23.5303 16.4697L23.4462 16.3971L23.5303 16.4697Z"
      fill="#EF4444"
    />
  </svg>
);

const MatchResults: React.FC<{ 
  matchedResult: any,
  score?: number,
  jobUrl: string
}> = ({ matchedResult, jobUrl="https://www.google.com" }) => {
  const matchedResult1 = {
    "atsScore": 50,
    "resumeObjectWithChanges": {
        "professionalSummary": "Entry Level Software Engineer with expertise in Agile methodologies, project management, and data science.",
        "designation": "Data Analytics Manager",
        "certifications": [],
        "toolsTechnicalSkills": [
            "Encryption",
            "Authentication",
            "Manual Testing",
            "Software Testing",
            "Object-oriented Programming Techniques",
            "Agile Methodologies",
            "Software Development",
            "Chromatography",
            "JavaScript",
            "Java",
            "Python",
            "JIRA",
            "Visual Studio",
            "SQL",
            "Tableau",
            "Power BI"
        ],
        "programmingLanguages": [
            "JavaScript",
            "Java",
            "Python",
            "R",
            "SQL"
        ],
        "interests": [],
        "workExperiences": [
            {
                "index": "e31178f7-9392-45a2-b145-aac3fcc8f226",
                "keyResponsibilities": [
                    "Created and sustained 40 software applications with a 92% average bug-free code.",
                    "Contributed to developing 20 new features in the company’s flagship software product within 17 weeks on the job.",
                    "Partnered with 10 cross-functional teams to deliver software solutions on time and 66% below budget.",
                    "Implemented Agile methodologies via careful planning, reducing software development time by 55 days."
                ]
            }
        ]
    },
    "designation": "Data Analytics Manager",
    "companyName": "NA",
    "keywordsToAdd": [
        "Data Analytics",
        "Business Intelligence",
        "Statistical Modeling",
        "Predictive Modeling",
        "Data Visualization",
        "Data Mining",
        "Machine Learning"
    ],
    "weightage": {
        "professionalSummary": 5,
        "designation": 3,
        "certifications": 2,
        "toolsTechnicalSkills": 4,
        "programmingLanguages": 3,
        "interests": 1,
        "workExperiences": {
            "e31178f7-9392-45a2-b145-aac3fcc8f226": 6
        },
        "keywordsToAdd": 2
    }
  };

  if (!matchedResult1) {
    return <Typography>No matching results available</Typography>;
  }
  const styles = {
    container: {
      width: '100%',
      pt: 2,
    },
    item: {
      display: 'flex',
      alignItems: 'center',
      py: 1
    },
    itemText: {
      fontSize: "20px",
      fontWeight: 500,
      color: '#242424',
      ml: 2
    },
    itemIcon: {
      color: '#22C55E',
      fontSize: '20px'
    },
    failIcon: {
      color: '#EF4444',
      fontSize: '20px'
    },
    divider: {
      my: 2
    },
    scoreText: {
      fontSize: '32px',
      fontWeight: 'bold',
      color: '#19A44C',
      pb: 2
    },
    caption: {
      color: '#98A2B3',
      fontSize: '16px',
      fontWeight: 500,
      pb: 2
    },
    caption2: {
      color: '#242424',
      fontSize: '16px',
      fontWeight: 600,
      pb: 2,
      display: "flex"
    },
    jobLink: {
      ml: 1
    }
  };

  const hasChanges = (property: any) => {
    const changes = (matchedResult1.resumeObjectWithChanges as any)[property];
    if (Array.isArray(changes)) {
      return changes.length > 0;
    }
    return !!changes;
  };

  const getChangesList = () => {
    const changes = [];
    const properties = {
      'professionalSummary': 'Professional Summary',
      'designation': 'Job Title',
      'certifications': 'Certifications',
      'toolsTechnicalSkills': 'Technical Skills',
      'programmingLanguages': 'Programming Languages',
      'workExperiences': 'Work Experience'
    };

    for (const [key, label] of Object.entries(properties)) {
      if (hasChanges(key)) {
        changes.push({
          label,
          hasChange: true,
          weight: (matchedResult1.weightage as any)[key]
        });
      }
    }

    return changes.sort((a, b) => b.weight - a.weight);
  };

  return (
    <Box sx={styles.container}>
      <Grid container>
        {/* Left Column */}
        <Grid item xs={6} sx={{ borderRight: '1px solid #E2E8F0', pr: 2 }}>
          <Typography sx={styles.scoreText}>{matchedResult1.atsScore}%</Typography>
          <Typography sx={styles.caption}>Matching with your Resume</Typography>
          <Typography sx={styles.caption2}>
            {matchedResult1.designation} at {matchedResult1.companyName}
            <Link href={jobUrl} target="_blank" sx={styles.jobLink}>
              <Open_Link_Icon/>
            </Link>
          </Typography>
          
          <Divider sx={styles.divider} />
          
          <Box>
            {getChangesList().map((item, index) => (
              <Box key={item.label} sx={styles.item}>
                {item.hasChange ? (
                  <RedCheckMark />
                ) : (
                  <GreenCheckMark />
                )}
                <Typography sx={styles.itemText}>{item.label}</Typography>
              </Box>
            ))}
          </Box>
        </Grid>

        {/* Right Column */}
        <Grid item xs={6} sx={{pl: 2}}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="body2" color="text.secondary">
                3 Items were auto tailored
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" color="text.secondary">
                1 Item skipped
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MatchResults;