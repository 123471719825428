import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ProfilePhotoImage } from "../../assets/SVG/personal-details-profile.svg";
import { ReactComponent as PersonalSiteIcon } from "../../assets/SVG/personal-site-blue.svg";
import { ReactComponent as LinkedInIcon } from "../../assets/SVG/linkedIn-color.svg";
import { ReactComponent as GithubIcon } from "../../assets/SVG/github-icon.svg";
import { ReactComponent as CustomLink } from "../../assets/SVG/reddit-icon.svg";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { useSelector, useDispatch } from 'react-redux';
import { setExtractedData, setPersonalDetailsValidation } from '../../redux/actions';
import defaultProfilePicture from "./../../assets/newProfile.png";
import { Autocomplete, IconButton, Skeleton } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import CustomLinkEditor from './CustomLinkEditor';
import { v4 as uuidv4 } from 'uuid';
import { countries, countryStates } from '../../components/helpers/countries';
import { StateProps } from '../../redux/reducer';
import { isProfilePictureUploaded, validateEmail } from '../../components/common/CommonFunctions';
import debounce from 'lodash.debounce';
import { GrFormEdit } from "react-icons/gr";
import ZoomProfilePictureModal from '../../components/CommonComponents/ZoomProfilePictureModal';
import { aiShimmerStyle } from './WorkExperience/WorkExperienceModal';

const InputShimmer = () => {
  return (
  <Box>
    <Skeleton  variant="rounded" width={"100%"} animation="wave" height={"2.625rem"} sx={{...aiShimmerStyle}}/>
  </Box>
  )
}

const PersonalDetails = () => {
  const dispatch = useDispatch();
  const { extractedData, PersonalDetailsValidation: personalDetailsValidation, isPersonalDetailsLoading } = useSelector((state: StateProps) => state);
  const [showEditProfilePicModal,setShowEditProfilePicModal] = useState<boolean>(false);
  const [showAddresField,setShowAddressField] = useState<boolean>(extractedData?.location ? true : false);

  const [localData, setLocalData] = useState(extractedData);
  const [customLinks, setCustomLinks] = useState([
    {
      id: uuidv4(),
      name: "Reddit link(Custom)",
      isEdit: false
    }
  ]);
  const [selectedCountry, setSelectedCountry] = useState({ label: '', flag: '' });
  const [statesData, setStatesData] = useState<{ label: string, value: string }[]>([]);
  const [state, setState] = useState<{ label: string, value: string } | null>(null);
  const [errors, setErrors] = useState({
    fullName: "",
    email: "",
    designation:"",
  });

  useEffect(() => {
    setLocalData(extractedData);
  }, [extractedData]);

  useEffect(() => {
    if (localData?.country) {
      const existingCountry = countries?.find((country) => country?.label === localData?.country);
      if (existingCountry) {
        setSelectedCountry(existingCountry);
        const selectedCountryData = countryStates?.find?.((country) => country?.name?.toLowerCase() === localData?.country?.toLowerCase());
        if (selectedCountryData) {
          const statesData = selectedCountryData?.states?.map((state) => ({ label: state?.name, value: state?.name }));
          setStatesData(statesData?.length > 0 ? statesData : []);
        } else {
          setStatesData([]);
        }
      }
    }

    if (localData?.city_state) {
      setState({ label: localData?.city_state, value: localData?.city_state });
    }
  }, [localData]);

  useEffect(() => {
    const { fullName, email, designation } = localData;
    console.log(localData,"from Back")
    if (fullName) {
      setErrors((prev) => ({ ...prev, fullName: "" }));
    }
    if (email) {
      setErrors((prev) => ({ ...prev, email: "" }));
    }
    if (designation) {
      setErrors((prev) => ({ ...prev, designation: "" }));
    }
    if (fullName && email && validateEmail(email) && designation) {
      dispatch(setPersonalDetailsValidation({ ...personalDetailsValidation, isNextAllowed: true }));
    } else {
      dispatch(setPersonalDetailsValidation({ ...personalDetailsValidation, isNextAllowed: false }));
    }
  }, [localData]);

  useEffect(() => {
    if (personalDetailsValidation.runValidation) {
      const { fullName, email, designation } = localData;
      console.log({ fullName, email, designation },"from validation")
      if(!designation) {
        setErrors((prev) => ({ ...prev, designation: "Job Title is required" }));
      }
      if (!fullName) {
        setErrors((prev) => ({ ...prev, fullName: "Name is required" }));
      }
      if (!email) {
        setErrors((prev) => ({ ...prev, email: "Email is required" }));
      }
      if (email && !validateEmail(email)) {
        setErrors((prev) => ({ ...prev, email: "Please provide a valid email" }));
      }
    }
  }, [personalDetailsValidation]);

  useEffect(() => {
    if (!localData?.profilePicture) {
      setLocalData({ ...localData, profilePicture: defaultProfilePicture });
    }
  }, []);

  const setEditModalClose = () => {
    setShowEditProfilePicModal(false)
  };

  const handleCountryChange = (event: any, newValue: any) => {
    setSelectedCountry(newValue);
    setLocalData({ ...localData, country: newValue?.label, city_state: "" });
    setState(null);

    const selectedCountryData = countryStates?.find?.((country) => country?.name?.toLowerCase() === newValue?.label?.toLowerCase());
    if (selectedCountryData) {
      const statesData = selectedCountryData?.states?.map((state) => ({ label: state.name, value: state?.name }));
      setStatesData(statesData?.length > 0 ? statesData : []);
    } else {
      setStatesData([]);
    }
  };

  const handleStateChange = (e: any, newValue: any) => {
    setLocalData({ ...localData, city_state: newValue?.label });
    setState({ label: newValue?.label, value: newValue?.label });
  };

  const handleChange = (newValue: any, key: string) => {
    if (key === 'phone') {
        if (/^\+?\d*$/.test(newValue)) {
                setLocalData({ ...localData, phone: [newValue] });
        }
      return;
    }
    setLocalData({ ...localData, [key]: newValue });
  };

  const handleSocialLinkChange = (newValue: string, key: string) => {
    setLocalData({ ...localData, socialLinks: { ...localData.socialLinks, [key]: newValue } });
  };

  const handleImageChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader.result) {
          setLocalData({ ...localData, profilePicture: reader.result.toString() });
        } else {
          console.error('Failed to read file');
        }
      };
      reader.readAsDataURL(file);
      setShowEditProfilePicModal(true);
    }
  };

  const handleBoxClick = () => {
    const uploadElement = document?.getElementById("upload-photo") as HTMLInputElement;
    uploadElement?.click();
  };

  const handleLinkNameChange = (name: string, id: string) => {
    const updatedLinksList = customLinks?.map((link) => {
      if (link?.id === id) {
        return {
          ...link,
          isEdit: false,
          name,
        };
      }
      return link;
    });
    setCustomLinks(updatedLinksList);
  };

  const addCustomLink = () => {
    setCustomLinks((prev) => ([...prev, { name: `Custom link ${customLinks?.length + 1}`, isEdit: false, id: uuidv4() }]));
  };

  const deleteCustomLink = (id: string, linkName: string) => {
    const linksList = localData?.socialLinks;
    delete linksList?.[linkName];
    setLocalData({ ...localData, socialLinks: linksList });
    const updatedLinks = customLinks?.filter((link) => link.id !== id);
    setCustomLinks(updatedLinks);
  };

  const handleEditStart = (id: string) => {
    const updatedLinks = customLinks?.map((link) => {
      if (link.id === id) {
        return {
          ...link,
          isEdit: true,
        };
      }
      return link;
    });
    setCustomLinks(updatedLinks);
  };

  const debouncedUpdate = useCallback(
    debounce((updatedData) => {
      dispatch(setExtractedData(updatedData));
    }, 100),
    []
  );

  useEffect(() => {
    debouncedUpdate(localData);
  }, [localData, debouncedUpdate]);

  useEffect(() => {
    setErrors({
      fullName: "",
      email: "",
      designation: "",
    });
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setPersonalDetailsValidation({ ...personalDetailsValidation, runValidation: false, isNextAllowed: false }));
    };
  }, []);

  const validateField = (key: string, value: string) => {
    switch (key) {
      case 'fullName':
        if (!value) {
          setErrors((prev) => ({ ...prev, fullName: "Name is required" }));
        } else {
          setErrors((prev) => ({ ...prev, fullName: "" }));
        }
        break;
      case 'email':
        if (!value) {
          setErrors((prev) => ({ ...prev, email: "Email is required" }));
        } else if (!validateEmail(value)) {
          setErrors((prev) => ({ ...prev, email: "Please provide a valid email" }));
        } else {
          setErrors((prev) => ({ ...prev, email: "" }));
        }
        break;
      case 'designation':
        if (!value) {
          setErrors((prev) => ({ ...prev, designation: "Job Title is required" }));
        } else {
          setErrors((prev) => ({ ...prev, designation: "" }));
        }
        break;
      default:
        break;
    }
  };

  return (
    <Box className="personal-details-container">
      <Box className="personal-details-wrapper">
        <Box className="personal-details-content">
          <Box className="main-heading">
            <Box className="heading-box">
              <Typography variant="h5" color="color.primary" className="heading">
                Personal Details
              </Typography>
            </Box>
          </Box>

          <Box className="profile-info">
            <Box className="icon-cont" onClick={handleBoxClick}>
              <input
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                id="upload-photo"
                onChange={handleImageChange}
              />
              <label htmlFor="upload-photo">
                <Box className="icon-cont" style={{ cursor: 'pointer' }}>
                  {localData?.profilePicture || localData.profilePicture ? <img src={localData?.profilePicture} alt="Uploaded" style={{ width:"120px",height:"120px",maxWidth: "120px", maxHeight: "120px" }} /> : <ProfilePhotoImage />}
                </Box>
              </label>
              <Box className="text-wrapper">
                <Typography variant="body2" className="upload-photo-text">Upload Photo</Typography>
              </Box>
            </Box>
              {isProfilePictureUploaded(extractedData.profilePicture ?? "") && extractedData.profilePicture && <Box sx={{position:"absolute",left:"105px",top:"12px",border: "2px solid #fff",
              background: "linear-gradient(0deg, #f7f7f7, #f7f7f7)",
              borderRadius: "50%",
              padding:" 0.3rem",
              cursor: "pointer",
              display: "flex"
            }} 
                onClick={()=> setShowEditProfilePicModal((prev) => !prev)}>
                    <GrFormEdit color="#c1c1c1" size={"1rem"}/>
              </Box>}
          </Box>

          <Box className="profile-details">
          
          <Box className="input-box-container">
            <InputLabel htmlFor="fullName" className="personal-details-label">
              Name*
            </InputLabel>
            {!isPersonalDetailsLoading ? <TextField
              fullWidth
              id="fullName"
              value={localData?.fullName}
              onBlur={(e) => validateField('fullName', e.target.value)}
              onChange={(e) => handleChange(e.target.value, 'fullName')}
              placeholder="Doe"
              inputProps={{
                sx: {
                  "@media(min-height:910px)": {
                    fontSize: "18px",
                  }
                }
              }}
              error={!!errors.fullName}
              helperText={errors.fullName ? errors.fullName : ''}
            /> : <InputShimmer/>}
          </Box>
          <Box className="input-box-container">
              <InputLabel htmlFor="fullName" className="personal-details-label">
                Job Title*
              </InputLabel>
              {!isPersonalDetailsLoading ? <TextField
                fullWidth
                id="designation"
                value={localData?.designation}
                placeholder={"Software Engineer"}
                onChange={(e) => handleChange(e.target.value , 'designation')}
                inputProps={{
                  sx: {
                    "@media(min-height:910px)": {
                      fontSize: "18px",
                    }
                  }
                }}
                error={!!errors.designation}
                helperText={errors.designation ? errors.designation : ''}
              /> : <InputShimmer/>}
            </Box>

            <Box className="input-box-container">
              <InputLabel htmlFor="email" className="personal-details-label">
                Email*
              </InputLabel>
              {!isPersonalDetailsLoading ? <TextField
                fullWidth
                id="email"
                value={localData?.email}
                placeholder={"john@tekno.ai"}
                onBlur={(e) => validateField('email', e.target.value)}
                onChange={(e) => handleChange(e.target.value, 'email')}
                inputProps={{
                  sx: {
                    "@media(min-height:910px)": {
                      fontSize: "18px",
                    }
                  }
                }}
                error={!!errors.email}
                helperText={errors.email ? errors.email : ''}
              /> : <InputShimmer/>}
            </Box>

            <Box className="input-box-container">
              <InputLabel htmlFor="designation" className="personal-details-label">
                Phone
              </InputLabel>
              {!isPersonalDetailsLoading ? <TextField
                type="text"
                fullWidth
                id="designation"
                value={localData?.phone}
                onChange={(e) => handleChange(e.target.value, 'phone')}
                inputProps={{
                  sx: {
                    "@media(min-height:910px)": {
                      fontSize: "18px",
                    }
                  }
                }}
              /> : <InputShimmer/>}
            </Box>

            <Box className="input-box-container">
              <InputLabel htmlFor="designation" className="personal-details-label">
                Country
              </InputLabel>

              {!isPersonalDetailsLoading ? <Autocomplete
                options={countries}
                getOptionLabel={(option) => option.label}
                onChange={handleCountryChange}
                value={selectedCountry}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ display: 'flex', alignItems: 'center' }} {...props}>
                    <img
                      loading="lazy"
                      width="20"
                      src={option.flag}
                      alt=""
                      style={{ marginRight: '10px' }}
                    />
                    {option.label}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Country"
                    fullWidth
                    required
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: selectedCountry?.label ? (
                        <img
                          loading="lazy"
                          width="20"
                          src={selectedCountry.flag}
                          alt=""
                          style={{ marginRight: '10px' }}
                        />
                      ) : null,
                      sx: {
                        "@media(min-height:910px)": {
                          fontSize: "18px",
                        }
                      }
                    }}
                  />
                )}
              /> : <InputShimmer/>}
            </Box>

            <Box className="input-box-container">
              <InputLabel htmlFor="designation" className="personal-details-label">
                State/City
              </InputLabel>
              {!isPersonalDetailsLoading ? <Autocomplete
                multiple={false}
                fullWidth
                disabled={!localData?.country}
                options={statesData}
                value={state?.label ? state : null}
                onChange={handleStateChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select State"
                    inputProps={{
                      ...params.inputProps,
                      sx: {
                        "@media(min-height:910px)": {
                          fontSize: "18px",
                        }
                      }
                    }}
                  />
                )}
              /> : <InputShimmer/>}
            </Box>
          </Box>

          <Box className="address-container">
            {showAddresField ? <Box className="input-box-container">
              <InputLabel htmlFor="address" className="personal-details-label">
                Address
              </InputLabel>
              <TextField
                multiline
                rows={2}
                fullWidth
                id="address"
                value={localData?.location}
                onChange={(e) => handleChange(e.target.value, 'location')}
                inputProps={{
                  sx: {
                    "@media(min-height:910px)": {
                      fontSize: "18px",
                    }
                  }
                }}
              />
            </Box> : <Box sx={{cursor:"pointer"}}>
                 <Typography sx={{color:"#5594ff",fontWeight:"600",fontSize:"1.063rem",lineHeight:"1.43rem"}} onClick={()=> setShowAddressField(true)}>+ Add Full Address</Typography>
              </Box>}
          </Box>

          <Box className="links">
            <Typography variant="h5" color="color.primary" className="heading">Social Links</Typography>
            <Box className="input-box-container">
              <InputLabel htmlFor="personalSite" className="personal-details-label">
                Personal Site
              </InputLabel>
              <TextField
                fullWidth
                id="personalSite"
                placeholder="www.mysite.com"
                value={localData?.socialLinks?.personal_Website !== "NA" ? localData?.socialLinks?.personal_Website : ""}
                onChange={(e) => handleSocialLinkChange(e.target.value, 'personal_Website')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonalSiteIcon />
                    </InputAdornment>
                  ),
                  sx: {
                    "@media(min-height:910px)": {
                      fontSize: "18px",
                    }
                  }
                }}
              />
            </Box>

            <Box className="input-box-container">
              <InputLabel htmlFor="linkedIn" className="personal-details-label">
                LinkedIn
              </InputLabel>
              <TextField
                fullWidth
                id="linkedIn"
                placeholder="www.linkedin.com/username"
                value={localData?.socialLinks?.linkedIn_URL !== "NA" ? localData?.socialLinks?.linkedIn_URL : ""}
                onChange={(e) => handleSocialLinkChange(e.target.value, 'linkedIn_URL')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LinkedInIcon />
                    </InputAdornment>
                  ),
                  sx: {
                    "@media(min-height:910px)": {
                      fontSize: "18px",
                    }
                  }
                }}
              />
            </Box>

            <Box className="input-box-container">
              <InputLabel htmlFor="gitHub" className="personal-details-label">
                GitHub
              </InputLabel>
              <TextField
                fullWidth
                id="gitHub"
                placeholder="www.github.com/username"
                value={localData?.socialLinks?.github_URL !== "NA" ? localData?.socialLinks?.github_URL : ""}
                onChange={(e) => handleSocialLinkChange(e.target.value, 'github_URL')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <GithubIcon />
                    </InputAdornment>
                  ),
                  sx: {
                    "@media(min-height:910px)": {
                      fontSize: "18px",
                    }
                  }
                }}
              />
            </Box>

            {customLinks?.map((link, i) => (
              <Box className="input-box-container" key={link.id}>
                <CustomLinkEditor name={link.name} id={link.id} isEdit={link.isEdit} handleLinkNameChange={handleLinkNameChange} deleteCustomLink={deleteCustomLink} handleEditStart={handleEditStart} />
                <TextField
                  fullWidth
                  id={link.name}
                  placeholder="www.personalwebsite.com/username"
                  value={localData?.socialLinks?.[link.name]}
                  onChange={(e) => handleSocialLinkChange(e.target.value, link.name)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CustomLink />
                      </InputAdornment>
                    ),
                    sx: {
                      "@media(min-height:910px)": {
                        fontSize: "18px",
                      }
                    }
                  }}
                />
              </Box>
            ))}

            <Box className="add-link">
              <Typography variant="body2" className="link color-primary" sx={{ fontSize: "1.063rem", fontWeight: "600" }} onClick={addCustomLink}>+ Add Link</Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* {showEditProfilePicModal && extractedData?.profilePicture && <EditProfilePictureModal base64ImageUrl={extractedData?.profilePicture} onClose={setEditModalClose}/>} */}
      {showEditProfilePicModal && extractedData?.profilePicture && <ZoomProfilePictureModal base64ImageUrl={extractedData?.profilePicture} pictureModal={showEditProfilePicModal} setPictureModal={setShowEditProfilePicModal}/>}
    </Box>
  );
};

export default PersonalDetails;