import { forwardRef, useId } from "react";
import { Check } from "@mui/icons-material";

import "./tekno-toggle-btn.scss";

interface TeknoToggleBtnProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  checked: boolean;
}

const TeknoToggleBtn = forwardRef<HTMLInputElement, TeknoToggleBtnProps>(
  ({ label, checked, ...props }, ref) => {
    const id = useId();

    return (
      <div className="toggle-btn-container">
        <label className={checked ? "checked" : ""} htmlFor={id}>
          {label}
        </label>
        <input type="checkbox" id={id} ref={ref} value={label} {...props} />
        {checked && (
          <div className="check-mark">
            <Check />
          </div>
        )}
      </div>
    );
  }
);

TeknoToggleBtn.displayName = "TeknoToggleBtn";

export default TeknoToggleBtn;
