import { Alert, Box, Button, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, TextField, Typography } from "@mui/material";
import ResumeScoreProgressBar from "./ResumeScoreProgressBar";
import { useMemo, useState } from "react";
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import DoneIcon from '@mui/icons-material/Done';
import { useDispatch, useSelector } from "react-redux";
import { TiTick } from "react-icons/ti";
import { AddTask, TaskAlt } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { checkMissingKeywords, IResume, ITailoredResume, stripHtmlTags } from "../components/utils";
import { setExtractedData } from "../redux/actions";
import {ReactComponent as DiamondIcon} from "../assets/SVG/diamond-gradient.svg"
import { initialState } from "../redux/reducer";
import ProcessingPopup from "../components/ATSChecker/ProcessingPopup";
import SteppedDialog from "./SteppedDialog";
import { extractJobDetails, jobDescriptionATSChecker, withAuthHandling } from "../services/apiService";

const TailoredResumeSuggestions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let extractedData = useSelector((state:any) => state.extractedData);

  const [showDetails, setShowDetails] = useState(false);

/*   extractedData = {...extractedData,
    tailoredResumeObject: {
      "resumeObjectWithChanges": {
          "careerObjective": "Experienced software professional with expertise in Agile methodologies, project management, and data science. Seeking to contribute leadership and technical skills to a dynamic team in the field of Data Engineering.",
          "designation": "Data Engineer",
          "toolsTechnicalSkills": "Python (Django), Javascript (NodeJS, ReactJS, jQuery), SQL (MySQL, PostgreSQL, NoSQL), HTML5/CSS, AWS, Unix, Git, Informatica PowerCenter, Shell Scripting",
          "programmingLanguages": "Python, Javascript, SQL, Shell Scripting, Informatica PowerCenter"
      },
      "atsScore": 60,
      "keywordsToAdd": [
          "ETL",
          "Data Warehousing",
          "Data Modeling",
          "Database Design",
          "Data Pipeline",
          "Data Integration",
          "Data Migration",
          "Informatica PowerCenter",
          "Informatica Mappings",
          "Data Quality",
          "Data Solutions",
      ],
      "missingKeywords": {
        "careerObjective": [
            "Informatica PowerCenter",
            "ETL",
            "Data Warehousing",
            "Data Integration",
            "Data Migration",
            "Data Modeling",
            "Database Design",
            "Shell Scripting"
        ],
        "workExperience": [
            "Informatica PowerCenter",
            "ETL",
            "Data Warehousing",
            "Data Integration",
            "Data Migration",
            "Data Modeling",
            "Database Design",
            "Shell Scripting"
        ],
        "projects": [
            "Informatica PowerCenter",
            "ETL",
            "Data Warehousing",
            "Data Integration",
            "Data Migration",
            "Data Modeling",
            "Database Design",
            "Shell Scripting"
        ]
      },
      "designation": "Data Engineer",
      "companyName": "Accenture"
    }
  }; */
  
  const isTailoredResumeObjectEmpty = !extractedData.tailoredResumeObject || 
  (!extractedData.tailoredResumeObject.designation && !extractedData.tailoredResumeObject.companyName);
  const { tailoredResumeObject } = extractedData;

  const missingKeywords = useMemo(() => {
    if(!extractedData.tailoredResumeObject || (extractedData.careerObjective === "" || extractedData.workExperiences === undefined || extractedData.projects === undefined))
      return {
        careerObjective: [],
        workExperience: [],
        projects: []
      };
    const missingCareerObjectiveKeywords = checkMissingKeywords(extractedData.careerObjective, "careerObjective", extractedData.tailoredResumeObject?.keywordsToAdd);
    const missingWorkExperienceKeywords = checkMissingKeywords(extractedData.workExperiences, "workExperiences", extractedData.tailoredResumeObject?.keywordsToAdd);
    const missingProjectsKeywords = checkMissingKeywords(extractedData.projects, "projects", extractedData.tailoredResumeObject?.keywordsToAdd);
    return {
      careerObjective: missingCareerObjectiveKeywords,
      workExperience: missingWorkExperienceKeywords,
      projects: missingProjectsKeywords
    }
  }, [extractedData]);

  const handleToggleDetails = () => {
    setShowDetails((prev) => !prev);
  };

  const { designation = "", companyName = "" } = extractedData.tailoredResumeObject || {};
  
  const handleNavigateToSection = (section: string) => {
    if(section === "careerObjective") {
      navigate(`/create-resume/${extractedData.id}/summary`);
    }
    else if (section === "workExperiences") {
      navigate(`/create-resume/${extractedData.id}/work-experience`);
    }
  }

  const getColor = (isMatched: boolean) => {
    return isMatched ? "rgb(237, 247, 237)" : "rgb(255, 244, 229);";
  };

  // Tailor Resume Dialog

  const [dialogState, setDialogState] = useState({
    open: false,
    jobUrl: '',
    jobDetails: null,
    tailoredResumeObject: {} as ITailoredResume,
    loading: false
  });

  const [popupDetails, setPopupDetails] = useState({
    open: false,
    title: "",
    subTitle: "",
    progress: 0,
    page: 0,
    resumeObject: {} as IResume
  });

  const fetchWithAuthHandling = withAuthHandling(navigate);

  const handleCloseDialog = () => {
    setDialogState({
      open: false,
      jobUrl: '',
      jobDetails: null,
      tailoredResumeObject: {} as ITailoredResume,
      loading: false
    });
  };

  const handleOpenDialog = () => {
    // setDialogState((prevState) => ({ ...prevState, open: true }));
    // const cleanedResume = removeProperties(resumeObject, ['profilePicture', 'screenshot']);
    setPopupDetails((prevState) => ({
      ...prevState,
      open: true,
      title: "Resume Added Successfully",
      subTitle: "In the next step, you can enter the job details to tailor this resume.",
      progress: 100,
      page: 2,
      resumeObject: {} as IResume
    }));
  };

  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDialogState((prevState) => ({ ...prevState, jobUrl: e.target.value }));
  };

  const handleExtractJobDetails = async () => {
    setDialogState((prevState) => ({ ...prevState, loading: true }));
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_NODEJS_API_URL}/job-scraper`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ url: dialogState.jobUrl, resumeObject: extractedData }),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      setDialogState((prevState) => ({ 
        ...prevState,
        jobDetails: data.jobScraperResult,
        tailoredResumeObject: {
          ...data.tailoredResumeObject,
          designation: data.jobScraperResult?.jobTitle ? data.jobScraperResult?.jobTitle : "",
          companyName: data.jobScraperResult?.companyName ? data.jobScraperResult?.companyName : "",
        } 
      }));
    } catch (error) {
      console.error('Error extracting job details:', error);
    } finally {
      setDialogState((prevState) => ({ ...prevState, loading: false }));
    }
  };

  const improveResume = ()  => {
    const updatedExtractedData = {...extractedData, tailoredResumeObject: dialogState.tailoredResumeObject};
    
    const careerObjectiveKeywords = checkMissingKeywords(updatedExtractedData.careerObjective, 'careerObjective', dialogState.tailoredResumeObject?.keywordsToAdd);
    const workExperienceKeywords = Array.from(
      new Set(
        updatedExtractedData.workExperiences.flatMap((experience: any) =>
          checkMissingKeywords(experience.keyResponsibilities, 'workExperience', dialogState.tailoredResumeObject?.keywordsToAdd)
        )
      )
    );

    const projectKeywords = Array.from(
      new Set(
        updatedExtractedData.projects.flatMap((project: any) =>
          checkMissingKeywords(project.description, 'projects', dialogState.tailoredResumeObject?.keywordsToAdd)
        )
      )
    );

    const missingKeywords = {
      careerObjective: careerObjectiveKeywords,
      workExperience: workExperienceKeywords,
      projects: projectKeywords,
    };
    
    updatedExtractedData.tailoredResumeObject = {
      ...updatedExtractedData.tailoredResumeObject,
      missingKeywords,
    };


    dispatch(setExtractedData(updatedExtractedData));
    handleCloseDialog();
    handleToggleDetails();
  }

  const handleFetchJobDetails = async (input: string, inputType: 'url' | 'description') => {
    if (inputType === 'url') {
      return await extractJobDetails(input, fetchWithAuthHandling);
    } else {
      return { jobDescription: input };
    }
  };

  const handleMatchJobDescription = async (jobDetails: any, resumeObject: any) => {
    const data = await jobDescriptionATSChecker(
      jobDetails.jobDescription ? jobDetails.jobDescription : jobDetails,
      resumeObject,
      fetchWithAuthHandling
    );
    return data;
  }

  // Tailor Resume Dialog

  return (
    <Box>
        {
        isTailoredResumeObjectEmpty ?
        <Box sx={{margin:"1rem"}}>
            <Alert severity="info" icon={false} sx={{width:"100%",fontSize:"16px",padding:"0 1rem",borderRadius:"10px",
            '&.MuiAlert-standardInfo': {
            background: "linear-gradient(to right, #EAF0FF, #FFF3F9)" // Custom background color for info alerts
          },
            }}>
              <Box sx={{display:"flex",alignItems:"center",gap:"0.5rem"}}>
              <DiamondIcon/>
              <Typography sx={{flex:1}}>Customize your resume for a specific job to improve your chances.</Typography>
              <Link onClick={handleOpenDialog} sx={{fontWeight:"600"}}>Let's go</Link>
              </Box>
          </Alert>
        </Box>
          :
          <Box>
            <Alert severity="info" onClick={handleToggleDetails} icon={<TaskAlt/>} sx={{width:"100%",fontSize:"16px",padding:"0 1rem",borderRadius:"10px",
              '&.MuiAlert-standardInfo': {
              background: "linear-gradient(to right, #EAF0FF, #FFF3F9)" // Custom background color for info alerts
            },
              }}>
              <Box>
                {designation !== "" && companyName !== "" && `${designation} at ${companyName}`} <u>Improve Resume</u>
              </Box>
            </Alert>
            {showDetails && (
              <Box>
                <List sx={{p: 0}}>
                  {missingKeywords.careerObjective && (
                    <ListItem
                      disablePadding
                      sx={{
                        backgroundColor: getColor((tailoredResumeObject.keywordsToAdd.length - missingKeywords.careerObjective.length) === tailoredResumeObject.keywordsToAdd.length),
                      }}
                    >
                      <ListItemButton>
                        <ListItemIcon>
                          <AddTask />
                        </ListItemIcon>
                        <ListItemText
                          primary={`Add career objective keywords: ${tailoredResumeObject.keywordsToAdd.length - missingKeywords.careerObjective.length} / ${tailoredResumeObject.keywordsToAdd.length}`}
                          onClick={() => handleNavigateToSection("careerObjective")}
                        />
                      </ListItemButton>
                    </ListItem>
                  )}
                  {missingKeywords.workExperience.length > 0 && (
                    <ListItem
                      disablePadding
                      sx={{
                        backgroundColor: getColor((tailoredResumeObject.keywordsToAdd.length - missingKeywords.workExperience.length) === tailoredResumeObject.keywordsToAdd.length),
                      }}
                    >
                      <ListItemButton>
                        <ListItemIcon>
                          <AddTask />
                        </ListItemIcon>
                        <ListItemText
                          primary={`Add work experience keywords: ${tailoredResumeObject.keywordsToAdd.length - missingKeywords.workExperience.length} / ${tailoredResumeObject.keywordsToAdd.length}`}
                          onClick={() => handleNavigateToSection("workExperiences")}
                        />
                      </ListItemButton>
                    </ListItem>
                  )}
                  {/* {missingKeywords.projects.length > 0 && (
                    <ListItem disablePadding>
                      <ListItemButton>
                        <ListItemIcon>
                          <AddTask />
                        </ListItemIcon>
                        <ListItemText primary={`Add project keywords: ${keywordsToAdd.length - missingKeywords.projects.length} / ${keywordsToAdd.length}`} />
                      </ListItemButton>
                    </ListItem>
                  )} */}
                </List>
              </Box>
            )}
          </Box>
        }

      <SteppedDialog
        open={popupDetails.open}
        onClose={() => setPopupDetails((prevState) => ({ ...prevState, open: false }))}
        onFetchJobDetails={handleFetchJobDetails}
        onMatchJobDescription={handleMatchJobDescription}
        resumeObject={popupDetails.resumeObject}
      />

      {/* <Dialog open={dialogState.open} onClose={handleCloseDialog} fullWidth maxWidth="md">
        <DialogTitle>Tailor Resume</DialogTitle>
        <DialogContent>
          {dialogState.jobDetails === null && 
            <>
              <TextField
                placeholder="Job URL"
                value={dialogState.jobUrl}
                onChange={handleUrlChange}
                fullWidth
                margin="normal"
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleExtractJobDetails}
                disabled={dialogState.loading}
              >
              {dialogState.loading ? 'Extracting...' : 'Extract Job Details'}
              </Button>
            </>
          }
          {dialogState.loading && (
            <Box display="flex" justifyContent="center" mt={2}>
              <CircularProgress />
            </Box>
          )}
          {dialogState.jobDetails && (
            <>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Extracted Job Details
                </Typography>
                <Grid container>
                  {Object.entries(dialogState.jobDetails).map(([key, value]) => (
                    <Grid item xs={12} key={key}>
                      <Box sx={{ mt: 2, p: 1, border: '1px solid #E2E8F0', borderRadius: 2, backgroundColor: '#f9f9f9' }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#333' }}>
                          {key}
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#555' }} dangerouslySetInnerHTML={{ __html: value as string }} />
                      </Box>
                    </Grid>
                  ))}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={improveResume}
                    disabled={dialogState.loading}
                  >
                  Improve Resume
                  </Button>
                </Grid>
              </CardContent>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} disabled={dialogState.loading}>Cancel</Button>
        </DialogActions>
      </Dialog> */}
    </Box>
  )
}
export default TailoredResumeSuggestions;