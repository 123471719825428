import { Box, Step, StepLabel, Stepper, StepIconProps, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ChevronRight } from '@mui/icons-material';
import DoneIcon from '@mui/icons-material/Done';
import { styled } from '@mui/system';
import { useSelector } from "react-redux";
import { StateProps } from "../redux/reducer";
import cn from "classnames";

const steps:{key:string,value:number}[] = [
   { key:'personal-details',value:0},
   { key:'work-experience',value:1},
   { key:'education',value:2},
    {key:'skills',value:3},
    {key:'summary',value:4},
  ];

const stepLabels = [
    "Personal",
    "Experience",
    "Education",
    "Skills",
    "Summary",
]

// Custom StepIcon styles
export const CustomStepIconStyle = styled('div')<{ active: boolean; completed: boolean }>(({ theme, active, completed }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 14,  // Custom width for step icon
  height: 14, // Custom height for step icon
  borderRadius: '50%',
  backgroundColor: completed ? '#3e7ee8' : '#fff', // Background color based on state
  color: active ? '#3e7ee8' : "black", // Text color
  fontSize: '8px', // Adjust step number font size
  border: active ? '1px solid #3e7ee8' : completed ? "none" : '1px solid grey', 
}));

// Define TypeScript types for the CustomStepIcon props
interface CustomStepIconProps extends StepIconProps {
  active?: boolean;
  completed?: boolean;
  icon: React.ReactNode;
}

const CustomStepIcon = (props:CustomStepIconProps) => {
  const { active=false, completed, className } = props;
  return (
    <CustomStepIconStyle active={active} completed={completed ? completed : false} className={className}>
      {completed ? <DoneIcon sx={{fontSize:"0.75rem",color:"#fff"}}/> : props.icon}
    </CustomStepIconStyle>
  );
};

const ResumeProgressStepper = () => {
  const drawerState = useSelector((state:StateProps)=> state.sideDrawer);


    const [activeStep,setActiveStep] = useState(0);
    const location = useLocation();
    useEffect(()=>{
        if(location) {
            steps.forEach(({key,value}) => {
                if(location.pathname.includes(key)) {
                    setActiveStep(value);
                }
            })
        }
    },[location])

    const CustomConnector = () => {
      return (
        <Box sx={drawerState
            ? {width:{
          xs:"0.1rem",
          sm:"0.1rem",
          md:"0.1rem",
          lg:"0.1rem",
          xl:"0.5rem",
        },
        padding:"0 0.5rem",
        "@media (min-height: 500px) and (max-height:730px)":{
          padding:0
      },
      "@media (min-height: 1070px)":{
        padding:"0 0.75rem"
      },
        display:"flex",justifyContent:"center",alignItems:"center"}
        :{width:{
          xs:"0rem",
          sm:"0rem",
          md:"0rem",
          lg:"0rem",
          xl:"0.5rem",
        },
        padding:"0 0.5rem",
          "@media (min-height: 500px) and (max-height:730px)":{
            padding:0
        },
        "@media (min-height: 1070px)":{
          padding:"0 0.75rem"
        },
        display:"flex",justifyContent:"center",alignItems:"center"}}>
          <ChevronRight sx={{fontSize:"1rem",color:"grey"}}/>
        </Box>
      )
    }

   return (
    <Box className="stepper-container" sx={{padding:"0.75rem 1rem"}}>
    <Box className="stepper-wrapper" sx={{ width: '100%' }}>
    <Stepper className="stepper" activeStep={activeStep} nonLinear  connector={<CustomConnector/>}>
      {stepLabels.map((step,index) => {

       return <Step className="step" key={step}>
          <StepLabel
          color="inherit" StepIconComponent={(props) => <CustomStepIcon {...props} completed={index < activeStep ? true : false}/>}
          //  active={activeStep === index ? true : false} 
          className="step-icon"
          sx={drawerState ? {fontSize:"1.5rem"} : {fontSize:"2rem"}}
          >
            <Typography className={cn({"step-text":activeStep!==index,"step-text_active":activeStep===index})} 
            sx={{
              marginLeft:"0.25rem",
             fontSize:drawerState ? 
             {
              xs:"12px !important",
              sm:"12px !important",
              md:"12px !important",
              lg:"12px !important",
              xl:"16px !important",
            }
            :{
              md:"12px !important",
              lg:"16px !important",
              xl:"16px !important"
            }
            }}
            >{step}</Typography></StepLabel>
        </Step>
})}
    </Stepper>
  </Box>
    </Box>
   )
}

export default ResumeProgressStepper;