import { NavigateFunction } from "react-router-dom";
import { flattenExtractedData, IResume } from "../components/utils";
import { setIsPersonalDetailsLoading, setLoggedInUserData, showSnackbar } from "../redux/actions";
import { initialState } from "../redux/reducer";
import Cookies from "js-cookie";
import { saveToken } from "../components/auth/storeToken";
import { Dispatch } from "react";
import { UnknownAction } from "redux";

export const fetchWithAuth = async (
  url: string,
  options: RequestInit = {}
): Promise<Response> => {
  const accessToken = localStorage.getItem("accessToken");
  const updatedOptions: RequestInit = {
    ...options,
    headers: {
      ...options.headers,
      Authorization: `Bearer ${accessToken}`,
    },
  };

  let response = await fetch(
    `${process.env.REACT_APP_BACKEND_DOTNET_API_URL}${url}`,
    updatedOptions
  );

  if (response.status === 401) {
    throw new Error("Access token expired");
    // Access token expired, try to refresh it
    /* const refreshResponse = await fetch(`${process.env.REACT_APP_BACKEND_DOTNET_API_URL}/refresh-token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include', // Include cookies in the request
    });

    if (refreshResponse.ok) {
      const refreshData = await refreshResponse.json();
      localStorage.setItem('accessToken', refreshData.token);
      // Retry the original request with the new token
      updatedOptions.headers = {
        ...updatedOptions.headers,
        'Authorization': `Bearer ${refreshData.token}`,
      };
      response = await fetch(`${process.env.REACT_APP_BACKEND_DOTNET_API_URL}${url}`, updatedOptions);
    } else {
      // Refresh token failed, handle logout
      console.error('Refresh token failed');
      throw new Error('Refresh token failed');
    } */
  }

  return response;
};

export const withAuthHandling =
  (navigate: NavigateFunction) =>
  async (url: string, options: RequestInit = {}) => {
    try {
      return await fetchWithAuth(url, options);
    } catch (error: any) {
      if (
        error.message === "Access token expired" /* 'Refresh token failed' */
      ) {
        navigate("/login"); // Redirect to login page
      }
      throw error;
    }
  };

export const extractFaceFromPDF = async (base64string: string) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_NODEJS_API_URL}/extract-face`, {
      method: "POST",
      credentials: 'include', // Keep credentials
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ pdfBase64: base64string }),
    });
    const result = await response.json();
    if (response.ok) {
      return result.faceBase64;
    }
    return null;
  } catch (error) {
    console.error("Error converting PDF:", error);
  }
};

export const extractText = async (
  formData: FormData,
  fetchWithAuthHandling: (
    url: string,
    options?: RequestInit
  ) => Promise<Response>
) => {
  const response = await fetchWithAuthHandling("/extract-text", {
    method: "POST",
    body: formData,
  });

  if (!response.ok) {
    throw new Error("Failed to extract text");
  }

  return response.json();
};

export const generateContent = async (
  textContent: string,
  fetchWithAuthHandling: (
    url: string,
    options?: RequestInit
  ) => Promise<Response>,
  dispatch: any
) => {
  const requestData = { content: textContent };
  try {
    const response = await fetchWithAuthHandling("/get-generate-content", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    if (!response.ok) {
      throw new Error("Failed to generate content");
    }

    return response.json();
  } catch (error) {
    dispatch(
      showSnackbar("Failed to generate content. Please try again.", "error")
    );
    throw error;
  }
};

export const generateContentForSinglePageResume = async (
  resumeObject: IResume,
  dispatch: any
) => {
  // Destructure the resumeObject to exclude unwanted properties
  const {
    id,
    title,
    name,
    profilePicture,
    email,
    phone,
    location,
    screenshot,
    tailoredResumeObject,
    templateId,
    socialLinks,
    modifiedDate,
    createdDate,
    country,
    city_state,
    educationalDetails,
    ...filteredResumeObject
  } = resumeObject;

  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_NODEJS_API_URL}/convert-resume-to-single-page`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ resumeObject: filteredResumeObject }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to generate content");
    }

    return response.json();
  } catch (error) {
    dispatch(
      showSnackbar("Failed to generate content. Please try again.", "error")
    );
    throw error;
  }
};

export const saveResumeData = async (extractedData: any, fetchWithAuthHandling: (url: string, options?: RequestInit) => Promise<Response>) => {
  try {
    // Update the existing resume
    await fetchWithAuthHandling(`/update-resume?id=${extractedData.id}`, {
      method: 'PUT',
      headers: {
        'accept': '*/*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...extractedData,
        customSections: [],
      }),
    });
    console.log('Resume updated successfully');
  } catch (error) {
    console.error('Error saving data', error);
  }
};

export const addNewResume = async (extractedData: any, fetchWithAuthHandling: (url: string, options?: RequestInit) => Promise<Response>) => {
  try {
    // Ensure extractedData.phone is an array of strings
    if (typeof extractedData.phone === 'string') {
      extractedData.phone = [extractedData.phone];
    }
    
    const addedResume = await fetchWithAuthHandling('/add-resume', {
      method: 'POST',
      headers: {
        'accept': '*/*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...extractedData,
        tailoredResumeObject: {
          resumeObjectWithChanges: "",
          atsScore: 0,
          keywordsToAdd: [],
          missingKeywords: [],
          designation: "",
          companyName: "",
        },
        customSections: []
      }),
    });
    const addedResumeData = await addedResume.json();
    return addedResumeData;
  } catch (error) {
    console.error('Error adding new resume', error);
  }
};

export const deleteResume = async (
  resumeId: string,
  fetchWithAuthHandling: (
    url: string,
    options?: RequestInit
  ) => Promise<Response>
) => {
  try {
    const response = await fetchWithAuthHandling(`/delete-resume/${resumeId}`, {
      method: "DELETE",
    });
    return response.json();
  } catch (error) {
    console.error("Error deleting resume", error);
  }
};

export const fetchResumes = async (
  fetchWithAuthHandling: (
    url: string,
    options?: RequestInit
  ) => Promise<Response>
) => {
  const response = await fetchWithAuthHandling("/get-resumes", {
    method: "GET",
  });

  if (!response.ok) {
    throw new Error("Failed to fetch resumes");
  }

  const data = await response.json();
  const sortedData = data.sort(
    (a: IResume, b: IResume) =>
      new Date(b.modifiedDate).getTime() - new Date(a.modifiedDate).getTime()
  );
  return sortedData;
};

export const fetchResumeById = async (
  resumeId: string,
  fetchWithAuthHandling: (
    url: string,
    options?: RequestInit
  ) => Promise<Response>,
  dispatch:Dispatch<UnknownAction>,
) => {
  try {
     dispatch(setIsPersonalDetailsLoading(true));
    const response = await fetchWithAuthHandling(`/get-resume?id=${resumeId}`, {
      method: "GET",
    });

    if (!response.ok) {
      throw new Error("Failed to fetch resume");
    }

    return response.json();
  } catch (error) {
    console.error("Error fetching resume", error);
    throw error;
  }
  finally {
     console.log("finally,APi Call")
     dispatch(setIsPersonalDetailsLoading(false));
  }
};

export const getUserProfile = async (
  fetchWithAuthHandling: (
    url: string,
    options?: RequestInit
  ) => Promise<Response>
) => {
  const response = await fetchWithAuthHandling("/user-profile", {
    method: "GET",
  });

  if (!response.ok) {
    throw new Error("Failed to fetch resumes");
  }

  return response.json();
};

export const processWord = async (extractedData: any) => {
  const payload = {
    data: {
      ...flattenExtractedData(extractedData),
    },
  };

  const serverResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_NODEJS_API_URL}/process-word`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );

  if (!serverResponse.ok) {
    throw new Error("Failed to process the document");
  }
  return serverResponse;
};

export const extractJobDetails = async (
  jobUrl: string,
  fetchWithAuthHandling: (
    url: string,
    options?: RequestInit
  ) => Promise<Response>
) => {
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_NODEJS_API_URL}/job-scraper`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ url: jobUrl }),
    }
  );

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return response.json();
};

export const jobDescriptionATSChecker = async (
  jobDetails: any,
  resumeObject: any,
  fetchWithAuthHandling: (
    url: string,
    options?: RequestInit
  ) => Promise<Response>
) => {
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_NODEJS_API_URL}/job-description-ats-checker`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        jobDetails,
        resumeObject,
      }),
    }
  );

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return response.json();
};

export const handleLogout = async (navigate: NavigateFunction) => {
  try {
    /* const response = await fetch('https://testapi.tekno.ai/api/logout', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      'credentials': 'include',
    });

    if (!response.ok) {
      throw new Error('Failed to logout');
    } */

    // Perform any necessary cleanup actions
    localStorage.removeItem("accessToken");
    navigate("/login");
  } catch (error) {
    console.error("Error logging out:", error);
  }
};

export const getHelpWithWriting = async (
  sectionToGenerate: string,
  resumeObject: IResume,
  dispatch: any
) => {
  // Exclude unwanted properties from resumeObject
  const {
    name,
    profilePicture,
    email,
    phone,
    location,
    tailoredResumeObject,
    socialLinks,
    templateId,
    screenshot,
    modifiedDate,
    createdDate,
    country,
    city_state,
    ...filteredResumeObject
  } = resumeObject;

  const requestData = { sectionToGenerate, resumeObject: filteredResumeObject };

  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_NODEJS_API_URL}/get-help-with-writing`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to get help with writing");
    }

    const data = await response.json();
    return data;
  } catch (error: any) {
    console.error("Error getting help with writing:", error);
    dispatch(
      showSnackbar(error.message || "Failed to get help with writing.", "error")
    );
    throw error;
  }
};
