import { Box, Button, FormControl, FormHelperText, InputLabel, List, ListItem, MenuItem, Modal, Select, TextField, Typography } from "@mui/material";
import { summarSectionModalstyle } from "../Summary";
import ReactQuill from "react-quill";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {ReactComponent as AiIcon} from "../../../assets/SVG/ai-old.svg";
import {ReactComponent as WhiteAiIcon} from "../../../assets/SVG/ai-description-small-icon.svg";
import {ReactComponent as StartAiIcon} from "../../../assets/SVG/ai-description-icon-new.svg";
import {ReactComponent as RegenerateIcon} from "../../../assets/SVG/referesh-icon.svg";
import {ReactComponent as AddAiIcon} from "../../../assets/SVG/add-ai-suggestion.svg";
import {ReactComponent as DoneAiIcon} from "../../../assets/SVG/done-ai-suggestion.svg";
import { useSelector } from "react-redux";
import { IResume, WorkExperienceTypes } from "../../../components/utils";
import { cleanSummaryHTMLstring } from "../../../components/common/CommonFunctions";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {ReactComponent as DatePickerIcon} from "../../../assets/SVG/date-picker-small.svg";
import { quillModules } from "../../../App";
import classNames from "classnames";
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from '@mui/material/Skeleton';
import { keyframes } from '@emotion/react';
import {ReactComponent as CancelModalIcon} from "../../../assets/SVG/cancel-modal.svg";

const jobDetails =  {
    "startDate": "January 2015",
    "endDate": "current",
    "company": "Embark",
    "jobType": null,
    "role": "Software Engineer",
    "location": null,
    "isEdit": null,
    "isNewEntry": null,
    "index": "0d7fd69f-393c-4310-8830-778f583715ec",
    "keyResponsibilities": "Worked with product managers to re-architect a multi-page web app into a single page web-app, boosting yearly revenue by $1.4M\nConstructed the logic for a streamlined ad-serving platform that scaled to our 35M users, which improved the page speed by 15% after implementation\nT ested software for bugs and operating speed, ﬁxing bugs and documenting processes to increase efﬁciency by 18%\nIterated platform for college admissions, collaborating with a group of 4 engineers to create features across the software",
    "projectDetails": "NA",
    "toolsTechnologies": "NA"
};

interface WorkExperienceSummaryModalProps {
    workExpModal:boolean;
    setWorkExpModal:Dispatch<SetStateAction<boolean>>;
    formData:WorkExperienceTypes;
    setFormData:Dispatch<SetStateAction<WorkExperienceTypes>>;
    handleChange: (newValue: any, key: string) => void;
    handleStartDateChange: (newValue: Date | null) => void;
    handleEndDateChange: (newValue: Date | null) => void;
    errors: { [key: string]:string; };
    handleStartDateError:(error:any)=>void;
    handleEndDateError:(error:any)=>void;
}

const StyledDatePickerIcon = () => (
    <DatePickerIcon style={{ width:"1.5rem",height:"1.5rem" }} /> // Customize as needed
  );

  const pulse = keyframes`
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
`;

  export const aiShimmerStyle= {
        background: `linear-gradient(90deg, #f7f7f7 25%, #f3f3f3 50%, #f7f7f7 75%)`,
        backgroundSize: '200% 100%',
        animation: `${pulse} 4s infinite`,
      }
  


const WorkExperienceModal:React.FC<WorkExperienceSummaryModalProps> = ({workExpModal,setWorkExpModal,formData,setFormData,handleChange,handleStartDateChange,handleEndDateChange,errors,handleStartDateError,handleEndDateError}) => {
    const extractedData = useSelector((state:IResume) => state.extractedData);
    const [workExpDetails,setWorkExpDetails] = useState("");
    const [isCursorActive, setIsCursorActive] = useState(false);
    const [loader,setLoader] = useState<boolean>(false);
    const [aiList,setAiList] = useState<{isAdded:boolean,text:string}[]>([])

      const getWorkExpAiData = async () => {
        setAiList([]);
        setLoader(true);
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_NODEJS_API_URL}/get-help-with-writing`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({sectionToGenerate:"KeyResponsibilitiesArray",jobDetails:extractedData?.workExperiences?.[0] ?? {},resumeObject:extractedData }),
          });
    
          if (response.ok) {
            const data = await response.json();
            const listData = data?.response?.map((detail:any)=>({isAdded:false,text:detail}));
              setAiList(listData)
              setLoader(false);
          } else if (response.status === 401) {
            console.error('Login failed: Invalid credentials');
          } else {
            const errorData = await response.json();
            console.error('Login failed:', errorData.message);
          }
        } catch (error) {
          console.error('Error during login:', error);
        }
        finally{
            setLoader(false);
        }
      };

    useEffect(()=>{
        if(workExpModal) {
            //   getWorkExpAiData();
        }
    },[workExpModal])

     /**
     * Below useEffect is to set the modalsummary value initially
    */
     useEffect(()=>{
        setIsCursorActive(false);
        if(formData?.keyResponsibilities && workExpModal) {
            const cleansummary = cleanSummaryHTMLstring(formData.keyResponsibilities);
            setWorkExpDetails(cleansummary);
        }
    },[workExpModal]);

    const handleConfirmAndContinue = () => {
        const cleansummary = cleanSummaryHTMLstring(workExpDetails); 
        setFormData((prev)=>({...prev, keyResponsibilities:cleansummary}));
        setWorkExpModal(false);
    }

    const handleDescriptionChange = (e:any) => {
        /**
         * Checking whether Ai Added modalSummary Changed or not
         * This handle chnage runs every time when there is a change in react quill state
        */
       if(isCursorActive){
        const updatedAiList = aiList.map((listItem)=>{
            if(listItem.isAdded === true && !e.includes(listItem.text)){
                return {...listItem, isAdded: false}
            }
            else if(listItem.isAdded === false && e.includes(listItem.text)){
                return {...listItem, isAdded: true}
            }
            else {
                return listItem;
            }
           })
           setAiList(updatedAiList);
           setWorkExpDetails(e);
       }
    //    else {
    //     const cleansummary = cleanSummaryHTMLstring(e);
    //     setWorkExpDetails(cleansummary);
    //    }
    }

    const handleTextClick = (text:string) => {
        if(!text) return;
        const upDatedList = aiList?.map((listItem)=> {
            if(listItem.text === text){
                return {...listItem, isAdded: true};
            }
            return listItem;
        })
        setAiList(upDatedList);
        const cleansummary = cleanSummaryHTMLstring(workExpDetails)
        setWorkExpDetails(cleansummary+`<ul><li>${text}</li></ul>`);
    }

      // Handle focus event
        const handleFocus = () => {
            setIsCursorActive(true);
        };

        // Handle blur event
        const handleBlur = () => {
            setIsCursorActive(false);
        };
        
    return (
        <Modal
        open={workExpModal}
        onClose={()=>setWorkExpModal(false)}
        className="work-experience-modal-container"
        >
            <Box sx={summarSectionModalstyle} className="modal-wrapper">
                <Box className="modal-heading">
                    <Typography className="heading-text" variant="h5" component="h5">
                        Job Details
                    </Typography>
                    <Box onClick={()=>setWorkExpModal(false)} sx={{cursor:"pointer"}}>
                      <CancelModalIcon/>
                    </Box>
                </Box>

                <Box className="job-details-content">
                <Box className="input-box-container">
                <InputLabel htmlFor="company">Job Title*</InputLabel>
                <TextField
                    fullWidth
                    id="company"
                    name="company"
                    placeholder="Ex: Microsoft Corporation"
                    value={formData?.role}
                    onChange={(e) => handleChange(e.target.value, 'role')}
                    // error={!!errors.role}
                    // helperText={errors.role ? errors.role : ''}
                    InputProps={{
                        sx: {
                          height: "2.25rem",
                        },
                      }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          minHeight: "2.25rem",
                        },
                        borderRadius: "7px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "7px",
                        },
                      }}
                />
                </Box>

                <Box className="input-box-container">
                        <InputLabel htmlFor="role">
                            Job type
                        </InputLabel>
                        <Select
                            value={formData?.jobType}
                            onChange={(e) => handleChange(e.target.value, 'jobType')}
                            fullWidth
                            sx={{
                                height: '2.25rem', // Outer height
                                fontSize: '0.875rem', // Text size to fit well within height
                                '.MuiSelect-select': {
                                  padding: '0.5rem 0.75rem', // Padding inside select field
                                },
                                borderRadius: "7px",
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderRadius: "7px",
                                },
                              }}
                        >
                            <MenuItem value={"full_time"}>Full time</MenuItem>
                            <MenuItem value={"part_time"}>Part time</MenuItem>
                        </Select>
                    </Box>

                <Box className="input-box-container">
                <InputLabel htmlFor="company">Location*</InputLabel>
                <TextField
                    fullWidth
                    id="company"
                    name="company"
                    placeholder="Ex: Microsoft Corporation"
                    value={formData?.location}
                    onChange={(e) => handleChange(e.target.value, 'location')}
                    InputProps={{
                        sx: {
                          height: "2.25rem",
                        },
                      }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          minHeight: "2.25rem",
                        },
                        borderRadius: "7px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "7px",
                        },
                      }}
                />
                </Box>    

                <Box className="input-box-container">
                    <InputLabel htmlFor="startDate">
                        Start Date
                    </InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>  
            <FormControl error={!!errors.startDate} className={classNames({"date-error":!!errors.startDate,},"date-form-control")}>
            <DatePicker
            value={formData?.startDate ? new Date(formData.startDate) : null}
        onChange={handleStartDateChange}
        onError={handleStartDateError}
        sx={{
            borderRadius: "7px",
            "& .MuiOutlinedInput-notchedOutline": {
              borderRadius: "7px",
            },
        }}
        slots={{
        openPickerIcon: DatePickerIcon,}}
        // slotProps={{
        //     popper: {
        //       modifiers: [
        //         {
        //           name: "offset",
        //           options: {
        //             offset: startDateOffset, // Adjust the offset as needed
        //           },
        //         },
        //         {
        //             name: "preventOverflow",
        //             options: {
        //             mainAxis: true, // Adjust on the main axis (up/down)
        //             altAxis: false,
        //               boundariesElement: "viewport",
        //               tether: false,
        //             },
        //           },
        //         {
        //           name: "flip",
        //           options: {
        //             fallbackPlacements: ["bottom-end", "top-end", "top-start"], // Position at bottom-end
        //             flipVariations: true,
        //           },
        //         },
        //       ],
        //     },
        //   }}
            />
        {errors?.startDate && (
          <FormHelperText>{errors?.startDate }</FormHelperText>
        )}
        </FormControl>
    </LocalizationProvider>
    </Box>

    <Box className="input-box-container">
        <InputLabel htmlFor="endDate">
                End Date
        </InputLabel>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
        <FormControl error={!!errors.endDate} className={classNames({"date-error":!!errors.endDate,},"date-form-control")}>
        <DatePicker
            value={formData?.endDate ? new Date(formData.endDate) : null}             
            onChange={handleEndDateChange}
            onError={handleEndDateError}
            sx={{
                borderRadius: "7px",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "7px",
                },
            }}
            minDate={formData?.startDate ? new Date(formData?.startDate) : undefined}
            slots={{
            openPickerIcon: DatePickerIcon,
            }}
            // slotProps={{
            //     popper: {
            //       modifiers: [
            //         {
            //           name: "offset",
            //           options: {
            //             offset: endDateOffset, // Adjust the offset as needed
            //           },
            //         },
            //         {
            //             name: "preventOverflow",
            //             options: {
            //             mainAxis: true, // Adjust on the main axis (up/down)
            //             altAxis: false,
            //               boundariesElement: "viewport",
            //               tether: false,
            //             },
            //           },
            //         {
            //           name: "flip",
            //           options: {
            //             fallbackPlacements: ["bottom-end", "top-end", "top-start"], // Position at bottom-end
            //             flipVariations: true,
            //           },
            //         },
            //       ],
            //     },
            //   }}
            /> 
        {errors?.endDate && (
          <FormHelperText>{errors?.endDate }</FormHelperText>
        )}
        </FormControl>
        </LocalizationProvider>
    </Box>
                </Box>

                <Box
                sx={{    
                    display:"flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    padding:2,
                    }}
                    className="modal-content-wrapper"
                >
                    <Box className="quill-container">
                    <Typography className="quill-heading">Job Duties</Typography>
                    <ReactQuill
                        // ref={careerObjectiveInputRef}
                        placeholder="Add the relevant description."
                        value={workExpDetails}
                        onChange={handleDescriptionChange}
                        theme="snow" 
                        modules={quillModules}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        />
                    </Box>

                    <Box className="ai-suggestion-container">
                        <Box className="ai-title">
                            <Box className="ai-box">
                            <AiIcon/>
                            <Typography className="ai-description">AI Generated</Typography>
                            </Box>
                         {aiList?.[0] &&<Box
                            sx={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginLeft: 2,
                            border: '1px solid #5594ff',
                            borderRadius:"100px",
                            cursor: 'pointer',
                            color: "#5594ff",
                            px: 1,
                            }}
                         onClick={()=> getWorkExpAiData()}
                        className="regenrate-wrapper"
                    >
                     <RegenerateIcon/> <Typography className="button" variant="caption">Regenrate</Typography>
                         </Box>}
                        </Box>

                        <Box className="ai-content-container">
                            {
                                loader ? 
                                <Box sx={{flex:1,display:"flex",flexDirection:"column",gap:"1rem",justifyContent:"center",alignItems:"center"}}>
                                   {Array(6).fill(0)?.map((_,index)=>
                                   <Box sx={{display:"flex",flexDirection:"column",width:"90%",height:"48px",gap:"0.5rem"}}>
                                        <Box sx={{display:"flex",gap:"0.25rem",alignItems:"center"}}>
                                         <Skeleton  sx={aiShimmerStyle} key={index} variant="circular" width={"10px"} animation="wave" height={"10px"}/>
                                        <Skeleton sx={aiShimmerStyle} key={index} variant="rounded" width={"90%"} animation="wave" height={"16px"}/>
                                        </Box>
                                        <Skeleton key={index} variant="rounded" width={"50%"} animation="wave" height={"16px"} sx={{...aiShimmerStyle,marginLeft:"0.75rem"}}/>
                                   </Box>
                                   )}
                                </Box>
                                : aiList?.[0] ?
                                <List className="list">   
                                {aiList?.map((sug)=>(
                                    <ListItem className="list-item">
                                        {sug.isAdded ? 
                                         <Box className="item-wrapper active-item-wrapper">
                                        <Box className="icon-wrapper">
                                         <DoneAiIcon/>
                                        </Box>
                                        <Typography className="active-text">{sug.text}</Typography>
                                            </Box>
                                            :
                                            <Box className="item-wrapper" onClick={()=>handleTextClick(sug.text)}>
                                            <Box className="icon-wrapper">
                                             <AddAiIcon/>
                                            </Box>
                                            <Typography className={"inactive-text"}>{sug.text}</Typography>
                                        </Box>
                                        }
    
                                    </ListItem>
                                ))}
                                </List>
                                :
                                <Box sx={{width:"100%",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",gap:"0.5rem"}}>
                                    <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",marginLeft:"1rem",marginBottom:"1rem"}}>
                                    <StartAiIcon/>
                                    </Box>
                                <Box
                                    sx={{
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginLeft: 2,
                                    // backgroundColor: '#0088ff',
                                    background:"linear-gradient(to left,#5594FF,#687AF3,#D080ff)",
                                    color:"#fff",
                                    borderRadius: "5.71px",
                                    cursor: 'pointer',
                                    px: 1,
                                    py: 0.5,
                                    }}
                                    >
                                    <WhiteAiIcon /> <Typography variant="caption" sx={{marginLeft:"0.3rem",fontFamily:"1rem",fontWeight:"500"}} onClick={()=>getWorkExpAiData()}>Generate with AI</Typography>
                                </Box>
                                </Box>
                            }

                           
                        </Box>
                    </Box>
                </Box>

                <Box className="modal-footer">
                        <Button variant="outlined" sx={{border:"none","&:hover":{border:"none"}}} onClick={()=>setWorkExpModal(false)}>Cancel</Button>
                        <Button variant="contained" onClick={handleConfirmAndContinue}>Confirm and Continue</Button>
                </Box>
            </Box>  
        </Modal>
    )
}
export default WorkExperienceModal;



// { isAdded: false, text: "Highly motivated professional with extensive experience in delivering successful projects on time and within budget." },
// { isAdded: false, text: "Dedicated team player with proven leadership skills and a track record of driving business growth." },
// { isAdded: false, text: "Detail-oriented individual with a strong background in data analysis and problem-solving." },
// { isAdded: false, text: "Seeking a challenging position in a dynamic organization where I can apply my skills and grow professionally." },
// { isAdded: false, text: "Aiming to leverage my expertise in software development to contribute to innovative projects." },
// { isAdded: false, text: "Aspiring to secure a role in project management where I can utilize my organizational and leadership skills." },
// { isAdded: false, text: "Experienced in full-stack web development, specializing in React and Node.js." },
// { isAdded: false, text: "Proficient in cloud computing with a focus on AWS and DevOps best practices." },
// { isAdded: false, text: "Skilled in data science and machine learning, with hands-on experience in Python and R." },
// { isAdded: false, text: "Collaborated effectively with cross-functional teams to achieve project goals and enhance team productivity." },
// { isAdded: false, text: "Led a team of developers to deliver high-quality software solutions within tight deadlines." },
// { isAdded: false, text: "Proven ability to manage diverse teams and foster a collaborative working environment." },
// { isAdded: false, text: "Successfully reduced operational costs by 15% through process improvements and automation." },
// { isAdded: false, text: "Increased customer satisfaction scores by implementing user-centric design practices." },
// { isAdded: false, text: "Developed and deployed a scalable application that handled over 1 million users." }