import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box, Grid, TextField, CircularProgress, IconButton, Divider, InputAdornment } from '@mui/material';
import { Close } from '@mui/icons-material';
import { AuthInputFieldStyle } from './EditForms/ProcessResumeUtils';
import { FetchJobDescriptionIcon } from './../components/Icons';
import MatchResults from './TailorResume/MatchedResult';
import { useDispatch, useSelector } from 'react-redux';
import { setExtractedData } from '../redux/actions';
import { initialState } from '../redux/reducer';

interface SteppedDialogProps {
  open: boolean;
  onClose: () => void;
  onFetchJobDetails: (input: string, inputType: 'url' | 'description') => Promise<any>;
  onMatchJobDescription: (jobDetails: any, resumeObject: any) => Promise<any>;
  resumeObject: any;
}

const LoadingIndicator: React.FC<{
  type: 'SCRAPING' | 'MATCHING' | 'ERROR';
  FetchJobDescriptionIcon: React.FC;
}> = ({ type, FetchJobDescriptionIcon }) => {
  const messageConfig = {
    SCRAPING: "Fetching job description...",
    MATCHING: "We are fetching the job details.",
    ERROR: "Oops... we couldn't process that link.\nThe link might be incorrect or restricted. No worries! You can manually add the details in the next screen."
  };

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      py: 4,
      textAlign: "center"
    }}>
      <Box sx={{ mb: 1 }}>
        <FetchJobDescriptionIcon />
      </Box>
      <Typography 
        sx={{ 
          fontSize: "16px",
          whiteSpace: "pre-line",
          mb: type === 'ERROR' ? 0 : 2
        }} 
        color="text.secondary"
      >
        {messageConfig[type]}
      </Typography>
      {type !== 'ERROR' && <CircularProgress size={24} />}
    </Box>
  );
};

type LoadingState = {
  isLoading: boolean;
  type: 'SCRAPING' | 'MATCHING' | 'ERROR' | null;
};

const SteppedDialog: React.FC<SteppedDialogProps> = ({ 
  open, 
  onClose, 
  onFetchJobDetails, 
  onMatchJobDescription, 
  resumeObject 
}) => {
  const dispatch = useDispatch();
  const extractedData = useSelector((state: any) => state.extractedData);
  const [jobUrl, setJobUrl] = useState('');
  const [jobDescription, setJobDescription] = useState('');
  const [loadingState, setLoadingState] = useState<LoadingState>({
    isLoading: false,
    type: null
  });
  const [error, setError] = useState('');
  const [showResults, setShowResults] = useState(false);
  const [tailoredResult, setTailoredResult] = useState(initialState.extractedData.tailoredResumeObject);

  const updateExtractedData = (matches: any) => {

    setShowResults(!!matches);
  };

  const handleUrlFetch = async () => {
    setLoadingState({ isLoading: true, type: 'SCRAPING' });
    setError('');
    
    try {
      const result = await onFetchJobDetails(jobUrl, 'url');
      setJobDescription(result);
      
      setLoadingState({ isLoading: true, type: 'MATCHING' });
      const matches = await onMatchJobDescription(result, extractedData);
      updateExtractedData(matches);
      setTailoredResult(matches);
      setLoadingState({ isLoading: false, type: null });
    } catch (err) {
      console.error('Error in fetch:', err);
      setLoadingState({ isLoading: true, type: 'ERROR' });
      setTimeout(() => {
        setLoadingState({ isLoading: false, type: null });
        setError('Failed to fetch job details. Please enter the job description manually.');
      }, 3000);
    }
  };
  
  const handleManualSubmit = async () => {
    setLoadingState({ isLoading: true, type: 'MATCHING' });
    setError('');
    
    try {
      const matches = await onMatchJobDescription(jobDescription, extractedData);
      updateExtractedData(matches);
      setTailoredResult(matches);
      setLoadingState({ isLoading: false, type: null });
    } catch (err) {
      console.error('Error in manual submit:', err);
      setError('Failed to process job description. Please try again.');
      setLoadingState({ isLoading: false, type: null });
    }
  };

  const handleBack = () => {
    setShowResults(false);  // Only update local state
    setJobUrl('');         // Reset form
    setJobDescription(''); // Reset form
    // updateExtractedData(null);  // Clear redux store separately
  };

  const updateExtractedDataInStore = () => {
    dispatch(setExtractedData({ ...extractedData, tailoredResumeObject: tailoredResult }));
  }

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '20px',
        },
      }}
    >
      <DialogTitle sx={{ fontWeight: "bold" }}>
        Tailor Resume to Job Description
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          justifyContent: "center",
          height: "500px",
          width: "600px",
        }}
      >
        {loadingState.type ? (
          <LoadingIndicator 
            type={loadingState.type}
            FetchJobDescriptionIcon={FetchJobDescriptionIcon}
          />
        ) : showResults ? (
          <MatchResults matchedResult={extractedData.tailoredResumeObject} jobUrl={jobUrl}/>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1" component="label" htmlFor="email" gutterBottom>
                Job URL
              </Typography>
              <TextField
                placeholder="Job URL"
                value={jobUrl}
                onChange={(e) => setJobUrl(e.target.value)}
                fullWidth
                sx={{
                  ...AuthInputFieldStyle,
                  mt: 0.5,
                  '& .MuiOutlinedInput-root': {
                    border: "1px solid #E2E8F0",
                    background: "#F8FAFC",
                    color: "#667085",
                    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                    paddingRight: 0
                  },
                }}
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button 
                        onClick={handleUrlFetch} 
                        variant="contained" 
                        color="primary"
                        sx={{
                          padding: "0.6em 0px",
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0
                        }}
                        disabled={loadingState.isLoading}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {loadingState.isLoading && (
                            <div style={{ width: "18px", height: "18px", marginRight: "8px" }}>
                              <CircularProgress size={18} color="inherit" />
                            </div>
                          )}
                          Fetch
                        </div>
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center' }}>
                <Divider sx={{ width: '15%' }} />
                <Typography variant="body2" sx={{ mx: 2, fontWeight: "bold" }}>OR</Typography>
                <Divider sx={{ width: '15%' }} />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" component="label" htmlFor="email" gutterBottom>
                Job Description
              </Typography>
              <TextField
                multiline
                rows={12}
                placeholder="Job Description"
                value={jobDescription}
                onChange={(e) => setJobDescription(e.target.value)}
                fullWidth
                sx={{
                  ...AuthInputFieldStyle,
                  mt: 0.5,
                  '& .MuiOutlinedInput-root': {
                    border: "1px solid #E2E8F0",
                    background: "#F8FAFC",
                    color: "#667085",
                    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                  },
                }}
                margin="normal"
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between", p: 3 }}>
        {showResults ? (
          <>
            <Button
              variant="outlined"
              size="large"
              onClick={handleBack}
              sx={{
                border: "1px solid #CBD5E1",
                borderRadius: "8px",
                mr: 1
              }}
            >
              Back
            </Button>
            <Button
              variant="contained"
              size="large"
              onClick={() => {
                updateExtractedDataInStore();
                onClose();
              }}
            >
              Edit Manually
            </Button>
          </>
        ) : (
          <>
            <Button
              variant="outlined"
              size="large"
              onClick={onClose}
              sx={{
                border: "1px solid #CBD5E1",
                borderRadius: "8px",
                mr: 1
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="large"
              disabled={loadingState.isLoading || (!jobUrl && !jobDescription)}
              onClick={jobDescription ? handleManualSubmit : handleUrlFetch}
            >
              Continue
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default SteppedDialog;