import { Padding } from "@mui/icons-material"

export const InputFieldCustomeStyle = {
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px',
      backgroundColor:"#fff",
      '& fieldset': {
        borderRadius: '10px',
        border:"1px solid lightgray",
        // backgroundColor:"#fff",
      },
    },
    '& .MuiInputBase-input': {
      padding: '0.6rem', // Adjust padding here
    },
  }

export const AuthInputFieldStyle = {
  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',
    backgroundColor:"#fff",
    fontSize: "1.15rem",
    '& fieldset': {
      borderRadius: '10px',
      border:"1px solid lightgray",
      "&:hover": {
        border:"1px solid #5594FF",
      }
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#5594FF",
    },
  },
  '& .MuiInputBase-input': {
    height: '0.3rem',
    padding: '0.6rem',
  },
}

export const InputSelectCustomeStyle = {
  '& .MuiSelect-select': {
      backgroundColor:"#fff",
      color:"grey",
      padding:"0.6rem",
    '& fieldset': {
      // backgroundColor:"#fff",
    },
  },  
  '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'lightgrey', // Default border color
      borderRadius: '10px',
      padding:"5px",
    },       
}

  export const autoComplteStyle = {
      '& .MuiInputBase-root': {
        padding: '0.1rem 0.5rem', // Adjust padding as needed
        borderRadius:"10px",

      },
      '& .MuiAutocomplete-input': {
        padding: '0px', // Adjust padding as needed
      }
    }
  