import {
  Box,
  Grid,
  TextField,
  Typography,
  Divider,
  Select,
  MenuItem,
  Chip,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { countries } from "../../helpers/countries";
import TeknoSwitch from "../../CommonComponents/TeknoSwitch";
import { SecondaryButton } from "../../button/Buttons";
import TeknoToggleBtn from "../../common/TeknoToggleBtn";
import {
  companySize,
  currencies,
  industries,
  preferredJobTypes,
  workArrangement,
} from "./job-preference-data";
import authenticatedFetch from "../../../services/AuthenticatedFetch";
import { tecknoApiBaseUrl } from "../../../utils/config";
import toast from "react-hot-toast";
import { responseHandler } from "../../../utils/responseHandler";
import { ILoggedinUserData } from "../../utils";
import { useSelector } from "react-redux";

interface IJobPreferencesValues {
  activelyLookingForJobs: boolean;
  preferredJobTypes: string[];
  workArrangement: string[];
  preferredWorkLocation: string;
  salaryCurrency: string;
  fromSalary?: number;
  toSalary?: number;
  companySize: string[];
  preferredIndustries: string[];
}

const JobPreferences = () => {
  const loggedInUserData: ILoggedinUserData = useSelector(
    (state: any) => state.loggedInUserData
  );

  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
    watch,
    setValue,
  } = useForm<IJobPreferencesValues>({
    defaultValues: {
      activelyLookingForJobs: false,
      preferredJobTypes: [],
      workArrangement: [],
      preferredWorkLocation: "",
      salaryCurrency: "USD",
      companySize: [],
      preferredIndustries: [],
    },
  });

  const formValues = watch();

  const [loading, setLoading] = useState(false);

  const getSelectedChip = (
    value: string,
    index: number,
    selected: string[]
  ) => {
    if (index === 2) {
      return (
        <Chip
          key={value}
          sx={{
            backgroundColor: "#5594FF",
            color: "#fff",
            borderRadius: "6px",
          }}
          label={`+ ${selected.length - 2}`}
        />
      );
    } else if (index > 2) {
      return null;
    } else {
      return (
        <Chip
          key={value}
          sx={{
            backgroundColor: "#5594FF",
            color: "#fff",
            borderRadius: "6px",
          }}
          label={industries.find((industry) => industry.value === value)?.label}
        />
      );
    }
  };

  const onSubmit = async (data: IJobPreferencesValues) => {
    try {
      setLoading(true);

      const body = JSON.stringify({
        activelyLookingForJobs: data.activelyLookingForJobs,
        preferredJobTypes: JSON.stringify(data.preferredJobTypes),
        workArrangement: JSON.stringify(data.workArrangement),
        preferredWorkLocation: data.preferredWorkLocation,
        salaryCurrency: data.salaryCurrency,
        fromSalary: data.fromSalary,
        toSalary: data.toSalary,
        companySize: JSON.stringify(data.companySize),
        preferredIndustries: JSON.stringify(data.preferredIndustries),
      });

      //API call to update the job preferences
      const response = await authenticatedFetch(
        `${tecknoApiBaseUrl}/api/update-user-job-pref`,
        {
          method: "PUT",
          body,
        }
      );

      if (response.ok) {
        toast.success("Job preferences updated successfully");
      } else {
        responseHandler(response);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setValue(
      "activelyLookingForJobs",
      !!loggedInUserData?.activelyLookingForJobs
    );
    setValue(
      "preferredJobTypes",
      (loggedInUserData?.preferredJobTypes &&
        JSON.parse(loggedInUserData?.preferredJobTypes)) ||
        []
    );
    setValue(
      "workArrangement",
      (loggedInUserData?.workArrangement &&
        JSON.parse(loggedInUserData?.workArrangement)) ||
        []
    );
    setValue(
      "preferredWorkLocation",
      loggedInUserData?.preferredWorkLocation || ""
    );
    setValue("salaryCurrency", loggedInUserData?.salaryCurrency || "USD");
    setValue("fromSalary", loggedInUserData?.fromSalary || 0);
    setValue("toSalary", loggedInUserData?.toSalary || 0);
    setValue(
      "companySize",
      (loggedInUserData?.companySize &&
        JSON.parse(loggedInUserData?.companySize)) ||
        ""
    );
    setValue(
      "preferredIndustries",
      (loggedInUserData?.preferredIndustries &&
        JSON.parse(loggedInUserData?.preferredIndustries)) ||
        []
    );
  }, [loggedInUserData]);

  return (
    <Box sx={{ flex: 1, padding: 4, maxWidth: "600px" }}>
      <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
        Job preferences
      </Typography>
      <Typography variant="body1" gutterBottom>
        Choose the roles and environments you’re interested in.
      </Typography>

      <Divider sx={{ my: 2.5 }} />

      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 2,
              }}
            >
              <Typography variant="subtitle1" sx={{ fontWeight: "500" }}>
                Are you actively looking for new jobs
              </Typography>

              <TeknoSwitch
                {...register("activelyLookingForJobs")}
                checked={formValues.activelyLookingForJobs}
              />
            </Box>
            <Typography variant="body2" color="textSecondary">
              Explore new opportunities to leverage your expertise and make a
              meaningful impact in innovative and dynamic environments
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ my: 2 }} />

        <Grid container spacing={2} sx={{ mt: 0.5, maxWidth: "800px" }}>
          <Grid item container xs={12} columnSpacing={2} rowSpacing={4}>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                component="label"
                htmlFor="fullName"
                gutterBottom
                sx={{ fontWeight: "bold" }}
                className="required-field"
              >
                Preferred job types
              </Typography>
              <Box sx={{ display: "flex", gap: 2, mt: 1 }}>
                {preferredJobTypes.map((type, idx) => (
                  <TeknoToggleBtn
                    key={idx}
                    label={type}
                    checked={formValues.preferredJobTypes?.includes(type)}
                    {...register("preferredJobTypes", {
                      required: "Preferred job types is required",
                    })}
                  />
                ))}
              </Box>
              <Typography variant="body2" sx={{ color: "red" }}>
                {errors.preferredJobTypes?.message}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="body1"
                component="label"
                htmlFor="fullName"
                gutterBottom
                sx={{ fontWeight: "bold" }}
                className="required-field"
              >
                Work Arrangement
              </Typography>
              <Box sx={{ display: "flex", gap: 2, mt: 1 }}>
                {workArrangement.map((type, idx) => (
                  <TeknoToggleBtn
                    key={idx}
                    label={type}
                    checked={formValues.workArrangement?.includes(type)}
                    {...register("workArrangement", {
                      required: "Work arrangement is required",
                    })}
                  />
                ))}
              </Box>

              <Box>
                <Select
                  sx={{
                    mt: 2,
                    width: "100%",
                    ".MuiSelect-select": {
                      display: "flex",
                      alignItems: "center",
                    },
                  }}
                  placeholder="Choose your preferred work location"
                  value={formValues.preferredWorkLocation}
                  {...register("preferredWorkLocation", {
                    required: "Preferred work location is required",
                  })}
                >
                  {/* Placeholder item */}
                  <MenuItem value="" disabled>
                    Choose your preferred work location
                  </MenuItem>
                  {countries.map((country) => (
                    <MenuItem
                      value={country.code}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={country.flag}
                        width="20"
                        alt=""
                        style={{ marginRight: "10px" }}
                      />
                      <span>{country.label}</span>
                    </MenuItem>
                  ))}
                </Select>
              </Box>

              <Typography variant="body2" sx={{ color: "red" }}>
                {errors.workArrangement?.message ||
                  errors.preferredWorkLocation?.message}
              </Typography>
            </Grid>

            <Grid item xs={12} spacing={0}>
              <Grid container spacing={0}>
                <Grid item sm={2}>
                  <Typography
                    variant="body1"
                    component="label"
                    htmlFor="salary"
                    gutterBottom
                    sx={{ fontWeight: "bold" }}
                  >
                    Salary
                  </Typography>
                  <Box>
                    <Select
                      fullWidth
                      sx={{
                        mt: 1,
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                        },
                      }}
                      value={formValues.salaryCurrency}
                      {...register("salaryCurrency")}
                      renderValue={(value) => (
                        <span>
                          {currencies.find((c) => c.label === value)?.symbol}
                        </span>
                      )}
                    >
                      {currencies.map((curr) => (
                        <MenuItem value={curr.label}>
                          {curr.symbol} {curr.text} ({curr.label})
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </Grid>

                <Grid item sm={5}>
                  <Typography
                    variant="body1"
                    component="label"
                    htmlFor="salary"
                    gutterBottom
                    sx={{ fontWeight: "bold" }}
                    className="required-field"
                  >
                    From
                  </Typography>
                  <TextField
                    sx={{
                      mt: 1,
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: 0,
                        borderLeftWidth: 0,
                        borderRightWidth: 0,
                      },
                    }}
                    type="number"
                    fullWidth
                    {...register("fromSalary", {
                      required: "Enter a value",
                    })}
                    placeholder="100000"
                  />
                  <Typography variant="body2" sx={{ color: "red" }}>
                    {errors.fromSalary?.message}
                  </Typography>
                </Grid>

                <Grid item sm={5}>
                  <Typography
                    variant="body1"
                    component="label"
                    htmlFor="salary"
                    gutterBottom
                    sx={{ fontWeight: "bold" }}
                    className="required-field"
                  >
                    To
                  </Typography>
                  <TextField
                    sx={{
                      mt: 1,
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                      },
                    }}
                    fullWidth
                    type="number"
                    {...register("toSalary", {
                      required: "Enter a value",
                    })}
                    placeholder="150000"
                  />
                  <Typography variant="body2" sx={{ color: "red" }}>
                    {errors.toSalary?.message}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="body1"
                component="label"
                htmlFor="fullName"
                gutterBottom
                sx={{ fontWeight: "bold" }}
                className="required-field"
              >
                Select company size
              </Typography>
              <Box sx={{ display: "flex", gap: 2, mt: 1 }}>
                {companySize.map((type, idx) => (
                  <TeknoToggleBtn
                    key={idx}
                    label={type}
                    checked={formValues.companySize?.includes(type)}
                    {...register("companySize", {
                      required: "Company size is required",
                    })}
                  />
                ))}
              </Box>
              <Typography variant="body2" sx={{ color: "red" }}>
                {errors.companySize?.message}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="body1"
                component="label"
                htmlFor="country"
                gutterBottom
                sx={{ fontWeight: "bold" }}
                className="required-field"
              >
                Peferred Industried
              </Typography>

              <Box sx={{ width: "100%" }}>
                <Select
                  fullWidth
                  multiple
                  sx={{
                    mt: 0.5,
                    width: "100%",
                  }}
                  MenuProps={{
                    sx: {
                      "&& .Mui-selected": {
                        backgroundColor: "transparent",
                      },
                    },
                  }}
                  {...register("preferredIndustries", {
                    required: "Preferred industries is required",
                  })}
                  value={formValues.preferredIndustries}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value, index) =>
                        getSelectedChip(value, index, selected)
                      )}
                    </Box>
                  )}
                >
                  {/* Placeholder item */}
                  <MenuItem value="" disabled>
                    Choose your preferred industries
                  </MenuItem>
                  {industries.map((industry, index) => (
                    <MenuItem key={index} value={industry.value} disableRipple>
                      <Checkbox
                        size="small"
                        checked={formValues.preferredIndustries.includes(
                          industry.value
                        )}
                        disableRipple
                      />
                      <ListItemText primary={industry.label} />
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Typography variant="body2" sx={{ color: "red" }}>
                {errors.preferredIndustries?.message}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
        <SecondaryButton
          variant="contained"
          color="primary"
          loading={loading}
          onClick={handleSubmit(onSubmit)}
          disabled={!Object.keys(dirtyFields).length}
        >
          Save
        </SecondaryButton>
      </Box>
    </Box>
  );
};

export default JobPreferences;
