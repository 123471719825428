import { jwtDecode } from "jwt-decode";

interface JWTPayload {
  given_name?: string;
  family_name?: string;
  exp?: number; // Optional expiration timestamp, if available
}

export const getUsernameFromToken = (): string | null  => {
  try {
    const token = localStorage.getItem("accessToken");
    const decodedToken: JWTPayload = jwtDecode<JWTPayload>(token || ""); // Decode the token with types
    return (decodedToken.given_name && decodedToken.family_name) ? `${decodedToken.given_name} ${decodedToken.family_name}` : decodedToken.given_name || decodedToken.family_name || null;
  } catch (error) {
    // console.error("Invalid token", error);
    return null;
  }
}