import { Box, IconButton, InputLabel, TextField, Typography } from "@mui/material"
import DoneIcon from '@mui/icons-material/Done';
import { useEffect, useRef, useState } from "react";
import {ReactComponent as CustomEditLinkIcon} from "../../assets/SVG/link-edit-icon.svg";
import CloseIcon from '@mui/icons-material/Close';

interface ICustomLinkEditor {
    name:string;
    id:string;
    isEdit:boolean;
    handleLinkNameChange:(name:string,id:string)=>void;
    deleteCustomLink:(id:string,name:string) => void;
    handleEditStart:(id:string)=>void
};

const CustomLinkEditor:React.FC<ICustomLinkEditor> = ({name,id,isEdit,handleLinkNameChange,deleteCustomLink,handleEditStart}) => {
    const [linkName,setName] = useState<string>("");
    const initialName = useRef<string>(name);
    //const [edit,setEdit] = useState<boolean>(isEdit);

    useEffect(()=>{
       if(isEdit){
        setName(name)
       }
    },[isEdit])

    const handleDone = () => {
        initialName.current = linkName;
        handleLinkNameChange(linkName, id);
    }

    const handleCloseEditor = () => {
        handleLinkNameChange(initialName.current, id);
    }
    return(
        <>
        {!isEdit ? 
        <Box sx={{display:"flex",justifyContent:"left",alignItems:"center",gap:"0.5rem"}}>
        <InputLabel htmlFor={name} className="personal-details-label" > 
            {name}
        </InputLabel>
        <Box sx={{cursor:"pointer"}}>
        <CustomEditLinkIcon onClick={()=>handleEditStart(id)}/>
        </Box>
        <Typography sx={{flex:"1",textAlign:"right",paddingRight:"0.5rem",cursor:"pointer",fontSize:"14px",color:"#98a2b3"}}
        onClick={()=>deleteCustomLink(id,name)}
        >Delete</Typography>
        </Box>
        : <Box className="edit-wrapper" sx={{display:"flex",justifyContent:"left",alignItems:"center"}}>
        <TextField onChange={(e)=> setName(e.target.value)} value={linkName} size="small" sx={{ 
        '& .MuiInputBase-input': {
            fontSize: '14px !important', // Adjust font size of input text
            },
            '& .MuiOutlinedInput-root': {
            padding:"0.2rem 0.2rem 0.2rem 0.5rem  !important",
            '& fieldset': {
             paddingLeft:"0.5rem !important",
             borderRadius: "4px !important",
          },
            }
        }} 
          />
        <CloseIcon sx={{color:"#EF4444"}} onClick={handleCloseEditor}/>
        <IconButton disabled={!linkName}
        sx={{
            "&:hover":{
            background:"none",
        },
        cursor: !linkName ? 'not-allowed' : 'pointer',}}

        ><DoneIcon sx={{color:linkName ? "#19A44C" : "lightgray"}} onClick={handleDone}/></IconButton>
        <Typography sx={{flex:"1",textAlign:"right",paddingRight:"0.5rem",cursor:"pointer",fontSize:"14px",color:"#ef4444"}}
        onClick={()=>deleteCustomLink(id,name)}
        >Delete</Typography>
        </Box>}
        </>
    )
}

export default CustomLinkEditor